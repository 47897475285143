/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'
import SideMenu from '../containers/SideMenu'
import { ThemeDetect } from './../containers/DetectThemeCollaspe'
import { Redirect, Route } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { setUser, logout } from '../features/users/userSlice'
import axios from 'axios'

export function interceptor () {
  axios.interceptors.response.use(res => res,
    function axiosRetryInterceptor (err) {
      if (err.response.status === 401 || err.response.data.message === '401 Unauthorized') {
        const currentPath = window.location.href
        // eslint-disable-next-line
        const checkPath = new RegExp('login')
        const currentLocation = checkPath.test(currentPath)
        if (!currentLocation) {
          localStorage.removeItem('token')
          localStorage.removeItem('idp')
          window.location.href = '/login'
        }
        return
      }
      return Promise.reject(err)
    })
}

export function parseJwt (token) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))

  return JSON.parse(jsonPayload)
};

const Box = styled.div`
  .wrapper-expand {
    margin-left: 240px;
  }
  .wrapper-collapse {
    margin-left: 90px;
  }
`
export const PrivateRoute = ({ component: Component, ...rest }) => {
  const [expand, setCollapse] = React.useState(true)
  const token = useSelector(state => state.user.token)

  return (
        <div className='container'>
        <ThemeDetect.Provider value={{ expand: expand, setCollapse: setCollapse }}>
          <SideMenu />
          <Box>
            <div data-testid="menuContainerType" className={(expand) ? 'wrapper-expand' : 'wrapper-collapse'}>
              <Route {...rest} render={props => (
                token
                  ? <Component {...props} />
                  : <Redirect to="/login" />
              )} />
          </div>
        </Box>
      </ThemeDetect.Provider>
    </div>
  )
}

export const RedirectPage = props => {
  const dispatch = useDispatch()
  const token = useSelector(state => state.user.token)
  if (props.location?.pathname === '/redirect/logout') {
    dispatch(logout())
  }
  React.useEffect(() => {
  // Update the document title using the browser API
    if (props.location?.search) {
      const urlParams = new URLSearchParams(props.location.search)
      const token = urlParams.get('token')
      const converted = parseJwt(token)
      localStorage.setItem('token', token)
      dispatch(setUser({ user: converted, token: token }))
      const idp = urlParams.get('idp')
      localStorage.setItem('idp', idp)
    }
  }, [dispatch, props.location.hash])
  return (
    token ? <Redirect to='/' /> : <div data-testid="no-redirect"/>
  )
}

export function useMounted () {
  const [isMounted, setIsMounted] = React.useState(false)
  React.useEffect(() => {
    setIsMounted(true)
  }, [])
  return isMounted
}
