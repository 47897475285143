import React from 'react'
import PropTypes from 'prop-types'
import {
  MultiSelectWrap,
  SearchAndToggle,
  Items,
  Item,
  ItemLabel,
  StyledSelect
} from './styles'
import SearchIcon from '@material-ui/icons/Search'
import i18n from 'i18next'
import { useFormContext } from 'react-hook-form'

function RegisterMultiSelect (props) {
  const { className, width, marginLeft, placeholder, multi, allItem, onChange, onAllSelectChange, labelField, valueField, options, disabled, defaultValue, element, onBlur } = props
  const [values, setValues] = React.useState([])
  const { setValue } = useFormContext()

  const compareValues = (oldValues, newValues) => {
    // 参照元のソート順がおかしくなってしまうためJSONの変換を行う
    let sortedOld = JSON.parse(JSON.stringify(oldValues))
    sortedOld = sortedOld.sort(function (a, b) {
      return a[valueField] - b[valueField]
    })
    let sortedNew = JSON.parse(JSON.stringify(newValues))
    sortedNew = sortedNew.sort(function (a, b) {
      return a[valueField] - b[valueField]
    })
    const isSame = (sortedOld.length === sortedNew.length) && sortedOld.every((element, index) => {
      return element === sortedNew[index]
    })
    return isSame
  }

  const useArrayHasChanged = (val) => {
    const prevVal = usePrevious(val)
    if (!prevVal) { return prevVal !== val }
    const isSame = compareValues(prevVal, val)
    return !isSame
  }

  const usePrevious = (value) => {
    const ref = React.useRef()
    React.useEffect(() => {
      ref.current = value
    })
    return ref.current
  }

  const getSelectedOptions = (inputValues, options) => {
    options = options || props.options
    const result = []
    for (const itemVal of inputValues) {
      const selectedItem = options.filter(item => item[valueField] === itemVal)
      if (selectedItem.length > 0) {
        result.push(selectedItem[0])
      }
    }
    return result
  }

  const optionsHasChanged = useArrayHasChanged(options)

  React.useEffect(() => {
    if (!optionsHasChanged) { return }
    if (multi) {
      // setValues(options)
      if (defaultValue) {
        let selected = getSelectedOptions(defaultValue, options)
        selected = selected.length ? selected : options
        setValues(selected)
        if (onChange) { onChange(selected, selected.length === options.length) }
      } else {
        setTimeout(() => {
          const currentValues = getSelectedOptions(values.map(item => item[valueField]), options)
          if (!(currentValues.length === values.length && currentValues.length !== 0)) {
            setValues(options)
            if (onChange) { onChange(options, true) }
          } else {
            if (onAllSelectChange) { onAllSelectChange(values.length === options.length) }
          }
        })
      }
    } else {
      if (!values.length) {
        const initValues = props.options.filter(item => item[props.valueField] === defaultValue)
        if (initValues.length > 0) {
          setValues(initValues)
          if (onChange) { onChange(initValues) }
        }
      }
    }
  }, [options])

  React.useLayoutEffect(() => {
    if (multi) {
      if (defaultValue) {
        const selected = getSelectedOptions(defaultValue)
        setValues(selected)
        if (onChange) { onChange(selected, selected.length === options.length) }
      }
    } else {
      const initValues = props.options.filter(item => item[props.valueField] === defaultValue)
      if (initValues.length > 0) {
        setValues(initValues)
      }
    }
  }, [defaultValue])

  const showSelectedItems = (props, state) => {
    if (!multi) {
      const selectedItem = state.values.length > 0 ? props.options.find(item => item[props.valueField] === state.values[0][props.valueField]) : null
      return selectedItem ? selectedItem[props.labelField] : <span className="place-holder">{props.placeholder}</span>
    }

    if (state.values.length === props.options.length ||
      getSelectedOptions(state.values.map(item => item[props.valueField]), props.options).length !== state.values.length) {
      return i18n.t('COMMON_COMMON_DROPDOWN-OPTION-ALL_LABEL')
    }

    if (state.values.length === 0) {
      return <span className="place-holder">{props.placeholder}</span>
    }

    const displayText = i18n.t('COMMON_COMMON_BRANCH-DROPDOWN-NO-OF-SELECTION_LABEL').replace('XX', state.values.length)
    return displayText
  }

  const onSingleSelectHandle = (option, methods) => {
    const cancel = onChange ? onChange([option]) : false
    if (!cancel) {
      methods.addItem(option)
      setValue(element.value, option.key)
      if (onBlur) {
        onBlur()
      }
    } else {
      methods.dropDown('close')
    }
  }

  const onDropCloseHandle = ({ methods, state, close }) => {
    const isSame = compareValues(state.values, values)
    if (isSame) {
      // close the dropdown
      close()
      return
    }
    if (state.values.length === 0) {
      // return back to previous state
      values.map(item => methods.addItem(item))
    } else {
      setValues(state.values)
      if (onChange) { onChange(state.values, state.values.length === options.length) }
    }
    // close the dropdown
    close()
  }

  const contentRenderer = ({ props, state, methods }) => {
    return (
      <div style={{ width: '100%' }}
        data-testid="dropdown-button"
        onClick={() => methods.dropDown(state.dropdown ? 'close' : 'open')}
      >
        {showSelectedItems(props, state)}
      </div>
    )
  }

  const escapeRegex = (input) => {
    return input.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
  }

  const dropdownRenderer = ({ props, state, methods }) => {
    const regexp = new RegExp(escapeRegex(state.search), 'i')

    return (
      <div>
        <SearchAndToggle color="#ccc">
          <SearchIcon fontSize="small" />
          <input
            type="text"
            data-testid="input-search"
            value={state.search}
            onChange={methods.setSearch}
            placeholder={i18n.t('COMMON_COMMON_DROPDOWN-SEARCH_LABEL')}
          />
        </SearchAndToggle>
        <Items>
          {(multi && allItem) &&
            <Item
            data-testid="checkbox-all"
              key={''}
              onClick={
                () => methods.areAllSelected() ? methods.clearAll() : methods.selectAll()
              }
            >
              <input
                data-testid="input-checkbox-all"
                data-patial-check={!methods.areAllSelected() && state.values.length > 0}
                type="checkbox"
                onChange={() => methods.areAllSelected() ? methods.clearAll() : methods.selectAll()}
                checked={methods.areAllSelected()}
              />
              <ItemLabel>{i18n.t('COMMON_COMMON_DROPDOWN-OPTION-ALL_LABEL')}</ItemLabel>
            </Item>
          }

          {props.options
            .filter(item =>
              regexp.test(item[props.labelField]) ||
              item[props.labelField] === i18n.t('COMMON_COMMON_DROPDOWN-OPTION-ALL_LABEL')
            )
            .map(option => {
              return (
                <Item
                  data-testid={`checkbox-${option.key}`}
                  disabled={option.disabled}
                  key={option[props.valueField]}
                  onClick={
                    option.disabled ? null : () => multi ? methods.addItem(option) : onSingleSelectHandle(option, methods)
                  }
                >
                  {(multi && allItem) &&
                    <input
                      data-testid={`input-checkbox-${option.key}`}
                      type="checkbox"
                      onChange={() => methods.addItem(option)}
                      checked={state.values.map(item => item[props.valueField]).indexOf(option[props.valueField]) !== -1}
                    />
                  }
                  <ItemLabel>{option[props.labelField]}</ItemLabel>
                </Item>
              )
            })}
        </Items>
      </div>
    )
  }

  return (
    <MultiSelectWrap width={width} style={{ marginLeft: marginLeft }}>
      <StyledSelect
        className={className}
        disabled={disabled}
        width={width}
        placeholder={placeholder}
        searchable={true}
        dropdownHandle={true}
        backspaceDelete={false}
        keepSelectedInList={true}
        labelField={labelField}
        valueField={valueField}
        multi={multi}
        values={values}
        onDropdownCloseRequest={onDropCloseHandle}
        options={options}
        contentRenderer={
          (innerProps, innerState, innerMethods) =>
            contentRenderer(innerProps, innerState, innerMethods)
        }
        dropdownRenderer={
          (innerProps, innerState, innerMethods) =>
            dropdownRenderer(
              innerProps,
              innerState,
              innerMethods
            )
        }
      />
    </MultiSelectWrap>
  )
}

export default RegisterMultiSelect

RegisterMultiSelect.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  width: PropTypes.string,
  marginLeft: PropTypes.string,
  placeholder: PropTypes.string,
  multi: PropTypes.bool,
  allItem: PropTypes.bool,
  onChange: PropTypes.func,
  onAllSelectChange: PropTypes.func,
  labelField: PropTypes.string,
  valueField: PropTypes.string,
  options: PropTypes.array,
  defaultValue: PropTypes.any,
  element: PropTypes.object,
  onBlur: PropTypes.func
}
