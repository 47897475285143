import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import i18n from 'i18next'
import { EditDropDownWrapper } from './styles'
import Modal from '../../../components/Modal'
import { CircularProgress } from '@material-ui/core'
import { userInfo } from './../../../features/users/userInfoSlice'
import { MaterialCommunityIcons } from 'react-web-vector-icons'
import { OkButton, YesButton, NoButton, SaveButton, BackButton, CommonButton } from '../../../components/Button'
import { useForm, useFieldArray } from 'react-hook-form'
import { dialogMessageList, requiredItems } from './valueMapping'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { getDropDownContentList, editDropDown, setEditing, setCheckError, checkDropdownDelete } from '../../../features/dropdown/dropdownSlice'

function EditDropDown (props) {
  const { register, getValues, setValue, formState: { errors }, setError, clearErrors, control, reset } = useForm({ mode: 'onSubmit' })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'list'
  })
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const [showMessage, setShowMessage] = React.useState(false)
  const [dialogInfo, setDialogInfo] = React.useState({})
  const [errorFields, setErrorFields] = React.useState([])
  const [deleteRecordIndex, setDeleteRecordIndex] = React.useState('')
  const [deleteRecordKey, setDeleteRecordKey] = React.useState('')
  const [deleteRecordName, setDeleteRecordName] = React.useState('')
  const [deleteRecordList, setdeleteRecordList] = React.useState([])
  const [row, setRow] = React.useState(1)
  const [sort, setSort] = React.useState(1)
  const userState = useSelector(state => state.userInfo)
  const dropDownState = useSelector(state => state.dropDown)
  const noMoreErrors = 'none'
  const conflictError = 'CONFLICT'
  const dropdownExist = 'DROPDOWN_EXIST'

  React.useEffect(() => {
    // userInfoはいらないかもしれないが他でやっていたため記載
    dispatch(userInfo())
    // 子のリストを取得
    dispatch(getDropDownContentList({ categoryId: location.state.id }))
    // サイドメニューで遷移する時にメッセージを表示するために編集中の状態に変更
    dispatch(setEditing(true))
    return () => {
      dispatch(setEditing(false))
    }
  }, [])

  // 子のリストが取得された時の処理
  React.useEffect(() => {
    if (dropDownState.contentList.length !== 0) {
      // 一覧を表示するために取得したリストでリセット
      const list = []
      for (let i = 0; i < dropDownState.contentList.length; i++) {
        const value = dropDownState.contentList[i]
        list.push({ ...value, execFlg: 'update' })
      }
      reset({ list: list })
      // 取得したリストのsubの最大値を取得(+1しないと最大値の更新が正しく動かなかった)
      setRow(Math.max.apply(null, dropDownState.contentList.map(function (o) { return o.contentId })) + 1)
      // 取得したリストのsortの最大値を取得(+1しないと最大値の更新が正しく動かなかった)
      setSort(Math.max.apply(null, dropDownState.contentList.map(function (o) { return o.sort })) + 1)
    }
  }, [dropDownState.contentList])

  // 更新処理の後続処理
  React.useEffect(async () => {
    const { error } = dropDownState
    // errorの状態がnullまたは、noMoreErrors以外の場合にエラーメッセージを表示
    if (error && error !== noMoreErrors) {
      if (error[0] === conflictError) showDialog('conflict')
      else showDialog('unknown')
    }
    // errorの状態がnoMoreErrors(更新処理が成功した時に設定している状態)の場合、親の一覧画面に遷移
    if (error && error === noMoreErrors) {
      history.push('/dropdownmanagement')
    }
  }, [dropDownState.error])

  // チェック処理の後続処理
  React.useEffect(async () => {
    const { checkError } = dropDownState
    const dispName = getValues(deleteRecordName)
    // checkErrorの状態がnoMoreErrors以外の場合にエラーメッセージを表示
    if (checkError && checkError !== noMoreErrors) {
      if (checkError[0] === dropdownExist) {
        showDialog('exist', '「' + deleteRecordKey + ':' + dispName + '」')
      } else {
        showDialog('unknown')
      }
    }
    // errorの状態がnoMoreErrorsの場合、削除確認ダイアログを表示
    if (checkError && checkError === noMoreErrors) {
      showDialog('delete', '「' + deleteRecordKey + ':' + dispName + '」')
    }
    // 同じ処理の場合に、dropDownState.checkErrorが変わらずダイアログが表示しなかったため、処理の最後でNULLを設定
    if (checkError !== null) {
      dispatch(setCheckError(null))
    }
  }, [dropDownState.checkError])

  // 削除処理
  const checkDelete = (index, key, name) => {
    setDeleteRecordIndex(index)
    setDeleteRecordKey(key)
    setDeleteRecordName(name)
    // ドロップダウン項目が使用されていないか確認
    dispatch(checkDropdownDelete({ categoryId: location.state.id, contentId: String(key) }))
  }

  // ダイアログ表示処理
  const showDialog = (messageCode, message = '') => {
    setShowMessage(true)
    setDialogInfo({ ...dialogMessageList.find((e) => e.code === messageCode), messageComent: message })
  }

  // ダイアログのキャンセルボタン処理
  const onClickCancel = () => {
    setShowMessage(false)
  }

  // ダイアログのOKボタン処理
  const onClickConfirm = async (dlgInfo) => {
    switch (dlgInfo.code) {
      case 'cancel':
        history.push('/dropdownmanagement')
        break
      case 'delete':
        deleteRecord()
        break
      default: // 'save'
        onSubmit()
        break
    }
    setShowMessage(false)
  }

  // 更新処理
  const onSubmit = async () => {
    if (validation()) {
      await dispatch(editDropDown({ body: getValues('list').concat(deleteRecordList) }))
    }
  }

  // 更新処理、必須チェック、重複チェック
  const validation = () => {
    let countErrors = 0
    const list = getValues('list')
    if (list.length === 0) {
      setError('list', {
        type: 'manual',
        message: i18n.t('DROP-DOWN-MANAGEMENT_EDIT-DROP-DOWN_WARNING-MANDATORY_LABEL')
      })
      setErrorFields(prevState => [...prevState, 'list'])
      countErrors += 1
    } else {
      requiredItems.forEach(element => {
        list.forEach((record, i) => {
          if (getValues(`list.${i}.${element}`) === '') {
            setError(`list-${i}-${element}`, {
              type: 'manual',
              message: i18n.t('VEHICLE_VEHICLE-CREATE-EDIT_WARNING-MANDATORY_LABEL')
            })
            setErrorFields(prevState => [...prevState, `list-${i}-${element}`])
            countErrors += 1
          }
        })
        const valuesSoFar = []
        for (let i = 0; i < list.length; ++i) {
          const value = Reflect.get(list[i], element)
          if (value !== '') {
            if (valuesSoFar.indexOf(value) === -1) {
              valuesSoFar.push(value)
            } else {
              setError(`${element}-Duplication`, {
                type: 'manual',
                message: i18n.t(`DROP-DOWN-MANAGEMENT_EDIT-DROP-DOWN_WARNING-${element.toUpperCase()}-DUPLICATION_LABEL`)
              })
              setErrorFields(prevState => [...prevState, `${element}-Duplication`])
              countErrors += 1
              break
            }
          }
        }
      })
      for (let i = 0; i < list.length; i++) {
        if (list[i].inputType === '') {
          list[i].inputType = null
        }
      }
    }
    if (countErrors > 0) {
      return false
    } else {
      return true
    }
  }

  // 必須項目の入力処理&重複チェック(入力された場合にエラーメッセージを削除するために必要)
  const onBlur = (key, name) => () => {
    clearErrors(`list-${key}-${name}`)
    const index = errorFields.indexOf(`list-${key}-${name}`)
    if (index > -1) {
      errorFields.splice(index, 1)
    }
    if (getValues(`list.${key}.${name}`) === '') {
      setError(`list-${key}-${name}`, {
        type: 'manual',
        message: i18n.t('VEHICLE_VEHICLE-CREATE-EDIT_WARNING-MANDATORY_LABEL')
      })
      setErrorFields(prevState => [...prevState, `${`list-${key}-${name}`}`])
    }
    clearErrors(`${name}-Duplication`)
    const dupIndex = errorFields.indexOf(`${name}-Duplication`)
    if (dupIndex > -1) {
      errorFields.splice(dupIndex, 1)
      const list = getValues('list')
      const valuesSoFar = []
      for (let i = 0; i < list.length; ++i) {
        const value = Reflect.get(list[i], name)
        if (value !== '') {
          if (valuesSoFar.indexOf(value) === -1) {
            valuesSoFar.push(value)
          } else {
            setError(`${name}-Duplication`, {
              type: 'manual',
              message: i18n.t(`DROP-DOWN-MANAGEMENT_EDIT-DROP-DOWN_WARNING-${name.toUpperCase()}-DUPLICATION_LABEL`)
            })
            setErrorFields(prevState => [...prevState, `${name}-Duplication`])
            break
          }
        }
      }
    }
  }

  // ソート順の文字数制限
  const changeSortValue = (key) => {
    if (getValues(`list.${key}.sort`).length > 18) {
      setValue(`list.${key}.sort`, getValues(`list.${key}.sort`).slice(0, 18))
    }
  }

  const changeInputTypeValue = (key) => {
    if (getValues(`list.${key}.inputType`).length > 18) {
      setValue(`list.${key}.inputType`, getValues(`list.${key}.inputType`).slice(0, 18))
    }
  }

  const contains = (stringValue, charValue) => {
    return stringValue.indexOf(charValue) > -1
  }

  const allowTypeNumericOnly = (e) => {
    const invalidKey = (e.key.length === 1 && !contains('0123456789', e.key))
    return invalidKey && e.preventDefault()
  }

  // レコード追加処理
  const addRecord = () => {
    setRow((prevCount) => prevCount + 1)
    setSort((prevCount) => prevCount + 1)
    append({ id: null, categoryId: location.state.id, contentId: row, name: '', sort: sort, inputType: null, isNew: false, execFlg: 'insert' })
    clearErrors('list')
    const index = errorFields.indexOf('list')
    if (index > -1) {
      errorFields.splice(index, 1)
    }
  }

  // レコード削除処理
  const deleteRecord = () => {
    setErrorFields([])
    const value = getValues(`list.${deleteRecordIndex}`)
    if (value.id !== null) {
      value.execFlg = 'delete'
      setdeleteRecordList((prevState) => [...prevState, value])
    }
    remove(deleteRecordIndex)
    // リセットしないとチェックボックスのチェックが反映されないため、リセット
    reset({ list: getValues('list') })
  }

  // エラーメッセージ項目表示
  const renderErrorText = (field, className) => {
    for (let i = 0; i < field.length; i++) {
      let errorMessage = ''
      errorMessage = errors[field[i]] && errors[field[i]].message
      if (errorMessage) {
        return <p className={className} data-testid="error-text">{errorMessage}</p>
      }
    }
    return ''
  }

  return (
      <EditDropDownWrapper>
        <Modal show={dropDownState.isLoading || userState.isFetching}><CircularProgress></CircularProgress></Modal>
        <Modal show={showMessage}>
          <div className="modal-body">
            <div className="modal-message-content">{dialogInfo.messageComent + i18n.t(dialogInfo.message)}</div>
            {dialogInfo.type === 'error'
              ? <div className="modal-message-button">
                <OkButton data-testid="message-ok" onClick={() => setShowMessage(false)} />
              </div>
              : <div className="modal-message-button">
                <YesButton data-testid="message-yes" onClick={() => onClickConfirm(dialogInfo)} />
                <NoButton data-testid="message-no" onClick={() => onClickCancel(dialogInfo)} />
              </div>}
          </div>
        </Modal>
        <section className="header">
          <div className="header-title">{i18n.t('DROP-DOWN-MANAGEMENT_DROP-DOWN-MANAGEMENT_PAGE-HEADER_LABEL')}</div>
          <div className="header-actions">
            <CommonButton onClick={() => addRecord()}><FontAwesomeIcon icon={faPlusCircle} />{i18n.t('DROP-DOWN-MANAGEMENT_EDIT-DROP-DOWN_PAGE-ADD-BUTTON_LABEL')}</CommonButton>
            <SaveButton data-testid="btn-save" onClick={() => showDialog('save')} {...errorFields.length > 0 && { disabled: true }}/>
            <BackButton data-testid="btn-cancel" onClick={() => showDialog('cancel')}/>
          </div>
        </section>
        <section className="main">
          <div className="main-title">{location.state.name + ' ' + i18n.t('DROP-DOWN-MANAGEMENT_EDIT-DROP-DOWN_PAGE-MIDDLE-HEADER_LABEL')}</div>
          <div className="remarks">{i18n.t('DROP-DOWN-MANAGEMENT_EDIT-DROP-DOWN_PAGE-REMARKS-HEADER_LABEL')}</div>
          {renderErrorText(['list', 'name-Duplication', 'sort-Duplication'], 'error-text')}
          <form style={{ height: 'calc(100% - 65px)' }}>
            <table className='main-table'>
              <tbody className='table-tbody'>
                <tr className='table-thead-tr'>
                  <th style={{ width: '10%' }}>{i18n.t('DROP-DOWN-MANAGEMENT_EDIT-DROP-DOWN_HEADER-NAME1_LABEL')}</th>
                  <th style={{ width: '50%' }}>{i18n.t('DROP-DOWN-MANAGEMENT_EDIT-DROP-DOWN_HEADER-NAME2_LABEL')}</th>
                  <th style={{ width: '9%' }}>{i18n.t('DROP-DOWN-MANAGEMENT_EDIT-DROP-DOWN_HEADER-NAME3_LABEL')}</th>
                  <th style={{ width: '12%' }}>{i18n.t('DROP-DOWN-MANAGEMENT_EDIT-DROP-DOWN_HEADER-NAME4_LABEL')}</th>
                  <th style={{ width: '10%' }}>{i18n.t('DROP-DOWN-MANAGEMENT_EDIT-DROP-DOWN_HEADER-NAME6_LABEL')}</th>
                  <th style={{ width: '9%' }}>{i18n.t('DROP-DOWN-MANAGEMENT_EDIT-DROP-DOWN_HEADER-NAME5_LABEL')}</th>
                </tr>
                {fields.map((x, i) =>
                  <tr className='table-tbody-tr' key={i}>
                    <td style={{ textAlign: 'center', paddingLeft: '0%' }}>{x.contentId}</td>
                    <td><div key={i} onBlur={onBlur(i, 'name')}><input type="text" className='input-text' maxLength={255} defaultValue={x.name} {...register(`list.${i}.name`)} />{renderErrorText([`list-${i}-name`], 'error-text-row')}</div></td>
                    <td><div key={i} onBlur={onBlur(i, 'sort')} onChange={(e) => { changeSortValue(i) }} onKeyDown={(e) => allowTypeNumericOnly(e)}><input type="number" className='input-number' max="999999999999999999" defaultValue={x.sort} {...register(`list.${i}.sort`)} />{renderErrorText([`list-${i}-sort`], 'error-text-row')}</div></td>
                    <td><div key={i} ><input type="checkbox" className='input-checkbox' {...register(`list.${i}.isNew`)} /></div></td>
                    <td><div key={i} onChange={(e) => { changeInputTypeValue(i) }} onKeyDown={(e) => allowTypeNumericOnly(e)}><input type="number" className='input-number' max="999999999999999999" defaultValue={x.inputType} {...register(`list.${i}.inputType`)} /></div></td>
                    <td><button type='button' className='button' onClick={() => checkDelete(i, x.contentId, `list.${i}.name`)}>{<MaterialCommunityIcons name='delete' size={30} style={{ cursor: 'pointer' }}/>}</button></td>
                  </tr>
                )}
              </tbody>
            </table>
          </form>
        </section>
      </EditDropDownWrapper>)
}
export default EditDropDown
