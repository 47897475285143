import React from 'react'
import PropTypes from 'prop-types'
import { FilterTabWrapper } from './styles'
import { useForm } from 'react-hook-form'
import { SearchButton } from '../Button'
import i18n from 'i18next'
import { advancedSearchOptions } from './Defines/weightSizeTabDefine'

const fieldNames = {
  code: {
    // 製造/車台番号
    name: 'code',
    type: 'text',
    label: 'FORKLIFT_COMMON_CODE_LABEL',
    operator: 'ilike',
    queryName: 'code',
    maxLength: 255,
    tabIndexes: [0]
  },
  maximumLoad: {
  // 最大荷重（ｋｇ）
    label: 'FORKLIFT_COMMON_MAXIMUMLOAD_LABEL',
    name: 'maximumLoad',
    type: 'number',
    tabIndexes: [1, 2]
  },
  vehicleWeight: {
  // 車両重量（ｋｇ）
    label: 'FORKLIFT_COMMON_VEHICLEWEIGHT_LABEL',
    name: 'vehicleWeight',
    type: 'number',
    tabIndexes: [3, 4]
  },
  maximumLiftHeight: {
  // 最大揚高（ｍｍ）
    label: 'FORKLIFT_COMMON_MAXIMUMLIFTHEIGHT_LABEL',
    name: 'maximumLiftHeight',
    type: 'number',
    tabIndexes: [5, 6]
  },
  clawLength: {
  // ツメ長さ（ｍｍ）
    label: 'FORKLIFT_COMMON_CLAWLENGTH_LABEL',
    name: 'clawLength',
    type: 'number',
    tabIndexes: [7, 8]
  }
}

function WeightSizeTab (props) {
  const { onSearchSubmit } = props
  const { register, unregister, handleSubmit, getValues, setValue, formState: { errors }, setError, clearErrors } = useForm()
  const [errorFields, setErrorFields] = React.useState([])
  const allowedChars = '0123456789.'

  const onSubmit = data => {
    const body = prepareSearchBody(data)
    onSearchSubmit(body)
  }

  const prepareSearchBody = data => {
    const body = {}
    body.onSubmit = 'onSubmit'
    for (const key of Object.keys(fieldNames)) {
      const fieldName = fieldNames[key].name
      const type = fieldNames[key].type

      switch (type) {
        case 'text':
          if (data[fieldName].length > 0) body[fieldName] = { ilike: `%${data[fieldName]}%` }
          break
        case 'number':
        case 'month':
        case 'date':
          if (data[fieldName] && data[fieldName].from && data[fieldName].to) {
            body[fieldName] = { between: [data[fieldName].from, data[fieldName].to] }
          } else if (data[fieldName] && data[fieldName].from && !data[fieldName].to) {
            body[fieldName] = { gte: data[fieldName].from }
          } else if (data[fieldName] && !data[fieldName].from && data[fieldName].to) {
            body[fieldName] = { lte: data[fieldName].to }
          }
          break
      }
    }

    for (const key of Object.keys(data)) {
      const [label, searchNumber, optionName] = key.split('_')
      if (label !== 'advanceSearchInput') continue
      if (searchNumber && optionName && data[`advanceSearchLabel_${searchNumber}`] !== optionName) {
        unregister(key)
        continue
      }

      const index = advancedSearchOptions.map((e) => e.value).indexOf(optionName)
      const fieldName = advancedSearchOptions[index].value
      const type = advancedSearchOptions[index].type.split(' - ')[0]
      switch (type) {
        case 'text':
          if (data[key].length > 0) {
            body[fieldName] = {
              ilike: `%${data[key]}%`
            }
          }
          break
        case 'number':
          if (data[key] && data[key].from && data[key].to) {
            body[fieldName] = { between: [data[key].from, data[key].to] }
          } else if (data[key] && data[key].from && !data[key].to) {
            body[fieldName] = { gte: data[key].from }
          } else if (data[key] && !data[key].from && data[key].to) {
            body[fieldName] = { lte: data[key].to }
          }
          break
        case 'date':
          if (data[key] && data[key].from && data[key].to) {
            body[fieldName] = { between: [data[key].from, data[key].to] }
          } else if (data[key] && data[key].from && !data[key].to) {
            body[fieldName] = { gte: data[key].from }
          } else if (data[key] && !data[key].from && data[key].to) {
            body[fieldName] = { lte: data[key].to }
          }
          break
      }
    }
    return body
  }

  const addZeroBeforeDot = (item) => {
    if (getValues(item).length === 1) {
      getValues(item).charAt(0) === '.' && setValue(item, `0${getValues(item)}0`)
    } else {
      getValues(item).charAt(0) === '.' && setValue(item, `0${getValues(item)}`)
    }
  }

  const onBlur = field => () => {
    getValues(`${field}.from`) && addZeroBeforeDot(`${field}.from`)
    getValues(`${field}.to`) && addZeroBeforeDot(`${field}.to`)
    clearErrors([`${field}.from`, `${field}.to`])
    const index = errorFields.indexOf(`${field}.from`)
    index > -1 && errorFields.splice(index, 1)
    const from = getValues(`${field}.from`)
    const to = getValues(`${field}.to`)
    if (parseFloat(from) > parseFloat(to) && !Number.isNaN(parseFloat(from)) && !Number.isNaN(parseFloat(to))) {
      setError(`${field}.from`, {
        type: 'manual',
        message: i18n.t('VEHICLE_VEHICLE-LIST_POPUP-WARNING2_LABEL')
      })
      setErrorFields(prevState => [...prevState, `${field}.from`])
    }
    if ((from && !/^[0-9.]*$/.test(from)) || (to && !/^[0-9.]*$/.test(to))) {
      setError(`${field}.from`, {
        type: 'manual',
        message: i18n.t('VEHICLE_VEHICLE-LIST_WARNING-NUMERIC_LABEL')
      })
      setErrorFields(prevState => [...prevState, `${field}.from`])
    }
  }

  const renderErrorText = field => {
    let errorMessage = ''
    if (errors[field]) {
      errorMessage = errors[field].from || errors[field]
    }
    return errorMessage ? <p className="error-text" data-testid="error-text">{`${errorMessage.message}`}</p> : ''
  }

  const contains = (stringValue, charValue) => {
    return stringValue.indexOf(charValue) > -1
  }
  const integerNumberOnly = ['seatingCapacity', 'licensePlateNumber']
  const allowTypeNumericOnly = (item, e) => {
    // const invalidKey = (e.key.length === 1 && !contains(allowedChars, e.key)) || (e.key === '.' && contains(e.target.value, '.'))
    let invalidKey = null
    if (!integerNumberOnly.includes(item)) {
      invalidKey = (e.key.length === 1 && !contains(allowedChars, e.key)) || (e.key === '.' && contains(e.target.value, '.'))
    } else {
      invalidKey = (e.key.length === 1 && !contains('0123456789', e.key))
    }
    return invalidKey && e.preventDefault()
  }

  const renderInput = options => {
    let component = ''
    switch (options.type) {
      case 'text':
        component = (
          <input
            className="input-item"
            type={options.type}
            tabIndex={options.tabIndexes[0]}
            maxLength={options.maxLength}
            data-testid={options.name}
            {...register(options.name)} />
        )
        break
      case 'number':
        component = (
          <>
            <input
              {...!integerNumberOnly.includes(options.name) && { step: 'any' }}
              onKeyDown={(e) => allowTypeNumericOnly(options.name, e)}
              className="input-item"
              data-testid={`${options.name}-from`}
              type="text" // {inputItem.type}
              tabIndex={options.tabIndexes[0]}
              placeholder="From"
              {...register(`${options.name}.from`)} />
            <input
              {...!integerNumberOnly.includes(options.name) && { step: 'any' }}
              onKeyDown={(e) => allowTypeNumericOnly(options.name, e)}
              className="input-item"
              data-testid={`${options.name}-to`}
              type="text" // {inputItem.type}
              tabIndex={options.tabIndexes[1]}
              placeholder="To"
              {...register(`${options.name}.to`)} />
          </>
        )
        break
    }
    return component
  }

  const renderInputGroup = options => {
    return (
      <>
        <div className="label-text"><label>{i18n.t(options.label)}</label></div>
        <div>
          <div className="input-group" {...(options.type === 'number' && { onBlur: onBlur(options.name) })}>
            {renderInput(options)}
          </div>
          {renderErrorText(options.name)}
        </div>
      </>
    )
  }

  return (
    <FilterTabWrapper>
      <form className="form-container" onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="filter-container">
          <div className="input-container">
            {renderInputGroup(fieldNames.code)}
            {renderInputGroup(fieldNames.maximumLiftHeight)}
          </div>
          <div className="input-container">
            {renderInputGroup(fieldNames.maximumLoad)}
            {renderInputGroup(fieldNames.clawLength)}
          </div>
          <div className="input-container">
            {renderInputGroup(fieldNames.vehicleWeight)}
          </div>
        </div>
        <div className="submit-container" style={{ alignItems: 'center' }}>
          <SearchButton tabIndex={31} type="submit" data-testid="search-submit" {...errorFields.length > 0 && { disabled: true }}/>
        </div>
      </form>
    </FilterTabWrapper>
  )
}

export default WeightSizeTab

WeightSizeTab.propTypes = {
  onSearchSubmit: PropTypes.func
}
