import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { ContractDetailWrapper } from './styles'
import i18n from 'i18next'
import dayjs from 'dayjs'

function sortMaxToMin (arr, key, secondKey, nested) {
  const sortedArr = arr.sort((a, b) => {
    let keyA = a[key]
    let keyB = b[key]
    if (nested) {
      keyA = a[key][nested]
      keyB = b[key][nested]
    }
    // Compare the 2 dates
    if (keyA < keyB) return 1
    if (keyA > keyB) return -1

    // compare the name
    const secKeyA = a[secondKey]
    const secKeyB = b[secondKey]
    return secKeyA.localeCompare(secKeyB)
  })
  return sortedArr
}

function renderLink (number, params, propTypes) {
  if (number <= 0) return <label>{number}</label>
  else {
    const parmsJSON = JSON.stringify(params)
    const linkParms = JSON.parse(parmsJSON)
    if (params.type === 'annualInspection') {
      const from = params.period.from
      linkParms.period.from = dayjs(from).add(-1, 'year').startOf('month').format('YYYY-MM-DD')
      linkParms.period.to = dayjs(from).add(-1, 'year').endOf('month').format('YYYY-MM-DD')
    }
    return <Link to={{ pathname: propTypes, state: linkParms }}>{number}</Link>
  }
}

function renderCompany (key, selectedBranch, selectedId, period, org, detailData, propTypes) {
  let result = []
  if (selectedId.length === 0) {
    // render all company
    // selectedBranch transform to sumed branch
    const transformObjCompany = []
    for (const jj in selectedBranch) {
      transformObjCompany.push({ key: jj, sum: selectedBranch[jj].reduce((partialSum, a) => partialSum + a.value[key], 0) })
    }
    const sortedObj = sortMaxToMin(transformObjCompany, 'sum', 'key')
    // handle limit render
    if (sortedObj.length > 4) {
      const [a, b, c, ...d] = sortedObj
      const firstThree = [a, b, c]
      result = firstThree.map(p => (
        <div className="value-box" key={p.key}>
          <span className="name-box">{p.key}</span>
          {renderLink(p.sum, { period, type: key, companyName: p.key, organizationId: selectedBranch[p.key].map(i => i.id), organizationList: org, detailDataList: detailData, organizationIdDefault: selectedId }, propTypes)}
        </div>
      ))
      // render other companies
      result.push(
        <div className="value-box" key={'d'}>
          <span className="name-box">{i18n.t('CONTRACT_CONTRACT-MANAGEMENT_OTHER_LABEL')}</span>
          {renderLink(d.reduce((partialSum, a) => partialSum + a.sum, 0), { period, type: key, organizationId: [], organizationList: org, detailDataList: detailData, organizationIdDefault: selectedId }, propTypes)}
        </div>
      )
    } else {
      result = sortedObj.map(o => (
        <div className="value-box" key={o.key}>
          <span className="name-box">{o.key}</span>
          {renderLink(o.sum, { period, type: key, companyName: o.key, organizationId: selectedBranch[o.key].map(i => i.id), organizationList: org, detailDataList: detailData, organizationIdDefault: selectedId }, propTypes)}
        </div>
      ))
    }
  } else if (selectedId.length > 0) {
    // render company
    const companyName = Object.keys(selectedBranch)[0]
    result.push(<div className="value-box" key='cop'>
      <span className="name-box">{selectedBranch[companyName][0].company}</span>
      {renderLink(selectedBranch[companyName].reduce((partialSum, a) => partialSum + a.value[key], 0), { period, type: key, companyName, organizationId: selectedBranch[companyName].map(i => i.id), organizationList: org, detailDataList: detailData, organizationIdDefault: selectedId }, propTypes)}
    </div>)
    const sortedBranch = sortMaxToMin(selectedBranch[companyName], 'value', 'branch', key)
    if (sortedBranch.length > 4) {
      const [a, b, c, ...d] = sortedBranch
      const firstThree = [a, b, c]
      result.push(...firstThree.map(t => (
        <div className="value-box" key={t.branch}>
          <span className="inner-name-box">{t.branch}</span>
          {renderLink(t.value[key], { period, type: key, companyName, organizationId: [t.id], organizationList: org, detailDataList: detailData, organizationIdDefault: selectedId }, propTypes)}
        </div>
      )))
      // Other branches
      result.push(
        <div className="value-box" key={companyName}>
          <span className="inner-name-box">{i18n.t('CONTRACT_CONTRACT-MANAGEMENT_OTHER_LABEL')}</span>
          {renderLink(d.reduce((partialSum, a) => partialSum + a.value[key], 0), { period, type: key, companyName, organizationId: d.map(i => i.id), organizationList: org, detailDataList: detailData, organizationIdDefault: selectedId }, propTypes)}
        </div>
      )
    } else {
      // render firsttime no fetch
      result.push(...sortedBranch.map(a => (
        <div className="value-box" key={a.branch}>
          <span className="inner-name-box">{a.branch}</span>
          {renderLink(a.value[key], { period, type: key, companyName, branch: a.branch, organizationId: [a.id], organizationList: org, detailDataList: detailData, organizationIdDefault: selectedId }, propTypes)}
        </div>
      )))
    }
  }

  return result
}

function sumKey (obj) {
  let sum = 0
  for (const i in obj) {
    sum = sum + obj[i]
  }
  return sum
}

function renderType (detailData, org, selectedId, period, type, propTypes) {
  const clusterGroup = org.reduce((r, a) => {
    r[a.company] = r[a.company] || []
    r[a.company].push(a)
    return r
  }, Object.create(null))
  const companyName = selectedId.length > 0 ? Object.keys(clusterGroup)[0] : ''
  const result = type.map(i =>
    (<div key={i.key} className="type-card">
    <div className="header-contain">
      <span id={i.id} className="title-node"><FontAwesomeIcon icon={faCircle} />{i18n.t(i.title)}</span>
      {selectedId && renderLink(sumKey(detailData[i.key]), { period, type: i.key, companyName, organizationId: selectedId, organizationList: org, detailDataList: detailData, organizationIdDefault: selectedId }, propTypes)}
    </div>
    <div className="value-contain">{selectedId && renderCompany(i.key, clusterGroup, selectedId, period, org, detailData, propTypes)}</div>
  </div>)
  )
  return result
}

function ContractDetail (props) {
  const { data, org, lastFetchIds, period, type, propTypes } = props

  const addOrgValue = org.map(item => {
    const newObj = { ...item }
    newObj.value = {
      inspection: data?.inspection?.[item.id] || 0,
      lease: data?.lease?.[item.id] || 0,
      regularMaintenance: data?.regularMaintenance?.[item.id] || 0,
      mandatoryInsurance: data?.mandatoryInsurance?.[item.id] || 0,
      specialVehiclePass: data?.specialVehiclePass?.[item.id] || 0,
      voluntaryInsurance: data?.voluntaryInsurance?.[item.id] || 0,
      annualInspection: data?.annualInspection?.[item.id] || 0,
      rental: data?.rental?.[item.id] || 0
    }
    return newObj
  })

  const showObjByType = lastFetchIds.length > 0 ? addOrgValue.filter(e => lastFetchIds.includes(e.id)) : addOrgValue

  return (
    <ContractDetailWrapper>
      {renderType(data, showObjByType, lastFetchIds, period, type, propTypes)}
    </ContractDetailWrapper>
  )
}

export default ContractDetail

ContractDetail.propTypes = {
  data: PropTypes.object,
  org: PropTypes.array,
  lastFetchIds: PropTypes.any,
  period: PropTypes.object,
  type: PropTypes.any,
  propTypes: PropTypes.string
}
