import axios from 'axios'
import { API_ENDPOINT } from '../../tools/apiEndpoint'

export const forkliftListApi = async ({ page, perPage, sort, body }) => {
  const URL = `${API_ENDPOINT}/forklift/search`
  const params = {
    page,
    perPage
  }
  if (sort) {
    params.sort = `${sort.column} ${sort.by}`
  }
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    params
  }

  const bodyParameters = { ...body }

  const response = await axios.post(
    URL,
    null || bodyParameters,
    config

  )

  return response
}

export const getForkliftApi = async ({ id }) => {
  const URL = `${API_ENDPOINT}/forklift/${id}`

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  }

  const response = await axios.get(
    URL,
    config
  )

  return response
}

export const getForkliftCSVApi = async ({ sort, body }) => {
  const URL = `${API_ENDPOINT}/forklift/export`

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    params: sort ? { sort: `${sort.column} ${sort.by}` } : {}
  }

  const bodyParameters = { ...body }

  return (await axios.post(
    URL,
    null || bodyParameters,
    config
  ))
}

export const createForkliftApi = async ({ exec, body }) => {
  const URL = `${API_ENDPOINT}/forklift?exec=${exec}`
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  }

  const bodyParameters = { ...body }

  const response = await axios.post(
    URL,
    null || bodyParameters,
    config

  )

  return response
}

export const editForkliftApi = async ({ exec, id, body }) => {
  const URL = `${API_ENDPOINT}/forklift/${id}?exec=${exec}`
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  }

  const bodyParameters = { ...body }
  const response = await axios.patch(
    URL,
    bodyParameters,
    config
  )

  return response
}

export const deleteForkliftApi = async ({ id }) => {
  const URL = `${API_ENDPOINT}/forklift/${id}`
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  }
  const response = await axios.delete(
    URL,
    config
  )

  return response
}
