import React from 'react'
import { FilterTabWrapper } from './styles'
import { useForm, FormProvider } from 'react-hook-form'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import { SearchButton } from '../Button'
import { fieldNames, advanceSearchOptions, integerNumberOnly } from './advanceSearchOptions'
import PropTypes from 'prop-types'
import i18n from 'i18next'
import { minimumInputNumber, maximumInputNumber } from '../../tools/constants'
import RegisterMultiSelect from '../RegisterMultiSelect'
import { dropdownCategory, getDropDownOptions } from '../../common/const'
import { dropDownContentListApi } from '../../features/dropdown/dropdownAPI'

export default function DetailVehicleTab (props) {
  const useFormMethods = useForm({ mode: 'onChange' })
  const { register, unregister, handleSubmit, getValues, setValue, watch, formState: { errors }, setError, clearErrors } = useFormMethods
  const { onSearchSubmit } = props
  const [errorFields, setErrorFields] = React.useState([])
  const watchAdvancedSelected = watch([...Array(9).keys()].slice(1).map(x => 'advanceSearchLabel_' + x))
  const allowedChars = '0123456789.'
  const [prevAdvancedSearchVal, setPrevAdvancedSearchVal] = React.useState('')
  const [DropDownList, setDropDownList] = React.useState({})

  React.useEffect(() => {
    setDropDownList({})
    // Get DropDown data
    const dropdownKey = {
      0: dropdownCategory.body,
      1: dropdownCategory.bodyManufacturer,
      2: dropdownCategory.wing,
      3: dropdownCategory.powerGate,
      4: dropdownCategory.powerManufacturer,
      5: dropdownCategory.craneManufacturer,
      6: dropdownCategory.withJorder,
      7: dropdownCategory.airSuspension,
      8: dropdownCategory.refrigeratorManufacturer,
      9: dropdownCategory.refrigeratorModel,
      10: dropdownCategory.inVehicleFreezingEquipment,
      11: dropdownCategory.temperatureRecord,
      12: dropdownCategory.standBy,
      13: dropdownCategory.etcTwo,
      14: dropdownCategory.adBlue,
      15: dropdownCategory.dashcamManufacturer,
      16: dropdownCategory.backEyeCameraManufacturer,
      17: dropdownCategory.cornerSensor,
      18: dropdownCategory.digiTachoManufacture
    }
    Object.entries(dropdownKey).map(async (value) => {
      const response = await dropDownContentListApi({ categoryId: value[1] })
      // setDropDownListを更新
      setDropDownList(prevState => ({ ...prevState, [value[1]]: response.data.rows }))
    })
  }, [])

  const onSubmit = data => {
    const body = prepareSearchBody(data)
    onSearchSubmit(body)
  }

  const prepareSearchBody = data => {
    const body = {}
    body.onSubmit = 'onSubmit'
    for (const key of Object.keys(fieldNames)) {
      const fieldName = fieldNames[key].name
      if (data[fieldName].length > 0) {
        switch (fieldName) {
          case 'licensePlateNumber':
            body[fieldName] = data[fieldName]
            break
          default:
            body[fieldName] = { ilike: `%${data[fieldName]}%` }
            break
        }
      }
    }

    // Advance search gathering
    for (const key of Object.keys(data)) {
      const [label, searchNumber, optionName] = key.split('_')
      if (label !== 'advanceSearchInput') continue
      if (searchNumber && optionName && data[`advanceSearchLabel_${searchNumber}`] !== optionName) {
        unregister(key)
        continue
      }

      const index = advanceSearchOptions.map((e) => e.value).indexOf(optionName)
      const fieldName = advanceSearchOptions[index].value
      const type = advanceSearchOptions[index].type.split(' - ')[0]
      switch (type) {
        case 'text':
          if (data[key].length > 0) {
            body[fieldName] = {
              ilike: `%${data[key]}%`
            }
          }
          break
        case 'number':
          if (data[key] && data[key].from && data[key].to) {
            body[fieldName] = { between: [data[key].from, data[key].to] }
          } else if (data[key] && data[key].from && !data[key].to) {
            body[fieldName] = { gte: data[key].from }
          } else if (data[key] && !data[key].from && data[key].to) {
            body[fieldName] = { lte: data[key].to }
          }
          break
        case 'dropdown':
          if (data[key] && data[key] !== 'All') body[fieldName] = data[key]
          break
      }
    }
    return body
  }

  const getSelectedStatus = (formKey, value) => {
    const curSelected = getValues(formKey)

    const selectedItems = [
      getValues('advanceSearchLabel_1'),
      getValues('advanceSearchLabel_2'),
      getValues('advanceSearchLabel_3'),
      getValues('advanceSearchLabel_4'),
      getValues('advanceSearchLabel_5'),
      getValues('advanceSearchLabel_6'),
      getValues('advanceSearchLabel_7'),
      getValues('advanceSearchLabel_8'),
      getValues('advanceSearchLabel_9')
    ]
    if (value !== curSelected && selectedItems.includes(value)) {
      return true
    }
    return false
  }

  const addZeroBeforeDot = (item) => {
    if (getValues(item).length === 1) {
      getValues(item).charAt(0) === '.' && setValue(item, `0${getValues(item)}0`)
    } else {
      getValues(item).charAt(0) === '.' && setValue(item, `0${getValues(item)}`)
    }
  }

  const errorMessage = `${i18n.t('VEHICLE_VEHICLE-LIST_POPUP-WARNING2_LABEL')}`
  const errorMessage2 = `${i18n.t('VEHICLE_VEHICLE-LIST_WARNING-NUMERIC_LABEL')}`
  const showError = (num) => {
    const advanceSearchValue = getValues(`advanceSearchLabel_${num}`)
    // START: Find datatype
    const idx = advanceSearchOptions.map((e) => e.value).indexOf(advanceSearchValue)
    if (!advanceSearchValue || advanceSearchValue === '1') return
    const advanceSearchItem = advanceSearchOptions[idx]
    const dataType = advanceSearchItem.type && advanceSearchItem.type.split(' - ')
    // END: Find datatype
    const from = getValues(`advanceSearchInput_${num}_${advanceSearchValue}.from`)
    const to = getValues(`advanceSearchInput_${num}_${advanceSearchValue}.to`)
    // START: Clear Error
    const indexErr = errorFields.indexOf(`advanceSearchInput_${num}_${advanceSearchValue}.from`)
    if (indexErr > -1) {
      errorFields.splice(indexErr, 1)
    }
    const indexPrevErr = errorFields.indexOf(`advanceSearchInput_${num}_${prevAdvancedSearchVal}.from`)
    if (indexPrevErr > -1) {
      errorFields.splice(indexPrevErr, 1)
    }
    clearErrors(`advanceSearchInput_${num}_${advanceSearchValue}.from`)
    // END: Clear Error
    if (dataType[0] === 'number') { // Handling error if textbox is numeric
      from && addZeroBeforeDot(`advanceSearchInput_${num}_${advanceSearchValue}.from`)
      to && addZeroBeforeDot(`advanceSearchInput_${num}_${advanceSearchValue}.to`)
      if (parseFloat(from) > parseFloat(to) && !Number.isNaN(from) && !Number.isNaN(to)) {
        setErrorFields(prevState => [...prevState, `advanceSearchInput_${num}_${advanceSearchValue}.from`])
        setError(`advanceSearchInput_${num}_${advanceSearchValue}.from`, { type: 'manual', message: errorMessage })
      }
      if ((from && !/^[0-9.]*$/.test(from)) || (to && !/^[0-9.]*$/.test(to))) {
        setErrorFields(prevState => [...prevState, `advanceSearchInput_${num}_${advanceSearchValue}.from`])
        setError(`advanceSearchInput_${num}_${advanceSearchValue}.from`, { type: 'manual', message: errorMessage2 })
      }
    }
    setPrevAdvancedSearchVal(getValues(`advanceSearchLabel_${num}`))
  }

  const showLicenseError = (item) => {
    const index = errorFields.indexOf(item)
    if (index > -1) {
      errorFields.splice(index, 1)
    }
    clearErrors(item)
    const licenseNum = getValues(item)
    if (licenseNum && !/^[0-9.]*$/.test(licenseNum)) {
      setErrorFields(prevState => [...prevState, item])
      setError(item, { type: 'manual', message: errorMessage2 })
    }
  }

  const contains = (stringValue, charValue) => {
    return stringValue.indexOf(charValue) > -1
  }
  const allowTypeNumericOnly = (e) => {
    const invalidKey = (e.key.length === 1 && !contains(allowedChars, e.key)) || (e.key === '.' && contains(e.target.value, '.'))
    return invalidKey && e.preventDefault()
  }

  const renderOptions = (num) => {
    const newOptions = [...advanceSearchOptions]
    return <div className="input-group input-flex">
      <select className="input-item" tabIndex={parseInt(num) + 3}
        style={{ marginLeft: '0' }}
        {...register(`advanceSearchLabel_${num}`)}
        data-testid={`advanceSearchLabel_${num}`}
        defaultValue={'1'}>
        <option value="1">{i18n.t('COMMON_COMMON_DROPDOWN-PLEASE-SELECT_LABEL')}</option>
        {newOptions.map(({ value, label }, index) => {
          return <option value={value} key={index} disabled={getSelectedStatus(`advanceSearchLabel_${num}`, value)}>{i18n.t(label)}</option>
        })}
      </select>
    </div>
  }

  const renderInput = (num) => {
    const advanceSearchValue = getValues(`advanceSearchLabel_${num}`)
    if (!advanceSearchValue || advanceSearchValue === '1') return
    const index = advanceSearchOptions.map((e) => e.value).indexOf(advanceSearchValue)
    const advanceSearchItem = advanceSearchOptions[index]
    advanceSearchItem.selected = true
    const dataType = advanceSearchItem.type && advanceSearchItem.type.split(' - ')
    switch (dataType[0]) {
      case 'dropdown':
        return (
          <div className="input-group input-flex">
            <RegisterMultiSelect
              className="input-item"
              marginLeft={'10px'}
              placeholder={i18n.t('COMMON_COMMON_DROPDOWN-OPTION-ALL_LABEL')}
              options={getDropDownOptions(advanceSearchItem.options, DropDownList)}
              valueField="key"
              labelField="value"
              element={{ value: `advanceSearchInput_${num}_${advanceSearchValue}` }}
            />
          </div>
        )
      case 'number':
        // Number.isNaN(parseFloat(getValues(`advanceSearchInput_${num}_${advanceSearchValue}.from`))) && setValue(`advanceSearchInput_${num}_${advanceSearchValue}.from`, '')
        // Number.isNaN(parseFloat(getValues(`advanceSearchInput_${num}_${advanceSearchValue}.to`))) && setValue(`advanceSearchInput_${num}_${advanceSearchValue}.to`, '')
        return (
          <div className="input-group input-flex">
            <input tabIndex={parseInt(num) + 3}
              {...!integerNumberOnly.includes(advanceSearchValue) && { step: 'any' }}
              onKeyDown={(e) => allowTypeNumericOnly(e)}
              placeholder='From'
              type='text'
              min={minimumInputNumber}
              max={maximumInputNumber}
              {...register(`advanceSearchInput_${num}_${advanceSearchValue}.from`)}
              data-testid={`advanceSearchInput_${num}_${advanceSearchValue}-from`}
              className="input-item" />
            <input tabIndex={parseInt(num) + 3}
              {...!integerNumberOnly.includes(advanceSearchValue) && { step: 'any' }}
              onKeyDown={(e) => allowTypeNumericOnly(e)}
              placeholder='To'
              type='text'
              min={minimumInputNumber}
              max={maximumInputNumber}
              {...register(`advanceSearchInput_${num}_${advanceSearchValue}.to`)}
              data-testid={`advanceSearchInput_${num}_${advanceSearchValue}-to`}
              className="input-item" />
          </div>)
      default:
        return (
          <div className="input-group input-flex">
            <input tabIndex={parseInt(num) + 3}
              placeholder=''
              type={dataType[0]}
              maxLength={advanceSearchItem.maxLength}
              {...register(`advanceSearchInput_${num}_${advanceSearchValue}`)}
              data-testid={`advanceSearchInput_${num}_${advanceSearchValue}`}
              className="input-item" />
          </div>)
    }
  }

  const renderSelect = (e, i) => {
    return <ul key={i}><li>
      <div className="item-container"
        onBlur={() => { showError(e) }}>
        {!watch(`advanceSearch_${e}`) &&
          <AddCircleOutlineIcon alt="addSearch" className="btn-add"
            style={{ alignSelf: 'center' }}
            {...register(`advanceSearch_${e}`)}
            {...watch(`advanceSearch_${e}`, false)}
            data-testid={`plus-icon-${e}`}
            onClick={() => setValue(`advanceSearch_${e}`, true)} />
        }
        {watch(`advanceSearch_${e}`) && watchAdvancedSelected && renderOptions(e)}
        {watch(`advanceSearchLabel_${e}`) && renderInput(e)}
      </div>
      {errors[`advanceSearchInput_${e}_${getValues(`advanceSearchLabel_${e}`)}`] && (
        <div className="error" data-testid={`error-text-${e}`}>{errors[`advanceSearchInput_${e}_${getValues(`advanceSearchLabel_${e}`)}`].from.message}</div>
      )}
    </li></ul>
  }

  return (
    <FilterTabWrapper>
      <FormProvider {...useFormMethods}>
        <form className="form-container" onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="container">
            <div className="grid">
              <ul style={{ marginBottom: '0' }} ><li>
                <div style={{ flexDirection: 'column' }} >
                  <div className="label-text"><label>{i18n.t('VEHICLE_COMMON_LICENSE-PLATE_LABEL')}</label></div>
                  <div className="input-group" onBlur={(e) => { showLicenseError(fieldNames.licenseNumber.name) }}>
                    <input
                      placeholder={i18n.t('VEHICLE_COMMON_LICENSE-PLATE-ADDRESS_LABEL')}
                      maxLength='255'
                      tabIndex='1'
                      {...register(fieldNames.licenseAddress.name)}
                      data-testid={fieldNames.licenseAddress.name}
                      style={{ marginLeft: '0' }}
                      className="input-item" />
                    <input
                      placeholder={i18n.t('VEHICLE_COMMON_LICENSE-PLATE-CLASS-NO_LABEL')}
                      maxLength='255'
                      tabIndex='2'
                      {...register(fieldNames.licenseClassNo.name)}
                      data-testid={fieldNames.licenseClassNo.name}
                      className="input-item" />
                    <input
                      placeholder={i18n.t('VEHICLE_COMMON_LICENSE-PLATE-NUMBER_LABEL')}
                      onKeyDown={(e) => { (e.key.length === 1 && !contains('0123456789', e.key)) && e.preventDefault() }}
                      type='text'
                      maxLength='4'
                      tabIndex='3'
                      {...register(fieldNames.licenseNumber.name)}
                      data-testid={fieldNames.licenseNumber.name}
                      // onKeyPress={handleTypeNumber}
                      className="input-item" />
                  </div>
                  {errors[fieldNames.licenseNumber.name] && (
                    <div className="error" data-testid={'error-text'}>{errors[fieldNames.licenseNumber.name].message}</div>
                  )}
                </div>
              </li></ul>
              <ul style={{ marginLeft: '20px', marginBottom: '0' }}><li>
                <div style={{ flexDirection: 'column' }} >
                  <div className="label-text"><label>{i18n.t('VEHICLE_VEHICLE-LIST_ADVANCE-SEARCH_LABEL')}</label></div>
                  <div className="item-container"
                    onBlur={() => { showError(1) }}>
                    {watchAdvancedSelected && renderOptions(1)}
                    {watch('advanceSearchLabel_1') && renderInput(1)}
                  </div>
                  {errors[`advanceSearchInput_1_${getValues('advanceSearchLabel_1')}`] && (
                    <div className="error" data-testid="error-text-1">{errors[`advanceSearchInput_1_${getValues('advanceSearchLabel_1')}`].from.message}</div>
                  )}
                </div>
              </li></ul>
            </div>
            <div style={{ display: 'flex' }}>
              <div className="left" >
                {[2, 4, 6, 8].map((e, i) => renderSelect(e, i))}
              </div>
              <div className="middle" >
                {[3, 5, 7, 9].map((e, i) => renderSelect(e, i))}
              </div>
            </div>
            <div className="submit-container">
              <SearchButton tabIndex={26} type="submit" data-testid="search-submit" {...errorFields.length > 0 && { disabled: true }} />
            </div>
          </div>
        </form>
      </FormProvider>
    </FilterTabWrapper>
  )
}

DetailVehicleTab.propTypes = {
  onSearchSubmit: PropTypes.func
}
