import styled from 'styled-components'

export const ForkliftContractManagementWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  section.header {
    padding: 12px;
    display: flex;
    .header-actions {
    }
    .header-title {
      flex: 1;
      font-size: 24px;
    }
    .header-actions {
      padding-bottom: 24px;
      padding-top: 24px;
      border-radius: 5px;
      border: 1px solid #ccc;
      display: flex;
      flex: 1;
      margin: auto;
      .action-options {
        flex-direction: column;
        display: flex;
        margin-left: 24px;
        height: 60px;
        flex: 1;
        label {
          flex: 1;
          max-width: 90px;
        }
        select, input.userid {
          width: 100%;
          border-radius: 6px;
          height: 36px;
          font-size: 14px;
        }
      }
    }
  }
  .submit-container {
    margin-top: auto;
    margin-bottom: auto;
  }
  button.contract-button {
    height: 36px;
    padding: 4px 24px;
  }
  section.content {
    display: grid;
    grid-template: 1fr/5fr 4fr;
    flex: 1;
    margin: 12px;
    margin-bottom: 16px;
    grid-gap: 24px;
    h3 {
      margin-top: 0px;
      margin-bottom: 0px;
      height: 36px;
    }
    .display-month-type-wrapper {
      padding: 0 0 24px 0;
    }
    .filter-scope {
      display: flex;
      gap: 12px;
    }
    .input-date-wrapper {
      font-size: 1.17em;
      font-weight: bold;
      display: flex;
      .label-container {
        display: flex;
        max-height: 36px;
        align-items: center;
        margin-bottom: 10px;
        .select-yaer-month {
          margin-left: 20px;
          margin-bottom: 3px;
          color: #B9103D;
        }
      }
      input[type=date] {
        border: none;
        border:solid 1px #757575;
        border-radius: 5px;
        width: 110px;
        height: 36px;
        margin: 0px 12px;
        font-size: 14px;
        letter-spacing: -1px;
        ::-webkit-calendar-picker-indicator {
            padding: 2px;
            margin: auto;
        }
      }
      p.error-text {
        margin: 0 0 0 10px;
        font-size: 12px;
        color: red;
      }
    }
  }
  table {
    width: 100%;
    // height: calc(100% - 20px);
    border-collapse: collapse;
    // display: block;
    overflow: auto;
    white-space: nowrap;
    table-layout: fixed;
    margin: auto;
    padding: 0;
    td {
      padding:12px;
      font-size: 14px;
    }
    td:nth-of-type(1) {
      background: rgb(235 235 235);
      font-weight: bold;
    }
    thead > tr {
      background: rgb(235 235 235);
      width: 100%;
    }
    td.column-name {
      border: 1px solid rgb(235 235 235);
      font-weight: bold;
    }
    td.value {
      border: 1px solid rgb(235 235 235);
      word-wrap: break-word;
      white-space: normal;
    }
    .group-header {
      text-align: center;
      font-weight: 500;
    }
    .cell-buttons {
      display: flex;
      justify-content: center;
      gap: 10px;
      button {
        min-width: 100px;
      }
    }
    tbody > tr:hover {
      outline: 2px solid red;
    }
    tbody > tr:hover td {
      background-color: #ffa8a8;
      border: 1px solid #ffa8a8;
    }
    tbody > tr.selected-row {
      outline: 2px solid red;
    }
    tr.disabled {
      pointer-events: none;
      background-color: lightgrey;
    }
    .table-message {
      position: sticky;
      z-index: 1;
      left: 0px;
      font-weight: bold;
    }
  }
`
