export const fieldNames = {
  code: {
    // 製造/車台番号
    name: 'code',
    type: 'text',
    label: 'FORKLIFT_COMMON_CODE_LABEL',
    operator: 'ilike',
    queryName: 'code',
    maxLength: 255
  },
  license: {
    // 自動車登録番号
    name: 'license',
    label: 'VEHICLE_COMMON_LICENSE-PLATE_LABEL',
    address: 'license.address',
    classNo: 'license.classNo',
    number: 'license.number',
    isPlate: true
  },
  carNumber: {
    // 社用車番
    name: 'carNumber',
    type: 'text',
    label: 'FORKLIFT_COMMON_CARNUMBER_LABEL',
    operator: 'ilike',
    queryName: 'carNumber',
    maxLength: 255
  },
  status: {
    // 稼働状況
    name: 'status',
    label: 'VEHICLE_COMMON_STATUS_LABEL',
    type: 'dropdown',
    operator: null,
    queryName: 'status',
    options: '40'
  },
  manufacturer: {
    // 製造メーカー
    name: 'manufacturer',
    label: 'FORKLIFT_COMMON_MANUFACTURER_LABEL',
    type: 'dropdown',
    operator: null,
    queryName: 'manufacturer',
    options: '41'
  },
  model: {
    // 型式
    name: 'model',
    type: 'text',
    label: 'VEHICLE_COMMON_MODEL_LABEL',
    operator: 'ilike',
    queryName: 'model',
    maxLength: 255
  },
  bodyShape: {
    // 車体の形状
    name: 'bodyShape',
    label: 'VEHICLE_COMMON_BODY-SHAPE_LABEL',
    type: 'dropdown',
    operator: null,
    queryName: 'bodyShape',
    options: '42'
  },
  power: {
    // 動力
    name: 'power',
    label: 'FORKLIFT_COMMON_POWER_LABEL',
    type: 'dropdown',
    operator: null,
    queryName: 'power',
    options: '43'
  },
  batteryVoltage: {
    // バッテリー電圧
    name: 'batteryVoltage',
    label: 'FORKLIFT_COMMON_BATTERYVOLTAGE_LABEL',
    type: 'dropdown',
    operator: null,
    queryName: 'batteryVoltage',
    options: '44'
  }
}

export const advancedSearchOptions = [
  { value: 'department', label: 'VEHICLE_COMMON_DEPARTMENT_LABEL', type: 'dropdown', maxLength: '', options: ['All'] },
  { value: 'businessUnit', label: 'VEHICLE_COMMON_BUSINESS-UNIT_LABEL', type: 'dropdown', maxLength: '', options: ['All'] },
  { value: 'branchOffice', label: 'VEHICLE_COMMON_BRANCH-OFFICE_LABEL', type: 'dropdown', maxLength: '', options: ['All'] }
]
