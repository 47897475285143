import React from 'react'
import { API_ENDPOINT } from '../../tools/apiEndpoint'
import { LoginWrapper } from './styles'
import { HenngeOneLoginButton, OneLoginLoginButton } from '../../components/Button'
import i18n from 'i18next'

function Login () {
  const [action, setAction] = React.useState()
  function onSubmit (formData) {
    if (formData.nativeEvent.submitter?.name === 'HenngeOne') {
      setAction(`${API_ENDPOINT}/auth/henngeOneLogin`)
    } else {
      setAction(`${API_ENDPOINT}/auth/login`)
    }
  }
  return (
    <LoginWrapper>
      <div className="login-box">
        <div>
          <h2>{i18n.t('LOGIN_LOGIN_WELCOME-MESSAGE_LABEL')}</h2>
        </div>
        <div>
          <h4>{i18n.t('LOGIN_LOGIN_LOGIN-DESC_LABEL')}</h4>
          <form action={action} onSubmit={onSubmit} style={{ flexDirection: 'column', alignItems: 'center' }} method="POST" >
            <input type="hidden" name="client" value={localStorage.getItem('uid') || ''}/>
            <OneLoginLoginButton name="OneLogin" type="submit"/>
            <HenngeOneLoginButton name="HenngeOne" type="submit"/>
          </form>
        </div>
      </div>
    </LoginWrapper>)
}

export default Login
