import styled from 'styled-components'
export const FileGroupWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 36px 12px;
   .no-cursor {
      cursor: default;
   }
   .has-cursor {
    cursor: pointer;
    }
   .pdf-wrapper {
     object {
       pointer-events: none;
       width: 100px;
       height: 80px;
       border: 1px solid black;
    }
  }
`

export const ForkliftDetailWrapper = styled.div`
  height: 100vh;
  h1 {
    flex: 4;
    font-size: 38px;
    font-weight: 400;
  }
  .scrolled-content {
    overflow: auto;
    padding: 0 12px;
    height: calc(100% - 65px);
  }
  span {
    display: flex;
    gap: 10px;
    flex: 1;
    // justify-content: space-between;
    button {
      flex: 1;
      padding: 6px 12px;
    }
  }
  section.header {
    padding: 12px 12px 0px 12px;
    display: flex;
    height 50px;
    background: white;
    z-index: 1;
    .header-title {
      align-self: auto;
      font-size: 24px;
    }
    .header-actions {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      align-items: normal;
      gap: 10px;
    }
  }
  section.general {
    display: grid;
    grid-template: 1fr / 1fr 1fr;
    .vehicle-images {
      padding: 36px;
      .image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
        border: 4px solid ${props => props.theme.primeColor};
      }
    }
  }
  section.general, section.registration, section.weight-size, section.detail-vehicle {
    border: solid 1px #ccc;
    border-radius: 5px;
    padding: 18px;
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    :not(:first-child) {
      margin: 24px 0px;
    }
  }
  div.remarks-contain {
    padding: 0px 12px;
    display: grid;
    column-gap: 5px;
    grid-template: auto / 29.6% 69.4%;
    font-size: 14px;
    line-height: 2;
  }
  div.value {
    position: relative;
    display: grid;
    grid-template: 1fr / 1fr;
    position: relative;
    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    // :hover .tooltip-wrapper {
    //   display: block;
    // }

    .tooltip-wrapper {
      position: absolute;
      display: none;
      bottom: 100%;
      right: 260px;
      width: 0;

      .tooltiptext {
        display: block;
        position: relative;
        right: 100%;
        width: 250px;
        color: #FFFFFF;
        background: #555;
        text-align: center;
        border-radius: 8px;
        z-index: 1;
        word-wrap: break-word;
        white-space: normal;
        padding: 6px;
      }
    }
  }
`
