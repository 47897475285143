import React from 'react'
import PropTypes from 'prop-types'
import ImageGallery from 'react-image-gallery'
import { useSelector, useDispatch } from 'react-redux'
import { VehicleDetailWrapper, FileGroupWrapper } from './styles'
import { getSingleVehicle, setEditing } from '../../../features/vehicles/vehicleSlice'
import { getFile } from '../../../features/file/fileSlice'
import ValueGroup from '../../../components/ValueGroup'
import TruckPlaceholder from '../../../assets/images/truck-placeholder.png'
import PdfThumbnail from '../../../components/PdfThumbnail'
import { userInfo } from './../../../features/users/userInfoSlice'
import { EditButton, BackButton } from '../../../components/Button'
import i18n from 'i18next'
import {
  generalInformationMap,
  registrationInformationMap,
  weightSizeInformationMap,
  detailedVehicleInformationMap
} from './valueMapping'
import { fileCategory, vehicleKind } from '../../../common/const'
function VehicleDetail (props) {
  const dispatch = useDispatch()
  const userState = useSelector(state => state.userInfo)

  const documentNameMapping = {
    doc1: i18n.t('VEHICLE_COMMON_INSPECTION-CERTIFICATE-PREVIOUS_LABEL'),
    doc2: i18n.t('VEHICLE_COMMON_INSPECTION-CERTIFICATE-TWO-TIMES-BEFORE_LABEL'),
    doc3: i18n.t('VEHICLE_COMMON_MANDATORY-INSURANCE-POLICY_LABEL'),
    doc4: i18n.t('VEHICLE_COMMON_ATTACHED-DATA_LABEL'),
    doc5: i18n.t('VEHICLE_COMMON_LAST-CERTIFICATE_LABEL')
  }

  const vehicleValue = useSelector(state => state.vehicle.selectedVehicle)
  const fileState = useSelector(state => state.file)
  const [document1, setDocument1] = React.useState(null)
  const [document2, setDocument2] = React.useState(null)
  const [document3, setDocument3] = React.useState(null)
  const [document5, setDocument5] = React.useState(null)
  React.useEffect(() => {
    if (props.match?.params) {
      dispatch(getSingleVehicle({ id: props.match.params.id }))
      // 車検証を取得
      dispatch(getFile({ id: props.match.params.id, category: fileCategory.Syaken, vehicleKind: vehicleKind.vehicle }))
      // 自賠責証書を取得
      dispatch(getFile({ id: props.match.params.id, category: fileCategory.Zibaiseki, vehicleKind: vehicleKind.vehicle }))
    } else if (props.id) {
      dispatch(getSingleVehicle({ id: props.id }))
      // 車検証を取得
      dispatch(getFile({ id: props.id, category: fileCategory.Syaken, vehicleKind: vehicleKind.vehicle }))
      // 自賠責証書を取得
      dispatch(getFile({ id: props.id, category: fileCategory.Zibaiseki, vehicleKind: vehicleKind.vehicle }))
    }
  }, [])

  React.useEffect(() => {
    dispatch(userInfo())
    dispatch(setEditing(false))
  }, [])

  React.useEffect(() => {
    if (fileState.list.length !== 0) {
      const vicList = fileState.list.filter((x) => { return x.category === fileCategory.Syaken })
      if (vicList.length === 1) {
        if (vicList[0].path !== null) {
          const path = vicList[0].path
          setDocument1(path)
        } else {
          setDocument1(null)
        }
        setDocument2(null)
      } else if (vicList.length === 2) {
        if (vicList[0].path !== null) {
          const document1Path = vicList[0].path
          setDocument1(document1Path)
        } else {
          setDocument1(null)
        }
        if (vicList[1].path !== null) {
          const document2Path = vicList[1].path
          setDocument2(document2Path)
        } else {
          setDocument2(null)
        }
      } else {
        setDocument1(null)
        setDocument2(null)
      }
      const caliList = fileState.list.filter((x) => { return x.category === fileCategory.Zibaiseki })
      if (caliList.length === 1) {
        if (caliList[0].path !== null) {
          const path = caliList[0].path
          setDocument3(path)
        } else {
          setDocument3(null)
        }
        setDocument5(null)
      } else if (caliList.length === 2) {
        if (caliList[0].path !== null) {
          const document3Path = caliList[0].path
          setDocument3(document3Path)
        } else {
          setDocument3(null)
        }
        if (caliList[1].path !== null) {
          const document5Path = caliList[1].path
          setDocument5(document5Path)
        } else {
          setDocument5(null)
        }
      } else {
        setDocument3(null)
        setDocument5(null)
      }
    } else {
      setDocument1(null)
      setDocument2(null)
      setDocument3(null)
      setDocument5(null)
    }
  }, [fileState.list])

  const images = [
    {
      original: vehicleValue.photo1,
      thumbnail: vehicleValue.photo1
    },
    {
      original: vehicleValue.photo2,
      thumbnail: vehicleValue.photo2
    },
    {
      original: vehicleValue.photo3,
      thumbnail: vehicleValue.photo3
    },
    {
      original: vehicleValue.photo4,
      thumbnail: vehicleValue.photo4
    },
    {
      original: vehicleValue.photo5,
      thumbnail: vehicleValue.photo5
    }
  ]

  const imagePlaceHolder = {
    original: TruckPlaceholder,
    thumbnail: TruckPlaceholder
  }

  const defaultImages = [imagePlaceHolder, imagePlaceHolder, imagePlaceHolder, imagePlaceHolder, imagePlaceHolder]
  const filterForImages = images.filter(i => i.original)
  return (
    <VehicleDetailWrapper>
      <section className="header">
        <div className="header-title">{i18n.t('VEHICLE_VEHICLE-INFORMATION_PAGE-HEADER_LABEL')}</div>
        <div className="header-actions">
          {
            (userState.isEdit || (userState.isAdmin && userState.isEdit))
              ? <EditButton data-testid="edit-button" onClick={() => props.onEdit ? props.onEdit(props.id) : props.history.push(`/editinfo/${props.onBack ? props.id : props.match.params.id}/edit`)} />
              : ''
          }
          <BackButton data-testid="back-button" onClick={() => props.onBack ? props.onBack(false) : props.history.push('/vehicle')} />
        </div>
      </section>
      <div className="scrolled-content">
        <section className="general">
          <div className="vehicle-images">
            <ImageGallery items={filterForImages.length > 0 ? filterForImages : defaultImages} infinite={false} showPlayButton={false} showFullscreenButton={false} />
          </div>
          <div>
            <ValueGroup
              title={i18n.t('VEHICLE_COMMON_GENERAL-INFO_LABEL')}
              valueLabels={generalInformationMap}
              values={vehicleValue}
            />
          </div>
        </section>
        <section className="registration">
          <ValueGroup
            title={i18n.t('VEHICLE_COMMON_REGISTRATION-INFO_LABEL')}
            valueLabels={registrationInformationMap}
            values={vehicleValue}
            column={2}
            row={11}
          />
          <FileGroupWrapper>
            <PdfThumbnail file={document1} title={documentNameMapping.doc1} />
            <PdfThumbnail file={document2} title={documentNameMapping.doc2} />
            <PdfThumbnail file={document3} title={documentNameMapping.doc3} />
            <PdfThumbnail file={document5} title={documentNameMapping.doc5} />
            <PdfThumbnail file={vehicleValue.document4} title={documentNameMapping.doc4} />
          </FileGroupWrapper>
        </section>
        <section className="weight-size">
          <ValueGroup
            title={i18n.t('VEHICLE_COMMON_WEIGHT-SIZE-INFO_LABEL')}
            valueLabels={weightSizeInformationMap}
            values={vehicleValue}
            column={2}
            row={16}
          />
              <FileGroupWrapper>
                <PdfThumbnail file={vehicleValue.blueprint1} />
                <PdfThumbnail file={vehicleValue.blueprint2} />
                <PdfThumbnail file={vehicleValue.blueprint3} />
              </FileGroupWrapper>

        </section>
        <section className="detail-vehicle">
          <ValueGroup
            title={i18n.t('VEHICLE_COMMON_DETAILED-VEHICLE-INFO_LABEL')}
            valueLabels={detailedVehicleInformationMap}
            values={vehicleValue}
            column={1}
          />
        </section>
      </div>
    </VehicleDetailWrapper>
  )
}

export default VehicleDetail

VehicleDetail.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  id: PropTypes.string,
  onBack: PropTypes.func,
  onEdit: PropTypes.func
}
