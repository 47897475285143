import i18n from 'i18next'

export const fileCategory = {
  // 車検証
  Syaken: '1',
  // 自賠責証書
  Zibaiseki: '2',
  // 車両画像
  Syaryou: '3',
  // 年次点検報告書
  NenziTenkin: '4',
  // 購入時契約書
  KounyuziKeiyaku: '5',
  // レンタル時契約書
  Rental: '6',
  // 任意保険証
  NiniHoken: '7'
}

export const dropdownCategory = {
  type: '1',
  brand: '2',
  class: '3',
  bodyShape: '4',
  fuelType: '5',
  status: '6',
  withLease: '7',
  expensesIncludingLease: '8',
  regularMaintenanceCycle: '9',
  voluntaryInsuranceType: '10',
  usage: '11',
  privateBusinessUse: '12',
  transmission: '13',
  cabinColor: '14',
  // bodyColor: '15',
  sbsMarking: '16',
  tireManufacturer: '17',
  snowTire: '18',
  tireChain: '19',
  spareTire: '20',
  body: '21',
  bodyManufacturer: '22',
  wing: '23',
  powerGate: '24',
  powerManufacturer: '25',
  craneManufacturer: '26',
  withJorder: '27',
  airSuspension: '28',
  refrigeratorManufacturer: '29',
  refrigeratorModel: '30',
  inVehicleFreezingEquipment: '31',
  temperatureRecord: '32',
  standBy: '33',
  etcTwo: '34',
  adBlue: '35',
  dashcamManufacturer: '36',
  backEyeCameraManufacturer: '37',
  cornerSensor: '38',
  digiTachoManufacture: '39',
  status_Forklift: '40',
  manufacturer: '41',
  bodyShape_Forklift: '42',
  power: '43',
  batteryVoltage: '44',
  holdingFormat: '45',
  owner: '46',
  user_Forklift: '47',
  expensesIncludingLease_Forklift: '48',
  voluntaryInsuranceType_Forklift: '49',
  dashcamManufacturer_Forklift: '50',
  horizontalLaserManufacturer: '51',
  rearLightingManufacturer: '52',
  sensorsManufacturer: '53',
  attachment: '54',
  user_vehicle: '55'
}

export const vehicleKind = {
  // 車両
  vehicle: '1',
  // フォークリフト
  forklift: '2'
}

export const perPageOptions = [
  { value: '20件', key: 20 },
  { value: '50件', key: 50 },
  { value: '100件', key: 100 },
  { value: '500件', key: 500 },
  { value: '1000件', key: 1000 }
]

export const getDropDownOptions = (index, list) => {
  let Options = []
  Object.entries(list).map(async (value) => {
    if (value[0] === index) {
      Options = value[1].map(item => {
        return { key: item.id, value: item.name }
      })
      Options = Options.filter(v => v)
      Options = [{ key: 'All', value: i18n.t('COMMON_COMMON_DROPDOWN-OPTION-ALL_LABEL') }, ...Options]
    }
  })
  return Options
}
