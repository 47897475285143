import axios from 'axios'
import { API_ENDPOINT } from '../../tools/apiEndpoint'

export const getForkliftContractApi = async (body) => {
  const URL = `${API_ENDPOINT}/forkliftContracts/monthly`

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  }

  const bodyParameters = {
    year: body.year
  }

  if (body.organizationId.length > 0) {
    bodyParameters.organizationId = body.organizationId
  }
  return (await axios.post(
    URL,
    null || bodyParameters,
    config
  ))
}

export const getForkliftContractDetailApi = async (body) => {
  const URL = `${API_ENDPOINT}/forkliftContracts/detail`

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  }

  const bodyParameters = {
    from: body.from,
    to: body.to
  }

  if (body.organizationId.length > 0) {
    bodyParameters.organizationId = body.organizationId
  }

  return (await axios.post(
    URL,
    null || bodyParameters,
    config
  ))
}
