import { dropdownCategory } from '../../../common/const'

export const fieldNames = {
  license: {
    name: 'license',
    label: 'VEHICLE_COMMON_LICENSE-PLATE_LABEL',
    address: 'license.address',
    classNo: 'license.classNo',
    number: 'license.number',
    isPlate: true
  },
  carNumber: {
    name: 'carNumber',
    label: 'VEHICLE_COMMON_CAR-NUMBER_LABEL',
    type: 'text',
    operator: 'ilike',
    queryName: 'carNumber',
    maxLength: 255
  },
  type: {
    name: 'type',
    label: 'VEHICLE_COMMON_TYPE_LABEL',
    type: 'select',
    operator: null,
    queryName: 'type',
    options: dropdownCategory.type
  },
  brand: {
    name: 'brand',
    label: 'VEHICLE_COMMON_BRAND_LABEL',
    type: 'select',
    operator: null,
    queryName: 'brand',
    options: dropdownCategory.brand
  },
  pastLicense: {
    name: 'pastLicense',
    type: 'text',
    label: 'VEHICLE_COMMON_PAST-LICENSE-PLATE_LABEL',
    operator: 'ilike',
    queryName: 'pastLicensePlate',
    maxLength: 255
  },
  vehicleId: {
    name: 'vehicleId',
    type: 'text',
    label: 'VEHICLE_COMMON_VEHICLE-ID_LABEL',
    operator: 'ilike',
    queryName: 'code',
    maxLength: 255
  },
  class: {
    name: 'class',
    type: 'select',
    label: 'VEHICLE_COMMON_CLASS_LABEL',
    operator: null,
    queryName: 'class',
    options: dropdownCategory.class
  },
  bodyShape: {
    name: 'bodyShape',
    type: 'select',
    label: 'VEHICLE_COMMON_BODY-SHAPE_LABEL',
    operator: null,
    queryName: 'bodyShape',
    options: dropdownCategory.bodyShape
  },
  regisDate: {
    name: 'regisDate',
    type: 'date',
    label: 'VEHICLE_COMMON_REGISTRATION-DATE_LABEL',
    operator: null,
    queryName: 'registrationDate',
    range: true
  },
  specialEquipment: {
    name: 'specialEquipment',
    type: 'text',
    label: 'VEHICLE_COMMON_SPECIAL-EQUIPMENT_LABEL',
    operator: 'ilike',
    queryName: 'specialEquipment',
    maxLength: 255
  },
  initRegisDate: {
    name: 'initRegisDate',
    label: 'VEHICLE_COMMON_INITIAL-REGISTRATION-YEAR-MONTH_LABEL',
    type: 'date',
    operator: null,
    queryName: 'initialRegistrationDate',
    range: true
  },
  model: {
    name: 'model',
    label: 'VEHICLE_COMMON_MODEL_LABEL',
    type: 'text',
    operator: 'ilike',
    queryName: 'model',
    maxLength: 255
  },
  engineModel: {
    name: 'engineModel',
    type: 'text',
    label: 'VEHICLE_COMMON_ENGINE-MODEL_LABEL',
    operator: 'ilike',
    queryName: 'engineModel',
    maxLength: 255
  },
  totalDisplace: {
    name: 'totalDisplace',
    label: 'VEHICLE_COMMON_TOTAL-DISPLACEMENT_LABEL',
    type: 'number',
    operator: null,
    queryName: 'totalDisplacement',
    range: true
  },
  fuelType: {
    name: 'fuelType',
    label: 'VEHICLE_COMMON_FUEL-TYPE_LABEL',
    type: 'select',
    operator: null,
    queryName: 'fuelType',
    options: dropdownCategory.fuelType
  },
  status: {
    name: 'status',
    label: 'VEHICLE_COMMON_STATUS_LABEL',
    type: 'select',
    operator: null,
    queryName: 'status',
    options: dropdownCategory.status
  }
}

export const advancedSearchOptions = [
  { value: 'department', label: 'VEHICLE_COMMON_DEPARTMENT_LABEL', type: 'dropdown', maxLength: '', options: ['All'] },
  { value: 'businessUnit', label: 'VEHICLE_COMMON_BUSINESS-UNIT_LABEL', type: 'dropdown', maxLength: '', options: ['All'] },
  { value: 'branchOffice', label: 'VEHICLE_COMMON_BRANCH-OFFICE_LABEL', type: 'dropdown', maxLength: '', options: ['All'] }
]
