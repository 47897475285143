import styled from 'styled-components'
export const ForkliftWrapper = styled.div`
  height: 100vh;
  display: ${props => props.show ? 'none' : 'flex'};
  flex-direction: column;
  section.header {
    margin: 12px;
    min-height: 36px;
    align-items: center;
    display: flex;
    .header-title {
      align-self: normal;
      font-size: 24px;
      margin-right: 12px;
    }
    .header-actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: auto;
      flex: 1;
      gap 20px;
      .action-options {
        display: flex;
        gap: 5px;
        label {
          flex: 1;
          overflow: hidden;
          justify-content: flex-end;
          display: flex;
          align-items: center;
        }
      }
    }
  }
  thead {
    tr, td {
      position: sticky;
      top: 0;
      z-index: 0;
      &:first-child {
        background: rgb(235 235 235);
        left: 0;
        z-index: 1;
      }
    }
  }
  tbody {
    td:first-child {
      background-color: #ffffff;
      position: sticky;
      left: 0;
      z-index: 0;
    }
    tr.row-click:hover td {
      background-color: #ebebeb;
    }
  }
  section.tab {
    margin: 12px;
  }
  section.list {
    margin: 12px;
    flex: 1;
    height: calc(100% - 610px);
    .list-action-wrapper {
      display: flex;
      justify-content: flex-end;
      gap: 16px;
      align-items: center;
      margin-bottom: 12px;
      height: 36px;
    }
    // tbody > tr.row-click:hover {
    //   background-color: #ebebeb !important;
    // }
    td.number-field {
      text-align: end;
    }
  }
  select.perpage-item {
    flex: 1 1 auto;
    width: 200px;
    height: 36px;
    border: none;
    border:solid 1px #757575;
    border-radius: 5px;
    font-size: 14px;
  }
  select.perpage-item option {
    color: initial;
  }
  select.perpage-item option[disabled] {
    color: gray;
  }

`
