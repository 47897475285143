import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'
import { importForkliftApi } from './importForkliftAPI'
const initialState = {
  check: {
    load: false,
    error: {},
    success: {}
  },
  import: {
    load: false,
    data: {}
  }
}

export const importForklift = createAsyncThunk(
  'forklift/import',
  async (props, { rejectWithValue }) => {
    try {
      const response = await importForkliftApi({ ...props, exec: true })
      return response.data
    } catch (err) {
      if (err.response.status >= 500) {
        return rejectWithValue({ error: { unknown: true } })
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const checkForklift = createAsyncThunk(
  'forklift/import-check',
  async (props, { rejectWithValue }) => {
    try {
      const response = await importForkliftApi({ ...props, exec: false })
      // console.log('RES', response);
      return response.data
    } catch (err) {
      if (err.response.status >= 500) {
        return rejectWithValue({ error: { unknown: true } })
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const importForkliftSlice = createSlice({
  name: 'importForklift',
  initialState,
  reducers: {
    clearState: (state) => {
      state.check.load = false
      state.check.error = {}
      state.check.success = {}
      state.import.data = {}
      return state
    }
  },
  extraReducers: {
    [checkForklift.fulfilled]: (state, { payload }) => {
      state.check.load = false
      state.check.success = payload
      return state
    },
    [checkForklift.rejected]: (state, { payload }) => {
      state.check.load = false
      if (typeof payload.error === 'undefined') {
        state.check.error = payload
      } else {
        state.check.error = payload.error
      }
    },
    [checkForklift.pending]: (state) => {
      state.check.load = true
      state.check.success = {}
      state.check.error = {}
      state.import.data = {}
    },
    [importForklift.fulfilled]: (state, { payload }) => {
      state.import.load = false
      state.import.data = payload
      return state
    },
    [importForklift.rejected]: (state, { payload }) => {
      state.import.load = false
      if (typeof payload.error === 'undefined') {
        state.check.error = payload
      } else {
        state.check.error = payload.error
      }
    },
    [importForklift.pending]: (state) => {
      state.import.load = true
      state.check.success = {}
      state.import.data = {}
    }
  }
})

export const { clearState } = importForkliftSlice.actions
export default importForkliftSlice.reducer
