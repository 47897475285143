import React from 'react'
import { FilterTabWrapper } from './styles'
import { useForm, FormProvider } from 'react-hook-form'
import { SearchButton } from '../Button'
import PropTypes from 'prop-types'
import i18n from 'i18next'
import RegisterMultiSelect from '../RegisterMultiSelect'
import { dropDownContentListApi } from '../../features/dropdown/dropdownAPI'
import { dropdownCategory, getDropDownOptions } from '../../common/const'

const fieldNames = {
  code: {
    // 製造/車台番号
    name: 'code',
    type: 'text',
    label: 'FORKLIFT_COMMON_CODE_LABEL',
    operator: 'ilike',
    queryName: 'code',
    maxLength: 255,
    tabIndexes: [0]
  },
  dashcamManufacturer: {
    // ドラレコメーカー
    label: 'FORKLIFT_COMMON_DASHCAMMANUFACTURER_LABEL',
    name: 'dashcamManufacturer',
    type: 'dropdown',
    selectOptions: '50',
    tabIndexes: [1]
  },
  horizontalLaserManufacturer: {
    // 水平レーザー
    label: 'FORKLIFT_COMMON_HORIZONTALLASERMANUFACTURER_LABEL',
    name: 'horizontalLaserManufacturer',
    type: 'dropdown',
    selectOptions: '51',
    tabIndexes: [2]
  },
  rearLightingManufacturer: {
    // 後方床面照射ライト
    label: 'FORKLIFT_COMMON_REARLIGHTINGMANUFACTURER_LABEL',
    name: 'rearLightingManufacturer',
    type: 'dropdown',
    selectOptions: '52',
    tabIndexes: [3]
  },
  sensorsManufacturer: {
    // 対人対物センサー
    label: 'FORKLIFT_COMMON_SENSORSMANUFACTURER_LABEL',
    name: 'sensorsManufacturer',
    type: 'dropdown',
    selectOptions: '53',
    tabIndexes: [4]
  },
  attachment: {
    // アタッチメント
    label: 'FORKLIFT_COMMON_ATTACHMENT_LABEL',
    name: 'attachment',
    type: 'text',
    maxLength: 255,
    tabIndexes: [5]
  },
  importantNotes: {
    // 備考
    label: 'FORKLIFT_COMMON_IMPORTANTNOTES_LABEL',
    name: 'importantNotes',
    type: 'text',
    maxLength: 255,
    tabIndexes: [6]
  }
}

export default function DetailVehicleTab (props) {
  const { onSearchSubmit } = props
  const useFormMethods = useForm()
  const { register, handleSubmit, formState: { errors }, setError, getValues, setValue, clearErrors } = useFormMethods
  const [errorFields, setErrorFields] = React.useState([])
  const [DropDownList, setDropDownList] = React.useState({})

  React.useEffect(() => {
    setDropDownList({})
    // Get DropDown data
    const dropdownKey = {
      0: dropdownCategory.dashcamManufacturer_Forklift,
      1: dropdownCategory.horizontalLaserManufacturer,
      2: dropdownCategory.rearLightingManufacturer,
      3: dropdownCategory.sensorsManufacturer
    }
    Object.entries(dropdownKey).map(async (value) => {
      const response = await dropDownContentListApi({ categoryId: value[1] })
      // setDropDownListを更新
      setDropDownList(prevState => ({ ...prevState, [value[1]]: response.data.rows }))
    })
  }, [])

  const onSubmit = data => {
    const body = prepareSearchBody(data)
    onSearchSubmit(body)
  }

  const prepareSearchBody = data => {
    const body = {}
    body.onSubmit = 'onSubmit'
    for (const key of Object.keys(fieldNames)) {
      const fieldName = fieldNames[key].name
      const type = fieldNames[key].type

      switch (type) {
        case 'text':
          if (data[fieldName].length > 0) body[fieldName] = { ilike: `%${data[fieldName]}%` }
          break
        case 'number':
        case 'month':
        case 'date':
          if (data[fieldName].from && data[fieldName].to) {
            body[fieldName] = { between: [data[fieldName].from, data[fieldName].to] }
          } else if (data[fieldName].from && !data[fieldName].to) {
            body[fieldName] = { gte: data[fieldName].from }
          } else if (!data[fieldName].from && data[fieldName].to) {
            body[fieldName] = { lte: data[fieldName].to }
          }
          break
        case 'dropdown':
          if (data[fieldName] !== 'All') body[fieldName] = data[fieldName]
          break
      }
    }

    return body
  }

  const addZeroBeforeDot = (item) => {
    if (getValues(item).length === 1) {
      getValues(item).charAt(0) === '.' && setValue(item, `0${getValues(item)}0`)
    } else {
      getValues(item).charAt(0) === '.' && setValue(item, `0${getValues(item)}`)
    }
  }

  const onNumberBlur = field => () => {
    getValues(`${field}.from`) && addZeroBeforeDot(`${field}.from`)
    getValues(`${field}.to`) && addZeroBeforeDot(`${field}.to`)
    clearErrors([`${field}.from`, `${field}.to`])
    const index = errorFields.indexOf(`${field}.from`)
    if (index > -1) {
      errorFields.splice(index, 1)
    }
    const from = getValues(`${field}.from`)
    const to = getValues(`${field}.to`)
    if (parseFloat(from) > parseFloat(to) && !Number.isNaN(parseFloat(from)) && !Number.isNaN(parseFloat(to))) {
      setError(`${field}.from`, {
        type: 'manual',
        message: i18n.t('VEHICLE_VEHICLE-LIST_POPUP-WARNING2_LABEL')
      })
      setErrorFields(prevState => [...prevState, `${field}.from`])
    }
    if ((from && !/^[0-9.]*$/.test(from)) || (to && !/^[0-9.]*$/.test(to))) {
      setError(`${field}.from`, {
        type: 'manual',
        message: i18n.t('VEHICLE_VEHICLE-LIST_WARNING-NUMERIC_LABEL')
      })
      setErrorFields(prevState => [...prevState, `${field}.from`])
    }
  }

  const onDateBlur = field => () => {
    clearErrors([`${field}.from`, `${field}.to`])
    const from = Date.parse(getValues(`${field}.from`))
    const to = Date.parse(getValues(`${field}.to`))
    if (from > to && !Number.isNaN(from) && !Number.isNaN(to)) {
      setError(`${field}.from`, {
        type: 'manual',
        message: i18n.t('VEHICLE_VEHICLE-LIST_POPUP-WARNING2_LABEL')
      })
      setErrorFields(prevState => [...prevState, `${field}.from`])
    } else {
      const index = errorFields.indexOf(`${field}.from`)
      if (index > -1) {
        errorFields.splice(index, 1)
      }
    }
  }

  const renderErrorText = field => {
    let errorMessage = ''
    if (errors[field]) {
      errorMessage = errors[field].message || errors[field].from.message
    }
    return errorMessage ? <p data-testid={`${field}-error-text`} className="error-text">{`${errorMessage}`}</p> : ''
  }

  const renderInput = options => {
    let component = ''
    switch (options.type) {
      case 'dropdown':
        component =
            <RegisterMultiSelect
              className="input-item"
              marginLeft={'10px'}
              placeholder={i18n.t('COMMON_COMMON_DROPDOWN-OPTION-ALL_LABEL')}
              options={getDropDownOptions(options.selectOptions, DropDownList)}
              valueField="key"
              labelField="value"
              element={{ value: options.name }}
            />
        break
      case 'text':
        component = (
          <input
            className="input-item"
            type={options.type}
            tabIndex={options.tabIndexes[0]}
            maxLength={options.maxLength}
            data-testid={options.name}
            {...register(options.name)} />
        )
        break
    }
    return component
  }

  const renderInputGroup = options => {
    let onBlur = null
    if (options.type === 'date' || options.type === 'month') onBlur = onDateBlur(options.name)
    if (options.type === 'number') onBlur = onNumberBlur(options.name)

    return (
      <>
        <div className="label-text"><label>{i18n.t(options.label)}</label></div>
        <div>
          <div data-testid={`${options.name}-input-group`} className="input-group" onBlur={onBlur}>{renderInput(options)}</div>
          {renderErrorText(options.name)}
        </div>
      </>
    )
  }

  return (
    <FilterTabWrapper>
      <FormProvider {...useFormMethods}>
      <form className="form-container" onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="filter-container">
          <div className="input-container">
            {renderInputGroup(fieldNames.code)}
            {renderInputGroup(fieldNames.rearLightingManufacturer)}
            {renderInputGroup(fieldNames.importantNotes)}
          </div>
          <div className="input-container">
            {renderInputGroup(fieldNames.dashcamManufacturer)}
            {renderInputGroup(fieldNames.sensorsManufacturer)}
          </div>
          <div className="input-container">
            {renderInputGroup(fieldNames.horizontalLaserManufacturer)}
            {renderInputGroup(fieldNames.attachment)}
          </div>
        </div>
        <div className="submit-container">
          <SearchButton data-testid="submit-button" tabIndex={34} type="submit" {...errorFields.length > 0 && { disabled: true }} />
        </div>
      </form>
      </FormProvider>
    </FilterTabWrapper>
  )
}

DetailVehicleTab.propTypes = {
  onSearchSubmit: PropTypes.func,
  defaultValue: PropTypes.object
}
