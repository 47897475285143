import React from 'react'
import PropTypes from 'prop-types'
import { useForm, FormProvider } from 'react-hook-form'
import { FilterTabWrapper } from './styles'
import i18n from 'i18next'
import dayjs from 'dayjs'
import RegisterMultiSelect from '../RegisterMultiSelect'
// import { minimumInputNumber, maximumInputNumber } from '../../tools/constants'
import { SearchButton } from '../Button'
import { integerNumberOnly } from './advanceSearchOptions'
import { dropDownContentListApi } from '../../features/dropdown/dropdownAPI'
import { dropdownCategory, getDropDownOptions } from '../../common/const'

const fieldNames = {
  code: {
    // 製造/車台番号
    name: 'code',
    type: 'text',
    label: 'FORKLIFT_COMMON_CODE_LABEL',
    operator: 'ilike',
    queryName: 'code',
    maxLength: 255,
    tabIndexes: [0]
  },
  purchaseDate: {
    // 購入/契約年月
    name: 'purchaseDate',
    type: 'month',
    label: 'FORKLIFT_COMMON_PURCHASEDATE_LABEL',
    tabIndexes: [1, 2]
  },
  holdingFormat: {
  // 保有形式
    label: 'FORKLIFT_COMMON_HOLDINGFORMAT_LABEL',
    name: 'holdingFormat',
    type: 'dropdown',
    selectOptions: '45',
    tabIndexes: [3, 4]
  },
  owner: {
  // 所有者
    label: 'FORKLIFT_COMMON_OWNER_LABEL',
    name: 'owner',
    type: 'dropdown',
    selectOptions: '46',
    tabIndexes: [5]
  },
  user: {
  // 使用者
    label: 'FORKLIFT_COMMON_USER_LABEL',
    name: 'user',
    type: 'dropdown',
    selectOptions: '47',
    tabIndexes: [6]
  },
  userAddress: {
  // 使用の本拠の位置
    label: 'FORKLIFT_COMMON_USERADDRESS_LABEL',
    name: 'userAddress',
    type: 'text',
    maxLength: 255,
    tabIndexes: [7]
  },

  annualInspectionDate: {
  // 年次点検日（前回）
    label: 'FORKLIFT_COMMON_ANNUALINSPECTIONDATE_LABEL',
    name: 'annualInspectionDate',
    type: 'date',
    tabIndexes: [8, 9]
  },
  expensesIncludingLease: {
  // リース代に含む諸費用
    label: 'FORKLIFT_COMMON_EXPENSESINCLUDINGLEASE_LABEL',
    name: 'expensesIncludingLease',
    type: 'dropdown',
    selectOptions: '48',
    tabIndexes: [10]
  },
  leaseEndDate: {
  // リース期限
    label: 'FORKLIFT_COMMON_LEASEENDDATE_LABEL',
    name: 'leaseEndDate',
    type: 'date',
    tabIndexes: [11, 12]
  },
  leaseFee: {
  // 月額リース料
    label: 'FORKLIFT_COMMON_LEASEFEE_LABEL',
    name: 'leaseFee',
    type: 'number',
    tabIndexes: [13, 14]
  },
  rentalEndDate: {
  // レンタル期限
    label: 'FORKLIFT_COMMON_RENTALENDDATE_LABEL',
    name: 'rentalEndDate',
    type: 'date',
    tabIndexes: [15, 16]
  },
  rentalFee: {
  // 月額レンタル料
    label: 'FORKLIFT_COMMON_RENTALFEE_LABEL',
    name: 'rentalFee',
    type: 'number',
    tabIndexes: [17, 18]
  },
  inspectionExpireDate: {
  // 車検証期限
    label: 'FORKLIFT_COMMON_INSPECTIONEXPIREDATE_LABEL',
    name: 'inspectionExpireDate',
    type: 'date',
    tabIndexes: [19, 20]
  },
  automobileTax: {
  // 自動車税
    label: 'FORKLIFT_COMMON_AUTOMOBILETAX_LABEL',
    name: 'automobileTax',
    type: 'number',
    tabIndexes: [21, 22]
  },
  weightTax: {
  // 重量税
    label: 'FORKLIFT_COMMON_WEIGHTTAX_LABEL',
    name: 'weightTax',
    type: 'number',
    tabIndexes: [23, 24]
  },
  mandatoryInsuranceEndDate: {
  // 自賠責保険期限
    label: 'FORKLIFT_COMMON_MANDATORYINSURANCEENDDATE_LABEL',
    name: 'mandatoryInsuranceEndDate',
    type: 'date',
    tabIndexes: [25, 26]
  },
  voluntaryInsuranceType: {
  // 任意保険種別
    label: 'FORKLIFT_COMMON_VOLUNTARYINSURANCETYPE_LABEL',
    name: 'voluntaryInsuranceType',
    type: 'dropdown',
    selectOptions: '49',
    tabIndexes: [27]
  },
  voluntaryInsuranceEndDate: {
  // 任意保険期限
    label: 'FORKLIFT_COMMON_VOLUNTARYINSURANCEENDDATE_LABEL',
    name: 'voluntaryInsuranceEndDate',
    type: 'date',
    tabIndexes: [28, 29]
  }
}

function RegistrationTab (props) {
  const { onSearchSubmit, defaultValue } = props
  const useFormMethods = useForm()
  const { register, handleSubmit, formState: { errors }, setError, getValues, setValue, clearErrors, watch } = useFormMethods
  const [errorFields, setErrorFields] = React.useState([])
  const [DropDownList, setDropDownList] = React.useState({})
  const allowedChars = '0123456789.'

  React.useEffect(() => {
    setDropDownList({})
    // Get DropDown data
    const dropdownKey = {
      0: dropdownCategory.holdingFormat,
      1: dropdownCategory.owner,
      2: dropdownCategory.user_Forklift,
      3: dropdownCategory.expensesIncludingLease_Forklift,
      4: dropdownCategory.voluntaryInsuranceType_Forklift
    }
    Object.entries(dropdownKey).map(async (value) => {
      const response = await dropDownContentListApi({ categoryId: value[1] })
      // setDropDownListを更新
      setDropDownList(prevState => ({ ...prevState, [value[1]]: response.data.rows }))
    })
  }, [])

  const onSubmit = data => {
    const body = prepareSearchBody(data)
    onSearchSubmit(body)
  }

  const prepareSearchBody = data => {
    const body = {}
    body.onSubmit = 'onSubmit'
    for (const key of Object.keys(fieldNames)) {
      const fieldName = fieldNames[key].name
      const type = fieldNames[key].type

      switch (type) {
        case 'license':
          if (data[fieldName].length > 0) {
            switch (fieldName) {
              case 'licensePlateNumber':
                body[fieldName] = data[fieldName]
                break
              default:
                body[fieldName] = { ilike: `%${data[fieldName]}%` }
                break
            }
          }
          break
        case 'text':
          if (data[fieldName].length > 0) body[fieldName] = { ilike: `%${data[fieldName]}%` }
          break
        case 'number':
        case 'date':
          if (data[fieldName].from && data[fieldName].to) {
            body[fieldName] = { between: [data[fieldName].from, data[fieldName].to] }
          } else if (data[fieldName].from && !data[fieldName].to) {
            body[fieldName] = { gte: data[fieldName].from }
          } else if (!data[fieldName].from && data[fieldName].to) {
            body[fieldName] = { lte: data[fieldName].to }
          }
          break
        case 'month': {
          const date = new Date(data[fieldName].to)
          date.setMonth(date.getMonth() + 1, 0)
          if (data[fieldName].from && data[fieldName].to) {
            body[fieldName] = { between: [data[fieldName].from + '-01', data[fieldName].to + `-${date.getDate()}`] }
          } else if (data[fieldName].from && !data[fieldName].to) {
            body[fieldName] = { gte: data[fieldName].from + '-01' }
          } else if (!data[fieldName].from && data[fieldName].to) {
            body[fieldName] = { lte: data[fieldName].to + `-${date.getDate()}` }
          }
          break
        }
        case 'dropdown':
          if (data[fieldName] !== 'All') body[fieldName] = data[fieldName]
          break
      }
    }

    return body
  }

  const addZeroBeforeDot = (item) => {
    if (getValues(item).length === 1) {
      getValues(item).charAt(0) === '.' && setValue(item, `0${getValues(item)}0`)
    } else {
      getValues(item).charAt(0) === '.' && setValue(item, `0${getValues(item)}`)
    }
  }

  const onNumberBlur = field => () => {
    getValues(`${field}.from`) && addZeroBeforeDot(`${field}.from`)
    getValues(`${field}.to`) && addZeroBeforeDot(`${field}.to`)
    clearErrors([`${field}.from`, `${field}.to`])
    const index = errorFields.indexOf(`${field}.from`)
    if (index > -1) {
      errorFields.splice(index, 1)
    }
    const from = getValues(`${field}.from`)
    const to = getValues(`${field}.to`)
    if (parseFloat(from) > parseFloat(to) && !Number.isNaN(parseFloat(from)) && !Number.isNaN(parseFloat(to))) {
      setError(`${field}.from`, {
        type: 'manual',
        message: i18n.t('VEHICLE_VEHICLE-LIST_POPUP-WARNING2_LABEL')
      })
      setErrorFields(prevState => [...prevState, `${field}.from`])
    }
    if ((from && !/^[0-9.]*$/.test(from)) || (to && !/^[0-9.]*$/.test(to))) {
      setError(`${field}.from`, {
        type: 'manual',
        message: i18n.t('VEHICLE_VEHICLE-LIST_WARNING-NUMERIC_LABEL')
      })
      setErrorFields(prevState => [...prevState, `${field}.from`])
    }
  }

  const onDateBlur = field => () => {
    clearErrors([`${field}.from`, `${field}.to`])
    const from = Date.parse(getValues(`${field}.from`))
    const to = Date.parse(getValues(`${field}.to`))
    if (from > to && !Number.isNaN(from) && !Number.isNaN(to)) {
      setError(`${field}.from`, {
        type: 'manual',
        message: i18n.t('VEHICLE_VEHICLE-LIST_POPUP-WARNING2_LABEL')
      })
      setErrorFields(prevState => [...prevState, `${field}.from`])
    } else {
      const index = errorFields.indexOf(`${field}.from`)
      if (index > -1) {
        errorFields.splice(index, 1)
      }
    }
  }

  const renderErrorText = field => {
    let errorMessage = ''
    if (errors[field]) {
      errorMessage = errors[field].message || errors[field].from.message
    }
    return errorMessage ? <p data-testid={`${field}-error-text`} className="error-text">{`${errorMessage}`}</p> : ''
  }

  const getDefaultValue = (key, fromTo) => {
    let value = ''
    if (defaultValue[key]) {
      switch (fromTo) {
        case 'from':
          if (key === 'nextRegularMaintenanceMonth') {
            value = dayjs(defaultValue[key].between[0], 'YYYY-MM-DD').format('YYYY-MM')
          } else value = defaultValue[key].between[0]
          break
        case 'to':
          if (key === 'nextRegularMaintenanceMonth') {
            value = dayjs(defaultValue[key].between[1], 'YYYY-MM-DD').format('YYYY-MM')
          } else value = defaultValue[key].between[1]
          break
      }
    }
    return value
  }

  const contains = (stringValue, charValue) => {
    return stringValue.indexOf(charValue) > -1
  }
  const allowTypeNumericOnly = (e) => {
    const invalidKey = (e.key.length === 1 && !contains(allowedChars, e.key)) || (e.key === '.' && contains(e.target.value, '.'))
    return invalidKey && e.preventDefault()
  }

  const renderInput = options => {
    let component = ''
    switch (options.type) {
      case 'dropdown':
        component =
            <RegisterMultiSelect
              className="input-item"
              marginLeft={'10px'}
              // testId={element.value}
              // defaultValue={vehicleValue[element.value]}
              placeholder={i18n.t('COMMON_COMMON_DROPDOWN-OPTION-ALL_LABEL')}
              options={getDropDownOptions(options.selectOptions, DropDownList)}
              valueField="key"
              labelField="value"
              element={{ value: options.name }}
            // onChange={registerDropDownValue}
            />
        break
      case 'text':
        component = (
          <input
            className="input-item"
            type={options.type}
            tabIndex={options.tabIndexes[0]}
            maxLength={options.maxLength}
            data-testid={options.name}
            {...register(options.name)} />
        )
        break
      case 'date':
      case 'month':
      case 'number':
        component = (
          <>
            <input
              {...!integerNumberOnly.includes(options.name) && { step: 'any' }}
              onKeyDown={(e) => allowTypeNumericOnly(e)}
              className="input-item"
              type={options.type === 'number' ? 'text' : options.type}
              tabIndex={options.tabIndexes[0]}
              placeholder="From"
              {...(options.type === 'date' && { min: '1890-01-01' })}
              {...(options.type !== 'month' && { max: watch(`${options.name}.to`) || '9999-12-31' })}
              {...(options.type === 'month' && { max: watch(`${options.name}.to`) || '9999-12' })}
              // max={getValues(`${options.name}.to`) || '9999-12-31'}
              defaultValue={options.type !== 'number' ? getDefaultValue(options.name, 'from') : ''}
              data-testid={`${options.name}.from`}
              {...register(`${options.name}.from`)} />
            <input
              {...!integerNumberOnly.includes(options.name) && { step: 'any' }}
              onKeyDown={(e) => allowTypeNumericOnly(e)}
              className="input-item"
              type={options.type === 'number' ? 'text' : options.type}
              tabIndex={options.tabIndexes[1]}
              placeholder="To"
              min={getValues(`${options.name}.from`) || '1890-01-01'}
              {...(options.type === 'date' && { max: '9999-12-31' })}
              {...(options.type === 'month' && { max: '9999-12' })}
              defaultValue={options.type !== 'number' ? getDefaultValue(options.name, 'to') : ''}
              data-testid={`${options.name}.to`}
              {...register(`${options.name}.to`)} />
          </>
        )
        break
    }
    return component
  }

  const renderInputGroup = options => {
    let onBlur = null
    if (options.type === 'date' || options.type === 'month') onBlur = onDateBlur(options.name)
    if (options.type === 'number') onBlur = onNumberBlur(options.name)

    return (
      <>
        <div className="label-text"><label>{i18n.t(options.label)}</label></div>
        <div>
          <div data-testid={`${options.name}-input-group`} className="input-group" onBlur={onBlur}>{renderInput(options)}</div>
          {renderErrorText(options.name)}
        </div>
      </>
    )
  }

  return (
    <FilterTabWrapper>
      <FormProvider {...useFormMethods}>
      <form className="form-container" onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="filter-container">
          <div className="input-container">
            {renderInputGroup(fieldNames.code)}
            {renderInputGroup(fieldNames.owner)}
            {renderInputGroup(fieldNames.annualInspectionDate)}
            {renderInputGroup(fieldNames.leaseFee)}
            {renderInputGroup(fieldNames.inspectionExpireDate)}
            {renderInputGroup(fieldNames.mandatoryInsuranceEndDate)}
          </div>
          <div className="input-container">
            {renderInputGroup(fieldNames.purchaseDate)}
            {renderInputGroup(fieldNames.user)}
            {renderInputGroup(fieldNames.expensesIncludingLease)}
            {renderInputGroup(fieldNames.rentalEndDate)}
            {renderInputGroup(fieldNames.automobileTax)}
            {renderInputGroup(fieldNames.voluntaryInsuranceType)}
          </div>
          <div className="input-container">
            {renderInputGroup(fieldNames.holdingFormat)}
            {renderInputGroup(fieldNames.userAddress)}
            {renderInputGroup(fieldNames.leaseEndDate)}
            {renderInputGroup(fieldNames.rentalFee)}
            {renderInputGroup(fieldNames.weightTax)}
            {renderInputGroup(fieldNames.voluntaryInsuranceEndDate)}
          </div>
        </div>
        <div className="submit-container">
          <SearchButton data-testid="submit-button" tabIndex={34} type="submit" {...errorFields.length > 0 && { disabled: true }}/>
        </div>
      </form>
      </FormProvider>
    </FilterTabWrapper>
  )
}

export default RegistrationTab

RegistrationTab.propTypes = {
  onSearchSubmit: PropTypes.func,
  defaultValue: PropTypes.object
}
