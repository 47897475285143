import React from 'react'
import PropTypes from 'prop-types'
import { FilterTabWrapper } from './styles'
import { useForm, FormProvider } from 'react-hook-form'
import { SearchButton } from '../Button'
import i18n from 'i18next'
import RegisterMultiSelect from '../RegisterMultiSelect'
import { minimumInputNumber, maximumInputNumber } from '../../tools/constants'
import { advancedSearchOptions } from './Defines/weightSizeTabDefine'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import { dropDownContentListApi } from '../../features/dropdown/dropdownAPI'
import { dropdownCategory, getDropDownOptions } from '../../common/const'

const fieldNames = {
  licenseAddress: { name: 'licensePlateAddress', type: 'license' },
  licenseClassNo: { name: 'licensePlateClass', type: 'license' },
  licenseNumber: { name: 'licensePlateNumber', type: 'license' },
  maxLoadCap: {
    label: 'VEHICLE_COMMON_MAXIMUM-LOAD-CAPACITY_LABEL',
    name: 'maxLoadCapacity',
    type: 'number',
    tabIndexes: [6, 7]
  },
  length: {
    label: 'VEHICLE_COMMON_LENGTH_LABEL',
    name: 'length',
    type: 'number',
    tabIndexes: [12, 13]
  },
  frontFrontAxleLoad: {
    label: 'VEHICLE_COMMON_FF-AXLE-LOAD_LABEL',
    name: 'frontFrontAxleLoad',
    type: 'number',
    tabIndexes: [18, 19]
  },
  rearRearAxleLoad: {
    label: 'VEHICLE_COMMON_RR-AXLE-LOAD_LABEL',
    name: 'rearRearAxleLoad',
    type: 'number',
    tabIndexes: [24, 25]
  },
  frontRearAxleLoad: {
    label: 'VEHICLE_COMMON_FR-AXLE-LOAD_LABEL',
    name: 'frontRearAxleLoad',
    type: 'number',
    tabIndexes: [20, 21]
  },
  rearFrontAxleLoad: {
    label: 'VEHICLE_COMMON_RF-AXLE-LOAD_LABEL',
    name: 'rearFrontAxleLoad',
    type: 'number',
    tabIndexes: [22, 23]
  },
  seatingCap: {
    label: 'VEHICLE_COMMON_SEATING-CAPACITY_LABEL',
    name: 'seatingCapacity',
    type: 'number',
    tabIndexes: [4, 5]
  },
  vehicleWeight: {
    label: 'VEHICLE_COMMON_VEHICLE-WEIGHT_LABEL',
    name: 'vehicleWeight',
    type: 'number',
    tabIndexes: [8, 9]
  },
  grossVehicleWeight: {
    label: 'VEHICLE_COMMON_GROSS-VEHICLE-WEIGHT_LABEL',
    name: 'grossVehicleWeight',
    type: 'number',
    tabIndexes: [10, 11]
  },
  width: {
    label: 'VEHICLE_COMMON_WIDTH_LABEL',
    name: 'width',
    type: 'number',
    tabIndexes: [14, 15]
  },
  height: {
    label: 'VEHICLE_COMMON_HEIGHT_LABEL',
    name: 'height',
    type: 'number',
    tabIndexes: [16, 17]
  }
}

function WeightSizeTab (props) {
  const { onSearchSubmit } = props
  const useFormMethods = useForm()
  const { register, unregister, handleSubmit, watch, getValues, setValue, formState: { errors }, setError, clearErrors } = useFormMethods
  const [errorFields, setErrorFields] = React.useState([])
  const [DropDownList, setDropDownList] = React.useState({})
  const [prevAdvancedSearchVal, setPrevAdvancedSearchVal] = React.useState('')
  const allowedChars = '0123456789.'

  const watchAdvancedSelected = watch([
    'advanceSearchLabel_25',
    'advanceSearchLabel_26',
    'advanceSearchLabel_27'
  ])

  React.useEffect(() => {
    setDropDownList({})
    // Get DropDown data
    const dropdownKey = {
      0: dropdownCategory.transmission,
      1: dropdownCategory.cabinColor,
      // 2: dropdownCategory.bodyColor,
      3: dropdownCategory.sbsMarking,
      4: dropdownCategory.tireManufacturer,
      5: dropdownCategory.snowTire,
      6: dropdownCategory.tireChain,
      7: dropdownCategory.spareTire
    }
    Object.entries(dropdownKey).map(async (value) => {
      const response = await dropDownContentListApi({ categoryId: value[1] })
      // setDropDownListを更新
      setDropDownList(prevState => ({ ...prevState, [value[1]]: response.data.rows }))
    })
  }, [])

  const getSelectedStatus = (formKey, value) => {
    const curSelected = getValues(formKey)
    const selectedItems = [
      getValues('advanceSearchLabel_25'),
      getValues('advanceSearchLabel_26'),
      getValues('advanceSearchLabel_27')
    ]

    if (value !== curSelected && selectedItems.includes(value)) {
      return true
    }
    return false
  }

  const onSubmit = data => {
    const body = prepareSearchBody(data)
    onSearchSubmit(body)
  }

  const prepareSearchBody = data => {
    const body = {}
    body.onSubmit = 'onSubmit'
    for (const key of Object.keys(fieldNames)) {
      const fieldName = fieldNames[key].name
      const type = fieldNames[key].type

      switch (type) {
        case 'license':
          if (data[fieldName].length > 0) {
            switch (fieldName) {
              case 'licensePlateNumber':
                body[fieldName] = data[fieldName]
                break
              default:
                body[fieldName] = { ilike: `%${data[fieldName]}%` }
                break
            }
          }
          break
        case 'number':
        case 'month':
        case 'date':
          if (data[fieldName] && data[fieldName].from && data[fieldName].to) {
            body[fieldName] = { between: [data[fieldName].from, data[fieldName].to] }
          } else if (data[fieldName] && data[fieldName].from && !data[fieldName].to) {
            body[fieldName] = { gte: data[fieldName].from }
          } else if (data[fieldName] && !data[fieldName].from && data[fieldName].to) {
            body[fieldName] = { lte: data[fieldName].to }
          }
          break
      }
    }

    for (const key of Object.keys(data)) {
      const [label, searchNumber, optionName] = key.split('_')
      if (label !== 'advanceSearchInput') continue
      if (searchNumber && optionName && data[`advanceSearchLabel_${searchNumber}`] !== optionName) {
        unregister(key)
        continue
      }

      const index = advancedSearchOptions.map((e) => e.value).indexOf(optionName)
      const fieldName = advancedSearchOptions[index].value
      const type = advancedSearchOptions[index].type.split(' - ')[0]
      switch (type) {
        case 'text':
          if (data[key].length > 0) {
            body[fieldName] = {
              ilike: `%${data[key]}%`
            }
          }
          break
        case 'number':
          if (data[key] && data[key].from && data[key].to) {
            body[fieldName] = { between: [data[key].from, data[key].to] }
          } else if (data[key] && data[key].from && !data[key].to) {
            body[fieldName] = { gte: data[key].from }
          } else if (data[key] && !data[key].from && data[key].to) {
            body[fieldName] = { lte: data[key].to }
          }
          break
        case 'date':
          if (data[key] && data[key].from && data[key].to) {
            body[fieldName] = { between: [data[key].from, data[key].to] }
          } else if (data[key] && data[key].from && !data[key].to) {
            body[fieldName] = { gte: data[key].from }
          } else if (data[key] && !data[key].from && data[key].to) {
            body[fieldName] = { lte: data[key].to }
          }
          break
        case 'dropdown':
          // if (data[key] && data[key] === 'All') body[fieldName] = { in: advanceSearchOptions[index].options }
          // else body[fieldName] = data[key]
          if (data[key] && data[key] !== 'All') body[fieldName] = data[key]
          break
      }
    }
    return body
  }

  const errorMessage = `${i18n.t('VEHICLE_VEHICLE-LIST_POPUP-WARNING2_LABEL')}`
  const errorMessage2 = `${i18n.t('VEHICLE_VEHICLE-LIST_WARNING-NUMERIC_LABEL')}`

  const addZeroBeforeDot = (item) => {
    if (getValues(item).length === 1) {
      getValues(item).charAt(0) === '.' && setValue(item, `0${getValues(item)}0`)
    } else {
      getValues(item).charAt(0) === '.' && setValue(item, `0${getValues(item)}`)
    }
  }

  const onBlur = field => () => {
    getValues(`${field}.from`) && addZeroBeforeDot(`${field}.from`)
    getValues(`${field}.to`) && addZeroBeforeDot(`${field}.to`)
    clearErrors([`${field}.from`, `${field}.to`])
    const index = errorFields.indexOf(`${field}.from`)
    index > -1 && errorFields.splice(index, 1)
    const from = getValues(`${field}.from`)
    const to = getValues(`${field}.to`)
    if (parseFloat(from) > parseFloat(to) && !Number.isNaN(parseFloat(from)) && !Number.isNaN(parseFloat(to))) {
      setError(`${field}.from`, {
        type: 'manual',
        message: i18n.t('VEHICLE_VEHICLE-LIST_POPUP-WARNING2_LABEL')
      })
      setErrorFields(prevState => [...prevState, `${field}.from`])
    }
    if ((from && !/^[0-9.]*$/.test(from)) || (to && !/^[0-9.]*$/.test(to))) {
      setError(`${field}.from`, {
        type: 'manual',
        message: i18n.t('VEHICLE_VEHICLE-LIST_WARNING-NUMERIC_LABEL')
      })
      setErrorFields(prevState => [...prevState, `${field}.from`])
    }
  }

  const onLicenseBlur = (item) => {
    const index = errorFields.indexOf(item)
    if (index > -1) {
      errorFields.splice(index, 1)
    }
    clearErrors(item)
    const licenseNum = getValues(item)
    if (licenseNum && !/^[0-9.]*$/.test(licenseNum)) {
      setErrorFields(prevState => [...prevState, item])
      setError(item, { type: 'manual', message: i18n.t('VEHICLE_VEHICLE-LIST_WARNING-NUMERIC_LABEL') })
    }
  }

  const renderErrorText = field => {
    let errorMessage = ''
    if (errors[field]) {
      errorMessage = errors[field].from || errors[field]
    }
    return errorMessage ? <p className="error-text" data-testid="error-text">{`${errorMessage.message}`}</p> : ''
  }

  const contains = (stringValue, charValue) => {
    return stringValue.indexOf(charValue) > -1
  }
  const integerNumberOnly = ['seatingCapacity', 'licensePlateNumber']
  const allowTypeNumericOnly = (item, e) => {
    // const invalidKey = (e.key.length === 1 && !contains(allowedChars, e.key)) || (e.key === '.' && contains(e.target.value, '.'))
    let invalidKey = null
    if (!integerNumberOnly.includes(item)) {
      invalidKey = (e.key.length === 1 && !contains(allowedChars, e.key)) || (e.key === '.' && contains(e.target.value, '.'))
    } else {
      invalidKey = (e.key.length === 1 && !contains('0123456789', e.key))
    }
    return invalidKey && e.preventDefault()
  }

  const renderInput = options => {
    let component = ''
    switch (options.type) {
      case 'number':
        component = (
          <>
            <input
              {...!integerNumberOnly.includes(options.name) && { step: 'any' }}
              onKeyDown={(e) => allowTypeNumericOnly(options.name, e)}
              className="input-item"
              data-testid={`${options.name}-from`}
              type="text" // {inputItem.type}
              tabIndex={options.tabIndexes[0]}
              placeholder="From"
              // min={minimumInputNumber}
              // max={maximumInputNumber}
              {...register(`${options.name}.from`)} />
            <input
              {...!integerNumberOnly.includes(options.name) && { step: 'any' }}
              onKeyDown={(e) => allowTypeNumericOnly(options.name, e)}
              className="input-item"
              data-testid={`${options.name}-to`}
              type="text" // {inputItem.type}
              tabIndex={options.tabIndexes[1]}
              placeholder="To"
              // min={minimumInputNumber}
              // max={maximumInputNumber}
              {...register(`${options.name}.to`)} />
          </>
        )
        break
    }
    return component
  }

  const showError = (num) => {
    const advanceSearchValue = getValues(`advanceSearchLabel_${num}`)
    // START: Find datatype
    const idx = advancedSearchOptions.map((e) => e.value).indexOf(advanceSearchValue)
    if (!advanceSearchValue || advanceSearchValue === '1') return
    const advanceSearchItem = advancedSearchOptions[idx]
    const dataType = advanceSearchItem.type && advanceSearchItem.type.split(' - ')
    // END: Find datatype
    const from = getValues(`advanceSearchInput_${num}_${advanceSearchValue}.from`)
    const to = getValues(`advanceSearchInput_${num}_${advanceSearchValue}.to`)
    // START: Clear Error
    const indexErr = errorFields.indexOf(`advanceSearchInput_${num}_${advanceSearchValue}.from`)
    if (indexErr > -1) {
      errorFields.splice(indexErr, 1)
    }
    const indexPrevErr = errorFields.indexOf(`advanceSearchInput_${num}_${prevAdvancedSearchVal}.from`)
    if (indexPrevErr > -1) {
      errorFields.splice(indexPrevErr, 1)
    }
    clearErrors(`advanceSearchInput_${num}_${advanceSearchValue}.from`)
    // END: Clear Error
    if (dataType[0] === 'number') { // Handling error if textbox is numeric
      from && addZeroBeforeDot(`advanceSearchInput_${num}_${advanceSearchValue}.from`)
      to && addZeroBeforeDot(`advanceSearchInput_${num}_${advanceSearchValue}.to`)
      if (parseFloat(from) > parseFloat(to) && !Number.isNaN(from) && !Number.isNaN(to)) {
        setErrorFields(prevState => [...prevState, `advanceSearchInput_${num}_${advanceSearchValue}.from`])
        setError(`advanceSearchInput_${num}_${advanceSearchValue}.from`, { type: 'manual', message: errorMessage })
      }
      if ((from && !/^[0-9.]*$/.test(from)) || (to && !/^[0-9.]*$/.test(to))) {
        setErrorFields(prevState => [...prevState, `advanceSearchInput_${num}_${advanceSearchValue}.from`])
        setError(`advanceSearchInput_${num}_${advanceSearchValue}.from`, { type: 'manual', message: errorMessage2 })
      }
    }
    if (dataType[0] === 'date') { // Handling error if textbox is date
      if (Date.parse(from) > Date.parse(to) && !Number.isNaN(Date.parse(from)) && !Number.isNaN(Date.parse(to))) {
        setErrorFields(prevState => [...prevState, `advanceSearchInput_${num}_${advanceSearchValue}.from`])
        setError(`advanceSearchInput_${num}_${advanceSearchValue}.from`, { type: 'manual', message: errorMessage })
      }
    }
    setPrevAdvancedSearchVal(getValues(`advanceSearchLabel_${num}`))
  }

  const renderAdvancedSearchInput = (num) => {
    const advanceSearchValue = getValues(`advanceSearchLabel_${num}`)
    if (!advanceSearchValue || advanceSearchValue === '1') return
    const index = advancedSearchOptions.map((e) => e.value).indexOf(advanceSearchValue)
    const advanceSearchItem = advancedSearchOptions[index]
    advanceSearchItem.selected = true
    const dataType = advanceSearchItem.type && advanceSearchItem.type.split(' - ')
    switch (dataType[0]) {
      case 'dropdown':
        return (
          <div className="input-group input-flex">
            <RegisterMultiSelect
              className="input-item"
              marginLeft={'10px'}
              placeholder={i18n.t('COMMON_COMMON_DROPDOWN-OPTION-ALL_LABEL')}
              options={getDropDownOptions(advanceSearchItem.options, DropDownList)}
              valueField="key"
              labelField="value"
              element={{ value: `advanceSearchInput_${num}_${advanceSearchValue}` }}
            />
          </div>
        )
      case 'number':
        // Number.isNaN(parseFloat(getValues(`advanceSearchInput_${num}_${advanceSearchValue}.from`))) && setValue(`advanceSearchInput_${num}_${advanceSearchValue}.from`, '')
        // Number.isNaN(parseFloat(getValues(`advanceSearchInput_${num}_${advanceSearchValue}.to`))) && setValue(`advanceSearchInput_${num}_${advanceSearchValue}.to`, '')
        return (
          <div className="input-group input-flex">
            <input tabIndex={parseInt(num) + 3}
              {...!integerNumberOnly.includes(advanceSearchValue)}
              onKeyDown={(e) => allowTypeNumericOnly(advanceSearchValue, e)}
              placeholder='From'
              type='text'
              min={minimumInputNumber}
              max={maximumInputNumber}
              {...register(`advanceSearchInput_${num}_${advanceSearchValue}.from`)}
              data-testid={`advanceSearchInput_${num}_${advanceSearchValue}-from`}
              className="input-item" />
            <input tabIndex={parseInt(num) + 3}
              {...!integerNumberOnly.includes(advanceSearchValue)}
              onKeyDown={(e) => allowTypeNumericOnly(advanceSearchValue, e)}
              placeholder='To'
              type='text'
              min={minimumInputNumber}
              max={maximumInputNumber}
              {...register(`advanceSearchInput_${num}_${advanceSearchValue}.to`)}
              data-testid={`advanceSearchInput_${num}_${advanceSearchValue}-to`}
              className="input-item" />
          </div>)
      case 'date':
        return (
          <div className="input-group input-flex">
            <input tabIndex={parseInt(num) + 3}
              placeholder='From'
              type={dataType[0]}
              data-testid={`advanceSearchInput_${num}_${advanceSearchValue}-from`}
              {...register(`advanceSearchInput_${num}_${advanceSearchValue}.from`)}
              min="1890-01-01"
              max={watch(`advanceSearchInput_${num}_${advanceSearchValue}.to`) || '9999-12-31'}
              className="input-item" />
            <input tabIndex={parseInt(num) + 3}
              placeholder='To'
              type={dataType[0]}
              data-testid={`advanceSearchInput_${num}_${advanceSearchValue}-to`}
              {...register(`advanceSearchInput_${num}_${advanceSearchValue}.to`)}
              min={watch(`advanceSearchInput_${num}_${advanceSearchValue}.from`) || '1890-01-01'}
              max="9999-12-31"
              className="input-item" />
          </div>)
      default:
        return (
          <div className="input-group input-flex">
            <input tabIndex={parseInt(num) + 3}
              placeholder=''
              type={dataType[0]}
              maxLength={advanceSearchItem.maxLength}
              {...register(`advanceSearchInput_${num}_${advanceSearchValue}`)}
              data-testid={`advanceSearchInput_${num}_${advanceSearchValue}`}
              className="input-item" />
          </div>)
    }
  }

  const renderAdvancedSearchOptions = (num) => {
    const newOptions = [...advancedSearchOptions]
    return <div className="input-group input-flex">
      <select className="input-item" tabIndex={parseInt(num) + 3}
        style={{ marginLeft: '0' }}
        {...register(`advanceSearchLabel_${num}`)}
        data-testid={`advanceSearchLabel_${num}`}
        defaultValue={'1'}>
        <option value="1">{i18n.t('COMMON_COMMON_DROPDOWN-PLEASE-SELECT_LABEL')}</option>
        {newOptions.map(({ value, label }, index) => {
          return <option value={value} key={index} disabled={getSelectedStatus(`advanceSearchLabel_${num}`, value)}>{i18n.t(label)}</option>
        })}
      </select>
    </div>
  }

  const renderAdvancedSearchSelect = (e, i) => {
    return <ul key={i}><li>
      <div className="item-container"
        onBlur={() => { showError(e) }}>
        {!watch(`advanceSearch_${e}`) && e !== 25 &&
          <AddCircleOutlineIcon alt="addSearch" className="btn-add"
            style={{ alignSelf: 'center' }}
            {...register(`advanceSearch_${e}`)}
            {...watch(`advanceSearch_${e}`, false)}
            data-testid={`plus-icon-${e}`}
            onClick={() => setValue(`advanceSearch_${e}`, true)} />
        }
        {((watch(`advanceSearch_${e}`) && watchAdvancedSelected) || e === 25) && renderAdvancedSearchOptions(e)}
        {watch(`advanceSearchLabel_${e}`) && renderAdvancedSearchInput(e)}
      </div>
      {errors[`advanceSearchInput_${e}_${getValues(`advanceSearchLabel_${e}`)}`] && (
        <div className="error" data-testid={`error-text-${e}`}>{errors[`advanceSearchInput_${e}_${getValues(`advanceSearchLabel_${e}`)}`].from.message}</div>
      )}
    </li></ul>
  }

  const renderInputGroup = options => {
    return (
      <>
        <div className="label-text"><label>{i18n.t(options.label)}</label></div>
        <div>
          <div className="input-group" {...(options.type === 'number' && { onBlur: onBlur(options.name) })}>
            {renderInput(options)}
          </div>
          {renderErrorText(options.name)}
        </div>
      </>
    )
  }

  return (
    <FilterTabWrapper>
      <FormProvider {...useFormMethods}>
        <form className="form-container" onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="filter-container">
            <div className="input-container">
              <div className="label-text">
                <label>{i18n.t('VEHICLE_COMMON_LICENSE-PLATE_LABEL')}</label>
              </div>
              <div>
                <div className="input-group" onBlur={(e) => { onLicenseBlur(fieldNames.licenseNumber.name) }}>
                  <input
                    tabIndex={1}
                    data-testid={fieldNames.licenseAddress.name}
                    className="input-item"
                    placeholder={i18n.t('VEHICLE_COMMON_LICENSE-PLATE-ADDRESS_LABEL')}
                    type="text"
                    maxLength={255}
                    {...register(fieldNames.licenseAddress.name)} />
                  <input
                    tabIndex={2}
                    data-testid={fieldNames.licenseClassNo.name}
                    className="input-item"
                    placeholder={i18n.t('VEHICLE_COMMON_LICENSE-PLATE-CLASS-NO_LABEL')}
                    type="text"
                    maxLength={255}
                    {...register(fieldNames.licenseClassNo.name)} />
                  <input
                    onKeyDown={(e) => { (e.key.length === 1 && !contains('0123456789', e.key)) && e.preventDefault() }}
                    tabIndex={3}
                    data-testid={fieldNames.licenseNumber.name}
                    className="input-item"
                    placeholder={i18n.t('VEHICLE_COMMON_LICENSE-PLATE-NUMBER_LABEL')}
                    type="text"
                    maxLength={4}
                    {...register(fieldNames.licenseNumber.name)} />
                </div>
                {renderErrorText(fieldNames.licenseNumber.name)}
              </div>
              {renderInputGroup(fieldNames.maxLoadCap)}
              {renderInputGroup(fieldNames.length)}
              {renderInputGroup(fieldNames.frontFrontAxleLoad)}
              {renderInputGroup(fieldNames.rearRearAxleLoad)}
            </div>
            <div className="input-container">
              {renderInputGroup(fieldNames.seatingCap)}
              {renderInputGroup(fieldNames.vehicleWeight)}
              {renderInputGroup(fieldNames.width)}
              {renderInputGroup(fieldNames.frontRearAxleLoad)}
            </div>
            <div className="input-container">
              <div className="label-text"></div>
              <div><div className="input-group"></div></div>
              {renderInputGroup(fieldNames.grossVehicleWeight)}
              {renderInputGroup(fieldNames.height)}
              {renderInputGroup(fieldNames.rearFrontAxleLoad)}
            </div>
          </div>
          <div className="submit-container" style={{ alignItems: 'center' }}>
            <div className="general-filter-group" style={{ marginRight: '10px' }}>
              <div className="filter-container" style={{ marginBottom: '8px' }}>
                  <div className="label-text">
                    <label>{i18n.t('VEHICLE_VEHICLE-LIST_ADVANCE-SEARCH_LABEL')}</label>
                  </div>
              </div>
              <div className="filter-container">
                {[25, 26, 27].map((e, i) => renderAdvancedSearchSelect(e, i))}
              </div>
            </div>
            <SearchButton tabIndex={31} type="submit" data-testid="search-submit" {...errorFields.length > 0 && { disabled: true }}/>
          </div>
        </form>
      </FormProvider>
    </FilterTabWrapper>
  )
}

export default WeightSizeTab

WeightSizeTab.propTypes = {
  onSearchSubmit: PropTypes.func
}
