import styled from 'styled-components'
export const TabWrap = styled.div`
  display: ${props => props.hidden ? 'none' : ''};
  .tab-row {
    display: flex;
  }
`
export const TabItemWrap = styled.div`
  border: 1px solid #ccc;
  border-radius: 0px 5px 5px 5px;
  padding: 12px;
`

export const TabButtonWrap = styled.div`
  padding: 6px 24px;
  border-radius: 5px 10px 0px 0px;
  border: 1px solid #ccc;
  border-bottom: none;
  cursor: pointer;
  position: relative;
  width: 150px;
  text-align: center;
  span {
    color: ${props => props.focus ? 'black' : 'gray'};
    ${props => props.focus ? `border-bottom: 3px solid ${props.theme.primeColor};` : ''}
    pointer-events: none;
  }
  ${props => props.focus
? `
    ::after {
      content: '';
      height: 2px;
      width: 100%;
      background: white;
      position: absolute;
      left: 0;
      bottom: -1px;
    }`
: ''
  }
`
