import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'
import { importForkliftFinanceApi } from './importForkliftFinanceAPI'
const initialState = {
  check: {
    load: false,
    error: {},
    success: {}
  },
  import: {
    load: false,
    data: {}
  }
}

export const importForkliftFinance = createAsyncThunk(
  'forklift/import-finance',
  async (props, { rejectWithValue }) => {
    try {
      const response = await importForkliftFinanceApi({ ...props, exec: true })
      return response.data
    } catch (err) {
      if (err.response.status >= 500) {
        return rejectWithValue({ error: { unknown: true } })
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const checkFinance = createAsyncThunk(
  'forklift/import-check-finance',
  async (props, { rejectWithValue }) => {
    try {
      const response = await importForkliftFinanceApi({ ...props, exec: false })
      // console.log('RES', response);
      return response.data
    } catch (err) {
      if (err.response.status >= 500) {
        return rejectWithValue({ error: { unknown: true } })
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const importForkliftFinanceSlice = createSlice({
  name: 'importForkliftFinance',
  initialState,
  reducers: {
    clearState: (state) => {
      state.check.load = false
      state.check.error = {}
      state.check.success = {}
      state.import.data = {}
      return state
    }
  },
  extraReducers: {
    [checkFinance.fulfilled]: (state, { payload }) => {
      state.check.load = false
      state.check.success = payload
      return state
    },
    [checkFinance.rejected]: (state, { payload }) => {
      state.check.load = false
      state.check.error = payload.error
    },
    [checkFinance.pending]: (state) => {
      state.check.load = true
      state.check.success = {}
      state.check.error = {}
      state.import.data = {}
    },
    [importForkliftFinance.fulfilled]: (state, { payload }) => {
      state.import.load = false
      state.import.data = payload
      return state
    },
    [importForkliftFinance.rejected]: (state, { payload }) => {
      state.import.load = false
      state.check.error = payload.error
    },
    [importForkliftFinance.pending]: (state) => {
      state.import.load = true
      state.check.success = {}
      state.import.data = {}
    }
  }
})

export const { clearState } = importForkliftFinanceSlice.actions
export default importForkliftFinanceSlice.reducer
