import { configureStore } from '@reduxjs/toolkit'
import userReducer from '../features/users/userSlice'
import userInfoReducer from '../features/users/userInfoSlice'
import vehicleReducer from '../features/vehicles/vehicleSlice'
import importVehicleReducer from '../features/vehicles/importVehicleSlice'
import importFinanceReducer from '../features/vehicles/importFinanceSlice'
import organizationReducer from '../features/organizations/organizationSlice'
import importOrganizationReducer from '../features/organizations/importOrganizationSlice'
import reportReducer from './../features/reports/reportSlice'
import contractReducer from './../features/contracts/contractSlice'
import consumptionReducer from '../features/consumptions/consumptionSlice'
import fileReader from '../features/file/fileSlice'
import dropDownReducer from '../features/dropdown/dropdownSlice'
import forkliftReducer from '../features/forklift/forkliftSlice'
import importForkliftReducer from '../features/forklift/importForkliftSlice'
import importForkliftFinanceReducer from '../features/forklift/importForkliftFinanceSlice'
import forkliftContractReducer from '../features/forkliftContracts/forkliftContractSlice'

export const store = configureStore({
  reducer: {
    user: userReducer,
    vehicle: vehicleReducer,
    organization: organizationReducer,
    importOrganization: importOrganizationReducer,
    importFinance: importFinanceReducer,
    importVehicle: importVehicleReducer,
    reports: reportReducer,
    consumption: consumptionReducer,
    contract: contractReducer,
    userInfo: userInfoReducer,
    file: fileReader,
    dropDown: dropDownReducer,
    forklift: forkliftReducer,
    importForkliftFinance: importForkliftFinanceReducer,
    importForklift: importForkliftReducer,
    forkliftContract: forkliftContractReducer
  }
})
