import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'
import { forkliftListApi, getForkliftApi, createForkliftApi, editForkliftApi, deleteForkliftApi } from './forkliftAPI'
const initialState = {
  list: null,
  itemCount: 0,
  selectedForklift: {},
  error: null,
  load: false,
  isLoading: false,
  isEditing: false
}

export const getForkliftList = createAsyncThunk(
  'forklift/list',
  async (props, { rejectWithValue }) => {
    try {
      const response = await forkliftListApi({ ...props })
      return response.data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const getSingleForklift = createAsyncThunk(
  'forklift/single',
  async (props, { rejectWithValue }) => {
    try {
      const response = await getForkliftApi({ ...props })
      return response.data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const createForklift = createAsyncThunk(
  'forklift/create',
  async (props, { rejectWithValue }) => {
    try {
      const response = await createForkliftApi({ ...props })
      return response.data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const editForklift = createAsyncThunk(
  'forklift/edit',
  async (props, { rejectWithValue, getState }) => {
    try {
      const response = await editForkliftApi({ ...props })
      return response.data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const deleteForklift = createAsyncThunk(
  'forklift/delete',
  async (props, { rejectWithValue }) => {
    try {
      const response = await deleteForkliftApi({ ...props })
      return response.data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const forkliftSlice = createSlice({
  name: 'forklift',
  initialState,
  reducers: {
    clearState: (state) => {
      state.list = null
      state.itemCount = 0
      state.selectedForklift = {}
      state.error = null
      return state
    },
    setEditing: (state, { payload }) => {
      state.isEditing = payload
      return state
    }
  },
  extraReducers: {
    [getForkliftList.fulfilled]: (state, { payload }) => {
      state.error = null
      state.list = payload.rows
      state.itemCount = payload.count
      state.load = false
      return state
    },
    [getForkliftList.rejected]: (state, { payload }) => {
      state.load = false
    },
    [getForkliftList.pending]: (state) => {
      state.list = null
      state.itemCount = 0
      state.load = true
    },
    [getSingleForklift.fulfilled]: (state, { payload }) => {
      state.error = null
      state.selectedForklift = payload
      state.isLoading = false
      return state
    },
    [getSingleForklift.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.selectedForklift = {}
    },
    [getSingleForklift.pending]: (state) => {
      state.isLoading = true
      state.selectedForklift = {}
    },
    [createForklift.fulfilled]: (state, { payload }) => {
      state.error = 'none'
      state.isLoading = false
      return state
    },
    [createForklift.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.error = payload && payload.errors
    },
    [createForklift.pending]: (state) => {
      state.isLoading = true
    },
    [editForklift.fulfilled]: (state, { payload }) => {
      state.isLoading = false
      state.error = 'none'
      return state
    },
    [editForklift.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.error = payload && payload.errors
    },
    [editForklift.pending]: (state) => {
      state.isLoading = true
    },
    [deleteForklift.fulfilled]: (state, { payload }) => {
      state.isLoading = false
      state.error = 'delete_none'
      return state
    },
    [deleteForklift.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.error = payload && payload.errors
    },
    [deleteForklift.pending]: (state) => {
      state.isLoading = true
    }
  }
})

export const { clearState, setEditing } = forkliftSlice.actions
export default forkliftSlice.reducer
