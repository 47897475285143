import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { FilterTabWrapper } from './styles'
import { useForm, FormProvider } from 'react-hook-form'
import { SearchButton, OkButton } from '../Button'
import MultiSelect from '../../components/MultiSelect'
import RegisterMultiSelect from '../RegisterMultiSelect'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import Modal from '../Modal'
import { fieldNames, advancedSearchOptions } from './Defines/generalTabDefine'
import i18n from 'i18next'
// import { minimumInputNumber, maximumInputNumber } from '../../tools/constants'
import { integerNumberOnly } from './advanceSearchOptions'
import { dropDownContentListApi } from '../../features/dropdown/dropdownAPI'
import { dropdownCategory, getDropDownOptions } from '../../common/const'

function GeneralTab (props) {
  const { onSearchSubmit, company, branchAll, setAdvancedOrganization } = props
  const [showMessage, setShowMessage] = React.useState(false)
  const useFormMethods = useForm()
  const { register, watch, handleSubmit, getValues, setValue, formState: { errors }, setError, clearErrors } = useFormMethods
  const organizationState = useSelector(state => state.organization)
  const [errorFields, setErrorFields] = React.useState([])
  const allowedChars = '0123456789.'
  const [DropDownList, setDropDownList] = React.useState({})

  const watchAdvancedSelected = watch([
    'advanceSearchLabel_1',
    'advanceSearchLabel_2',
    'advanceSearchLabel_3'
  ])

  React.useEffect(() => {
    setValue('advanceSearchInput_businessUnit', '')
    setValue('advanceSearchInput_branchOffice', '')
    // update parent values
    setAdvancedOrganization(prev => ({ ...prev, department: getValues('advanceSearchInput_department') }))
  }, [watch('advanceSearchInput_department')])

  React.useEffect(() => {
    setValue('advanceSearchInput_branchOffice', '')
    // update parent values
    setAdvancedOrganization(prev => ({ ...prev, businessUnit: getValues('advanceSearchInput_businessUnit') }))
  }, [watch('advanceSearchInput_businessUnit')])

  React.useEffect(() => {
    // update parent values
    setAdvancedOrganization(prev => ({ ...prev, branchOffice: getValues('advanceSearchInput_branchOffice') }))
  }, [watch('advanceSearchInput_branchOffice')])

  React.useEffect(() => {
    setValue('advanceSearchInput_department', '')
    setValue('advanceSearchInput_businessUnit', '')
    setValue('advanceSearchInput_branchOffice', '')
  }, [company])

  React.useEffect(() => {
    setDropDownList({})
    // Get DropDown data
    const dropdownKey = {
      0: dropdownCategory.type,
      1: dropdownCategory.brand,
      2: dropdownCategory.class,
      3: dropdownCategory.bodyShape,
      4: dropdownCategory.fuelType,
      5: dropdownCategory.status
    }
    let isMounted = true
    Object.entries(dropdownKey).map(async (value) => {
      const response = await dropDownContentListApi({ categoryId: value[1] })
      // setDropDownListを更新
      if (isMounted) {
        setDropDownList(prevState => ({ ...prevState, [value[1]]: response.data.rows }))
      }
    })
    return () => {
      isMounted = false
    }
  }, [])

  const onSubmit = data => {
    // Create post data
    const reqBody = buildItemRequest(data)
    if (onSearchSubmit) { onSearchSubmit(reqBody) }
  }

  const addZeroBeforeDot = (item) => {
    if (getValues(item).length === 1) {
      getValues(item).charAt(0) === '.' && setValue(item, `0${getValues(item)}0`)
    } else {
      getValues(item).charAt(0) === '.' && setValue(item, `0${getValues(item)}`)
    }
  }

  const onNumberBlur = field => () => {
    getValues(`${field}.from`) && addZeroBeforeDot(`${field}.from`)
    getValues(`${field}.to`) && addZeroBeforeDot(`${field}.to`)
    clearErrors([`${field}.from`, `${field}.to`])
    const index = errorFields.indexOf(`${field}.from`)
    if (index > -1) {
      errorFields.splice(index, 1)
    }
    const from = getValues(`${field}.from`)
    const to = getValues(`${field}.to`)
    if (parseFloat(from) > parseFloat(to) && !Number.isNaN(parseFloat(from)) && !Number.isNaN(parseFloat(to))) {
      setError(`${field}.from`, {
        type: 'manual',
        message: i18n.t('VEHICLE_VEHICLE-LIST_POPUP-WARNING2_LABEL')
      })
      setErrorFields(prevState => [...prevState, `${field}.from`])
    }
    if ((from && !/^[0-9.]*$/.test(from)) || (to && !/^[0-9.]*$/.test(to))) {
      setError(`${field}.from`, {
        type: 'manual',
        message: i18n.t('VEHICLE_VEHICLE-LIST_WARNING-NUMERIC_LABEL')
      })
      setErrorFields(prevState => [...prevState, `${field}.from`])
    }
  }

  const onDateBlur = field => () => {
    clearErrors([`${field}.from`, `${field}.to`])
    const index = errorFields.indexOf(`${field}.from`)
    if (index > -1) {
      errorFields.splice(index, 1)
    }
    const from = Date.parse(getValues(`${field}.from`))
    const to = Date.parse(getValues(`${field}.to`))
    if (from > to && !Number.isNaN(from) && !Number.isNaN(to)) {
      setError(`${field}.from`, {
        type: 'manual',
        message: i18n.t('VEHICLE_VEHICLE-LIST_POPUP-WARNING2_LABEL')
      })
      setErrorFields(prevState => [...prevState, `${field}.from`])
    }
  }

  const onLicenseBlur = (item, name) => {
    const index = errorFields.indexOf(name)
    if (index > -1) {
      errorFields.splice(index, 1)
    }
    clearErrors(name)
    const licenseNum = getValues(item)
    if (licenseNum && !/^[0-9.]*$/.test(licenseNum)) {
      setErrorFields(prevState => [...prevState, name])
      setError(name, {
        type: 'manual',
        message: i18n.t('VEHICLE_VEHICLE-LIST_WARNING-NUMERIC_LABEL')
      })
    }
  }

  const buildItemRequest = (data) => {
    const reqBody = {}
    reqBody.onSubmit = 'onSubmit'
    for (const [, value] of Object.entries(fieldNames)) {
      if (value.isPlate) {
        // License plate
        if (data[value.name].address) {
          reqBody.licensePlateAddress = {
            ilike: `%${data[value.name].address}%`
          }
        }
        if (data[value.name].classNo) {
          reqBody.licensePlateClass = {
            ilike: `%${data[value.name].classNo}%`
          }
        }
        if (data[value.name].number) {
          reqBody.licensePlateNumber = data[value.name].number
        }
      } else if (value.range) {
        // From - To
        const queryItem = {}
        if (data[value.name].from) {
          queryItem.gte = data[value.name].from
        }
        if (data[value.name].to) {
          queryItem.lte = data[value.name].to
        }
        if (Object.keys(queryItem).length > 0) {
          reqBody[value.queryName] = queryItem
        }
      } else if (value.type === 'select') {
        if (data[value.name] !== 'All') reqBody[value.queryName] = data[value.name]
      } else {
        // Others
        if (value.operator === 'ilike') {
          if (data[value.name]) {
            const reqValue = `%${data[value.name]}%`
            reqBody[value.queryName] = {
              [value.operator]: reqValue
            }
          }
        } else {
          if (data[value.name]) {
            reqBody[value.queryName] = data[value.name]
          }
        }
      }
    }

    // Advanced Search
    const selectedDepartment = data.advanceSearchInput_department
    const selectedBusinessUnit = data.advanceSearchInput_businessUnit
    const selectedBranchOffice = data.advanceSearchInput_branchOffice

    if (branchAll && (selectedDepartment || selectedBusinessUnit || selectedBranchOffice)) {
      // There is advanced search
      const selectedOrganizations = organizationState.list.filter(item => item.company === company &&
        (!selectedDepartment || item.department === selectedDepartment) &&
        (!selectedBusinessUnit || item.businessUnit === selectedBusinessUnit) &&
        (!selectedBranchOffice || item.branchOffice === selectedBranchOffice)
      ).map(item => parseInt(item.id))

      reqBody.organizationId = { in: selectedOrganizations }
    }

    return reqBody
  }

  const getSelectedStatus = (formKey, value) => {
    const curSelected = getValues(formKey)

    const selectedItems = [
      getValues('advanceSearchLabel_1'),
      getValues('advanceSearchLabel_2'),
      getValues('advanceSearchLabel_3')
    ]

    if (value !== curSelected && selectedItems.includes(value)) {
      return true
    }
    return false
  }

  const contains = (stringValue, charValue) => {
    return stringValue.indexOf(charValue) > -1
  }
  const allowTypeNumericOnly = (e) => {
    const invalidKey = (e.key.length === 1 && !contains(allowedChars, e.key)) || (e.key === '.' && contains(e.target.value, '.'))
    return invalidKey && e.preventDefault()
  }

  const renderInputItem = (inputItem, tabIdx) => {
    let inputItemElem
    if (inputItem.isPlate) {
      inputItemElem =
      <div className="input-group" onBlur={ (e) => { onLicenseBlur(inputItem.number, inputItem.name) } }>
          <input
            className="input-item"
            data-testid={inputItem.address}
            placeholder={i18n.t('VEHICLE_COMMON_LICENSE-PLATE-ADDRESS_LABEL')}
            type="text"
            maxLength={255}
            tabIndex={tabIdx}
            {...register(inputItem.address)} />
          <input
            className="input-item"
            data-testid={inputItem.classNo}
            placeholder={i18n.t('VEHICLE_COMMON_LICENSE-PLATE-CLASS-NO_LABEL')}
            type="text"
            maxLength={255}
            tabIndex={tabIdx}
            {...register(inputItem.classNo)} />
          <input
            onKeyDown={(e) => { (e.key.length === 1 && !contains('0123456789', e.key)) && e.preventDefault() }}
            className="input-item"
            data-testid={inputItem.number}
            placeholder={i18n.t('VEHICLE_COMMON_LICENSE-PLATE-NUMBER_LABEL')}
            type="text"
            maxLength={4}
            tabIndex={tabIdx}
            {...register(inputItem.number)} />
        </div>
    } else if (inputItem.range) {
      inputItemElem = (
        <div className="input-group"
          {...(inputItem.type === 'number' && { onBlur: onNumberBlur(inputItem.name) })}
          {...(inputItem.type === 'date' && { onBlur: onDateBlur(inputItem.name) })}
        >
          <input
            {...!integerNumberOnly.includes(inputItem.name) && { step: 'any' }}
            onKeyDown={(e) => allowTypeNumericOnly(e)}
            className="input-item"
            placeholder="From"
            type={inputItem.type === 'number' ? 'text' : inputItem.type}
            data-testid={`${inputItem.name}-from`}
            {...(inputItem.type === 'date' && { min: '1890-01-01' })}
            {...(inputItem.type === 'date' && { max: watch(`${inputItem.name}.to`) || '9999-12-31' })}
            {...register(`${inputItem.name}.from`)}
            tabIndex={tabIdx} />
          <input
            {...!integerNumberOnly.includes(inputItem.name) && { step: 'any' }}
            onKeyDown={(e) => allowTypeNumericOnly(e)}
            className="input-item"
            placeholder="To"
            type={inputItem.type === 'number' ? 'text' : inputItem.type}
            // min={getValues(`${inputItem.name}.from`)}
            // max={inputItem.type === 'date' && '9999-12-31'}
            data-testid={`${inputItem.name}-to`}
            {...(inputItem.type === 'date' && { min: watch(`${inputItem.name}.from`) || '1890-01-01' })}
            {...(inputItem.type === 'date' && { max: '9999-12-31' })}
            {...register(`${inputItem.name}.to`)}
            tabIndex={tabIdx}/>
        </div>
      )
    } else {
      switch (inputItem.type) {
        case 'select':
          inputItemElem =
            <div className="input-group">
              <RegisterMultiSelect
                className="input-item"
                marginLeft={'10px'}
                placeholder={i18n.t('COMMON_COMMON_DROPDOWN-OPTION-ALL_LABEL')}
                options={getDropDownOptions(inputItem.options, DropDownList)}
                valueField="key"
                labelField="value"
                element={{ value: inputItem.name }}
              />
            </div>
          break
        default:
          inputItemElem =
            <div className="input-group">
              <input
                className="input-item"
                data-testid={inputItem.name}
                type={inputItem.type}
                maxLength={inputItem.maxLength}
                {...register(inputItem.name)}
                tabIndex={tabIdx} />
            </div>
      }
    }
    return (
      <>
        <div className="label-text">
          <label>{i18n.t(inputItem.label)}</label>
        </div>
        <div>
          {inputItemElem}
          {inputItem.range && renderErrorText(inputItem.name)}
          {inputItem.isPlate && renderErrorText(inputItem.name)}
        </div>
      </>
    )
  }

  const renderErrorText = field => {
    let errorMessage = ''
    if (errors[field]) {
      errorMessage = errors[field].message || errors[field].from.message
    }
    return errorMessage ? <p data-testid="error-text" className="error-text">{`${errorMessage}`}</p> : ''
  }

  const renderAdvancedSearchInput = (formKey) => {
    const advanceSearchValue = getValues(formKey)
    const index = advancedSearchOptions.map((e) => e.value).indexOf(advanceSearchValue)
    const advanceSearchItem = advancedSearchOptions[index]
    // Advanced search fields only have dropdown type
    return (
      <MultiSelect
        width="100%"
        testId={`advanced-search-input-${advanceSearchItem.value}`}
        className="input-item"
        disabled={company === ''}
        placeholder={i18n.t('COMMON_COMMON_DROPDOWN-PLEASE-SELECT_LABEL')}
        options={getAdvancedSearchOptions(advanceSearchItem.value)}
        valueField="key"
        labelField="value"
        onChange={e => handleAdvancedSearchValueChanges(e, `advanceSearchInput_${advanceSearchItem.value}`)}
        defaultValue={`${watch(`advanceSearchInput_${advanceSearchItem.value}`)}`}
      />
    )
  }

  const handleAdvancedSearchValueChanges = (value, dataName) => {
    const currentValue = getValues(dataName)
    if (!branchAll && value[0].key && currentValue !== value[0].key) {
      // show message
      setShowMessage(true)
      // Cancel select
      return true
    }
    setValue(dataName, value[0].key)
  }

  const getAdvancedSearchOptions = (searchType) => {
    switch (searchType) {
      case 'department':
        return getDepartmentOptions()
      case 'businessUnit':
        return getBusinessUnitOptions()
      case 'branchOffice':
        return getBranchOfficeOptions()
    }
  }

  const getDepartmentOptions = () => {
    let departments = []
    if (company !== '') {
      // Company selected
      departments = [...new Set(organizationState.list.filter(item => item.company === company)
        .map(item => item.department))]
    }
    departments = departments.map(item => { return { key: item, value: item } })
    return [{ key: '', value: i18n.t('COMMON_COMMON_DROPDOWN-OPTION-ALL_LABEL') }, ...departments]
  }

  const getBusinessUnitOptions = () => {
    let businessUnits = []
    if (company !== '') {
      // Company selected
      const selectedDepartment = watch('advanceSearchInput_department')
      businessUnits = [...new Set(organizationState.list
        .filter(item => item.company === company && (!selectedDepartment || item.department === selectedDepartment))
        .map(item => item.businessUnit))]
    }
    businessUnits = businessUnits.map(item => { return { key: item, value: item } })
    return [{ key: '', value: i18n.t('COMMON_COMMON_DROPDOWN-OPTION-ALL_LABEL') }, ...businessUnits]
  }

  const getBranchOfficeOptions = () => {
    let branchOffices = []
    if (company !== '') {
      // Company selected
      const selectedDepartment = watch('advanceSearchInput_department')
      const selectedBusinessUnit = watch('advanceSearchInput_businessUnit')
      branchOffices = [...new Set(organizationState.list.filter(item => item.company === company &&
        (!selectedDepartment || item.department === selectedDepartment) &&
        (!selectedBusinessUnit || item.businessUnit === selectedBusinessUnit)
      ).map(item => item.branchOffice))]
    }
    branchOffices = branchOffices.map(item => { return { key: item, value: item } })
    return [{ key: '', value: i18n.t('COMMON_COMMON_DROPDOWN-OPTION-ALL_LABEL') }, ...branchOffices]
  }

  return (
    <FilterTabWrapper>
      <Modal show={showMessage}>
        <>
          <div className="modal-message-content">{i18n.t('VEHICLE_VEHICLE-LIST_POPUP-WARNING1_LABEL')}</div>
          <div className="modal-message-button">
            <OkButton data-testid="message-ok" onClick={() => setShowMessage(false)} />
          </div>
        </>
      </Modal>
      <FormProvider {...useFormMethods}>
        <form className="form-container" onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="general-filter-group">
            <div className="filter-container">
              {/* Column 1 */}
              <div className="input-container">
                {renderInputItem(fieldNames.license, 1)}
                {renderInputItem(fieldNames.brand, 4)}
                {renderInputItem(fieldNames.class, 7)}
                {renderInputItem(fieldNames.regisDate, 10)}
                {renderInputItem(fieldNames.engineModel, 13)}
                {renderInputItem(fieldNames.status, 16)}
              </div>
              {/* Column 2 */}
              <div className="input-container">
                {renderInputItem(fieldNames.carNumber, 2)}
                {renderInputItem(fieldNames.pastLicense, 5)}
                {renderInputItem(fieldNames.bodyShape, 8)}
                {renderInputItem(fieldNames.initRegisDate, 11)}
                {renderInputItem(fieldNames.totalDisplace, 14)}
              </div>
              {/* Column 3 */}
              <div className="input-container">
                {renderInputItem(fieldNames.type, 3)}
                {renderInputItem(fieldNames.vehicleId, 6)}
                {renderInputItem(fieldNames.specialEquipment, 9)}
                {renderInputItem(fieldNames.model, 12)}
                {renderInputItem(fieldNames.fuelType, 15)}
              </div>
            </div>
            {/* test */}
            <div className="filter-container" style={{ marginBottom: '8px' }}>
              <div className="label-text">
                <label>{i18n.t('VEHICLE_VEHICLE-LIST_ADVANCE-SEARCH_LABEL')}</label>
              </div>
            </div>
            <div className="filter-container">
              {[1, 2, 3].map((e, i) => {
                return (
                  <div key={e} className="advanced-input-container">
                    <div className="input-group">
                      {!watch(`advanceSearch_${e}`) && e !== 1 &&
                        <AddCircleOutlineIcon
                          style={{ alignSelf: 'center' }}
                          fontSize="large"
                          className="btn-add"
                          data-testid={`plus-icon-${e}`}
                          {...register(`advanceSearch_${e}`)}
                          {...watch(`advanceSearch_${e}`, false)}
                          onClick={() => setValue(`advanceSearch_${e}`, true)}
                        />
                      }
                      {((watch(`advanceSearch_${e}`) && watchAdvancedSelected) || e === 1) &&
                        <select defaultValue="" data-testid={`advanced-search-${e}`} className={`input-item item_${getValues(`advanceSearchLabel_${e}`)}`} style={{ marginLeft: '0' }} {...register(`advanceSearchLabel_${e}`)}
                        tabIndex={15 + e}>
                          <option value="">{i18n.t('COMMON_COMMON_DROPDOWN-PLEASE-SELECT_LABEL')}</option>
                          {advancedSearchOptions.map(({ value, label }, index) => {
                            return <option value={value} key={index} disabled={getSelectedStatus(`advanceSearchLabel_${e}`, value)}>{i18n.t(label)}</option>
                          })}
                        </select>
                      }
                    </div>
                    <div className="input-group" {...watch(`advanceSearchLabel_${e}`) && { tabIndex: 15 + e + 1 }}>
                      {watch(`advanceSearchLabel_${e}`) && renderAdvancedSearchInput(`advanceSearchLabel_${e}`)}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          {/* Submit */}
          <div className="submit-container">
            <SearchButton type="submit" data-testid="search-submit" {...errorFields.length > 0 && { disabled: true }} tabIndex="30"/>
          </div>
        </form>
      </FormProvider>
    </FilterTabWrapper>
  )
}

export default GeneralTab

GeneralTab.propTypes = {
  onSearchSubmit: PropTypes.func,
  company: PropTypes.string,
  branchAll: PropTypes.bool,
  setAdvancedOrganization: PropTypes.func
}
