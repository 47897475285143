import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { TabWrap, TabItemWrap, TabButtonWrap } from './styles'
function Tab (props) {
  const [selectedTab, setTab] = React.useState(0)
  const { tabList, onTabChange, tab } = props
  useEffect(() => setTab(tab))
  const onTabClick = (e) => {
    onTabChange(parseInt(e.target.id))
    setTab(parseInt(e.target.id))
  }
  return (
    <TabWrap hidden={props.hidden}>
      <div className="tab-row">
        {tabList.map((i, index) =>
          <TabButtonWrap
            id={index}
            focus={selectedTab === index}
            onClick={onTabClick}
            key={index}
          >
            <span>{i.props.name}</span>
          </TabButtonWrap>)
        }
      </div>
      {tabList[selectedTab]}
    </TabWrap>
  )
}

function TabItem (propsItem) {
  return (
    <TabItemWrap key={propsItem.name}>
      {propsItem.children}
    </TabItemWrap>
  )
}

Tab.TabItem = TabItem

export default Tab

Tab.propTypes = {
  tab: PropTypes.number,
  tabList: PropTypes.array,
  onTabChange: PropTypes.func,
  hidden: PropTypes.bool
}
