import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import i18n from 'i18next'

import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import minMax from 'dayjs/plugin/minMax'
import MultiSelect from '../../components/MultiSelect'
// import Select from '@material-ui/core/Select';
import { ContractManagementWrapper } from './styles'
import { SearchButton } from '../../components/Button'
import ContractDetail from '../../components/ContractDetail'
import YearClick from '../../components/YearClick'
import { CircularProgress } from '@material-ui/core'
import Modal from '../../components/Modal/index'
import { getOrganizationList } from '../../features/organizations/organizationSlice'
import { getContract, resetState, getContractDetail } from '../../features/contracts/contractSlice'
import { useLocation } from 'react-router-dom'
import { ContractColumnMap } from '../../components/Table/columnNameMap'
dayjs.extend(quarterOfYear)
dayjs.extend(minMax)

function ContractManagement () {
  const dispatch = useDispatch()
  const location = useLocation()
  const [currentYear, setYear] = React.useState(dayjs().format('YYYY'))
  const [company, setCompany] = React.useState('')
  const [focusElement, setFocus] = React.useState(dayjs())
  const [branchs, setBranchs] = React.useState(location.state?.organizationIdDefault ? location.state.organizationIdDefault : [])
  const [lastFetch, setLastFetch] = React.useState('')
  const [fromDate, setFromDate] = React.useState(dayjs().startOf('month').format('YYYY-MM-DD'))
  const [searchDate, setSearchDate] = React.useState(dayjs().startOf('month').format('YYYY-MM-DD'))
  const [toDate, setToDate] = React.useState(dayjs().endOf('month').format('YYYY-MM-DD'))
  const [highlightedRow, setHighlightedRow] = React.useState(null)
  const contractState = useSelector(state => state.contract)
  const organizationState = useSelector(state => state.organization)

  React.useEffect(() => {
    dispatch(getOrganizationList())
    dispatch(resetState())
    // dispatch(getContract('monthly', { year: dayjs().format('YYYY') }))

    // TODO: call another api for get detail on right bottom of screen render
    // dispatch(getContractDetail())

    // get company list >> selector
    // get branch list >> selector
    // fetch data from ALL, first branch
    // filter by monthly, quarter, period, year
    const today = new Date()
    const month = today.getMonth()
    setHighlightedRow(month)
    if (!location.state) return
    const { companyName, organizationIdDefault } = location.state
    searchDataInState(location.state.period, organizationIdDefault, companyName)
  }, [])

  const getCompanyOptions = () => {
    let companyOptions = [...new Set(organizationState.list.map(item => item.company))]
    companyOptions = companyOptions.map(item => { return { key: item, value: item } })
    return [{ key: '', value: i18n.t('COMMON_COMMON_DROPDOWN-OPTION-ALL_LABEL') }, ...companyOptions]
  }

  const handleCompanyChange = (e) => {
    setCompany(e[0].key)
  }

  const handleBranchChange = (e) => {
    setBranchs(e.map(item => item.id))
  }

  const searchDataInState = (period, organizationId, companyName) => {
    const { from, to, year, focus } = period
    setBranchs(organizationId)
    setYear(year)
    const dayjsFrom = dayjs(from)
    const dayjsTo = dayjs(to)
    setHighlightedRow(dayjsFrom.month())
    setFocus(focus)
    setFromDate(dayjsFrom.format('YYYY-MM-DD'))
    setToDate(dayjsTo.format('YYYY-MM-DD'))
    setSearchDate(dayjsFrom.format('YYYY-MM-DD'))
    const selectedId = branchs.length > 0 ? branchs : []
    const body = {
      year,
      organizationId: selectedId
    }

    setLastFetch(selectedId)
    dispatch(getContract({ body }))
    dispatch(getContractDetail({
      body: {
        from: dayjsFrom.format('YYYY-MM-DD'),
        to: dayjsTo.format('YYYY-MM-DD'),
        organizationId: selectedId
      }
    }))
  }

  const onClickSearch = () => {
    // From old multiple module
    // if (branchs.length === 0 && lastFetch.length > 0) {
    //   setBranchs(lastFetch)
    // }
    location.state = undefined
    const selectedId = branchs.length > 0 ? branchs : ''

    const body = {
      year: currentYear,
      organizationId: selectedId || []
    }
    dispatch(getContract({ body }))
    dispatch(getContractDetail({
      body: {
        from: fromDate,
        to: toDate,
        organizationId: selectedId || []
      }
    }
    ))

    setLastFetch(selectedId || [])
    setSearchDate(fromDate)
  }

  const middleHop = year => {
    setYear(year)
    setDateChange(year)
  }

  const setMiddleFocus = e => {
    setHighlightedRow(e)
    setFocus(e)
    const month = contractState.data[e].period
    const selectMonth = dayjs().year(currentYear).month(month - 1).date(1)
    setFromDate(selectMonth.startOf('month').format('YYYY-MM-DD'))
    setToDate(selectMonth.endOf('month').format('YYYY-MM-DD'))
    setSearchDate(selectMonth.startOf('month').format('YYYY-MM-DD'))
    if (lastFetch) {
      dispatch(getContractDetail({
        body: {
          from: selectMonth.startOf('month').format('YYYY-MM-DD'),
          to: selectMonth.endOf('month').format('YYYY-MM-DD'),
          organizationId: lastFetch
        }
      }))
    }
  }

  // const renderCompanyOptions = () => {
  //   const companyOptions = [...new Set(organizationState.list.map(item => item.company))]
  //   return companyOptions.map(item => (
  //     <option key={item} value={item}>{item}</option>
  //   ))
  // }

  // const setCompanyMiddle = e => {
  //   setCompany(e.target.value)
  //   const branchObjList = renderBranchOptions(e.target.value).map(i => i.value)
  //   setBranchs([...branchObjList, 'All'])
  // }

  const setDateChange = e => {
    setFromDate(dayjs(e + fromDate.replaceAll('-', '').substring(4, 8)).format('YYYY-MM-DD') || '')
    setToDate(dayjs(e + toDate.replaceAll('-', '').substring(4, 8)).format('YYYY-MM-DD') || '')
  }

  const type = [
    { title: 'CONTRACT_CONTRACT-MANAGEMENT_INSPECTION_LABEL', key: 'inspection', id: 'inspection' },
    { title: 'CONTRACT_CONTRACT-MANAGEMENT_LEASE_LABEL', key: 'lease', id: 'lease' },
    { title: 'CONTRACT_CONTRACT-MANAGEMENT_REGULAR-MAINTENACE_LABEL', key: 'regularMaintenance', id: 'regular-maintenance' },
    { title: 'CONTRACT_CONTRACT-MANAGEMENT_MANDATORY-INSURANCE_LABEL', key: 'mandatoryInsurance', id: 'mandatory-insurance' },
    { title: 'CONTRACT_CONTRACT-MANAGEMENT_SPECIAL-VEHICLE-PASS_LABEL', key: 'specialVehiclePass', id: 'special-vehicle-pass' },
    { title: 'CONTRACT_CONTRACT-MANAGEMENT_VOLUNTARY-INSURANCE_LABEL', key: 'voluntaryInsurance', id: 'voluntary-insurance' }
  ]

  const propTypes = '/vehicle'

  const rowClickEvent = (index) => {
    setMiddleFocus(index)
  }

  const renderColumnName = d => {
    const result = []
    for (const i in d) {
      result.push(<td key={i} className={`column-name column-${d[i]}`}>{i18n.t(d[i])}</td>)
    }
    return <thead className="header"><tr>{result}</tr></thead>
  }

  const renderData = list => {
    // no data
    if (list && list.length === 0) {
      return (
        <tbody className="table">
          <tr>
            <td className="table-message">{i18n.t('COMMON_COMMON_DATA-NOT-FOUND_LABEL')}</td>
          </tr>
        </tbody>
      )
    }
    // initial
    if (!list) {
      list = []
    }
    let result = []

    result = list.map((item, index) => {
      const i = { ...item }
      i.editable = false
      const row = []
      let dex = 0
      for (const j in ContractColumnMap) {
        let value = i[j]
        if (i.data && j !== 'period') {
          value = i.data[j]
        } else if (j === 'period') {
          value = i.period + '月'
        }
        row.push(<td data-testid='render-value' key={dex} className="value">{value}</td>)
        dex = ++dex
      }

      return <tr onClick={() => rowClickEvent(index)} id={list[index].id} key={index} className={index === highlightedRow ? 'row selected-row' : 'row'}>
        {row}
      </tr>
    })
    return <tbody className="table">{result}</tbody>
  }

  return (
    <ContractManagementWrapper>
      <section className="header">
        <div className="header-title">{i18n.t('CONTRACT_CONTRACT-MANAGEMENT_PAGE-HEADER_LABEL')}</div>
      </section>
      <section className="header">
        <div className="header-actions">
          {/* TODO: create Button 車両データ取込ボタン - (11-1 Element) */}
          {/* TODO: create Button ファイナンスデータ取込ボタン - (12-1 Element) */}
          <div className="action-options">
            <label>{i18n.t('COMMON_COMMON_TARGET-YEAR_LABEL')}</label>
            <YearClick onChange={middleHop} year={currentYear} />
          </div>
          <div className="action-options">
            <label>{i18n.t('COMMON_COMMON_COMPANY-DROPDOWN_LABEL')}</label>
            <MultiSelect
              width="100%"
              placeholder={i18n.t('COMMON_COMMON_DROPDOWN-PLEASE-SELECT_LABEL')}
              options={getCompanyOptions()}
              valueField="key"
              labelField="value"
              onChange={handleCompanyChange}
              defaultValue={(location.state && location.state.organizationIdDefault.length > 0) ? location.state.companyName : company }
            />
          </div>
          <div className="action-options">
            <label>{i18n.t('COMMON_COMMON_BRANCH-DROPDOWN_LABEL')}</label>
            <MultiSelect
              disabled={!company}
              width="100%"
              placeholder={i18n.t('COMMON_COMMON_DROPDOWN-PLEASE-SELECT_LABEL')}
              multi={true}
              allItem={true}
              options={organizationState.list.filter(item => item.company === company)}
              valueField="id"
              labelField="branch"
              onChange={handleBranchChange}
              defaultValue={(location.state && location.state.organizationIdDefault.length > 0) && location.state.organizationIdDefault}
            />
          </div>
          <div className="action-options"></div>
          <div className="action-options"></div>
          <div className="action-options" style={{ alignItems: 'flex-end', marginRight: '24px' }}>
            <div className="submit-container">
              <SearchButton data-testid="search-button" onClick={onClickSearch} />
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div>
          <h3>{i18n.t('CONTRACT_CONTRACT-MANAGEMENT_SECTION-HEADER_LABEL')}</h3>
          <div className="display-month-type-wrapper">
          <table>
            {renderColumnName(ContractColumnMap)}
            {renderData(contractState.data)}
          </table>
          </div>
        </div>
        <div>
          <h3>{i18n.t('CONTRACT_CONTRACT-MANAGEMENT_SUBSECTION-HEADER_LABEL')}</h3>
          <div className="input-date-wrapper">
            <div className="label-container">
              <span>{i18n.t('FORKLIFT_FORKLIFT-MANAGEMENT_DATE-FILTER_LABEL')}</span>
              <span id="selectYaerMonth" className='select-yaer-month'>{dayjs(searchDate).format('YYYY/MM')}</span>
            </div>
          </div>
          <ContractDetail period={{ from: fromDate, to: toDate, year: currentYear, focus: focusElement }} org={!location.state ? organizationState.list : location.state.organizationList} data={contractState.detail} lastFetchIds={lastFetch} type={type} propTypes={propTypes}/>
        </div>
      </section>
      <Modal show={contractState.load}><CircularProgress></CircularProgress></Modal>
    </ContractManagementWrapper>
  )
}

export default ContractManagement
