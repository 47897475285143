import React from 'react'
import PropTypes from 'prop-types'
import ImageGallery from 'react-image-gallery'
import { useSelector, useDispatch } from 'react-redux'
import { ForkliftDetailWrapper, FileGroupWrapper } from './styles'
import { getSingleForklift, setEditing } from '../../../features/forklift/forkliftSlice'
import { getFile } from '../../../features/file/fileSlice'
import ValueGroup from '../../../components/ValueGroup'
import Forklift from '../../../assets/images/forklift.png'
import PdfThumbnail from '../../../components/PdfThumbnail'
import { userInfo } from './../../../features/users/userInfoSlice'
import { EditButton, BackButton } from '../../../components/Button'
import i18n from 'i18next'
import {
  generalInformationMap,
  registrationInformationMap,
  weightSizeInformationMap,
  detailedVehicleInformationMap,
  fileTypeList
} from './valueMapping'
import { vehicleKind } from '../../../common/const'
function ForkliftDetail (props) {
  const dispatch = useDispatch()
  const userState = useSelector(state => state.userInfo)

  const documentNameMapping = {
    doc1: i18n.t('VEHICLE_COMMON_INSPECTION-CERTIFICATE-PREVIOUS_LABEL'),
    doc2: i18n.t('VEHICLE_COMMON_INSPECTION-CERTIFICATE-TWO-TIMES-BEFORE_LABEL'),
    doc3: i18n.t('VEHICLE_COMMON_MANDATORY-INSURANCE-POLICY_LABEL'),
    doc4: i18n.t('FORKLIFT_COMMON_VOLUNTARY-INSURANCE-REPORT_LABEL'),
    doc5: i18n.t('VEHICLE_COMMON_LAST-CERTIFICATE_LABEL'),
    doc6: i18n.t('FORKLIFT_COMMON_ANNUAL-INSPECTION-REPORT_LABEL'),
    doc7: i18n.t('FORKLIFT_COMMON_PURCHASE-AGREEMENT_LABEL'),
    doc8: i18n.t('FORKLIFT_COMMON_RENTAL-CONTRACT_LABEL')
  }

  const forkliftValue = useSelector(state => state.forklift.selectedForklift)
  const fileState = useSelector(state => state.file)
  const [photo1, setPhoto1] = React.useState(null)
  const [photo2, setPhoto2] = React.useState(null)
  const [photo3, setPhoto3] = React.useState(null)
  const [photo4, setPhoto4] = React.useState(null)
  const [photo5, setPhoto5] = React.useState(null)
  const [document1, setDocument1] = React.useState(null)
  const [document2, setDocument2] = React.useState(null)
  const [document3, setDocument3] = React.useState(null)
  const [document4, setDocument4] = React.useState(null)
  const [document5, setDocument5] = React.useState(null)
  const [document6, setDocument6] = React.useState(null)
  const [document7, setDocument7] = React.useState(null)
  const [document8, setDocument8] = React.useState(null)

  React.useEffect(() => {
    if (props.match?.params) {
      dispatch(getSingleForklift({ id: props.match.params.id }))
      // 画像やPDFファイルをS3から取得
      Object.values(fileTypeList).map(async (value) => {
        dispatch(getFile({ id: props.match.params.id, category: value, vehicleKind: vehicleKind.forklift }))
      })
    } else if (props.id) {
      dispatch(getSingleForklift({ id: props.id }))
      // 画像やPDFファイルをS3から取得
      Object.values(fileTypeList).map(async (value) => {
        dispatch(getFile({ id: props.id, category: value, vehicleKind: vehicleKind.forklift }))
      })
    }
  }, [])

  React.useEffect(() => {
    dispatch(userInfo())
    dispatch(setEditing(false))
  }, [])

  React.useEffect(() => {
    if (fileState.list.length !== 0) {
      // 車検証
      const syakenList = fileState.list.filter((x) => { return x.category === fileTypeList.Syaken })
      if (syakenList.length === 1) {
        if (syakenList[0].path !== null) {
          const path = syakenList[0].path
          setDocument1(path)
        } else {
          setDocument1(null)
        }
        setDocument2(null)
      } else if (syakenList.length === 2) {
        if (syakenList[0].path !== null) {
          const document1Path = syakenList[0].path
          setDocument1(document1Path)
        } else {
          setDocument1(null)
        }
        if (syakenList[1].path !== null) {
          const document2Path = syakenList[1].path
          setDocument2(document2Path)
        } else {
          setDocument2(null)
        }
      } else {
        setDocument1(null)
        setDocument2(null)
      }
      // 自賠責証書
      const zibaisekiList = fileState.list.filter((x) => { return x.category === fileTypeList.Zibaiseki })
      if (zibaisekiList.length === 1) {
        if (zibaisekiList[0].path !== null) {
          const path = zibaisekiList[0].path
          setDocument3(path)
        } else {
          setDocument3(null)
        }
        setDocument5(null)
      } else if (zibaisekiList.length === 2) {
        if (zibaisekiList[0].path !== null) {
          const document3Path = zibaisekiList[0].path
          setDocument3(document3Path)
        } else {
          setDocument3(null)
        }
        if (zibaisekiList[1].path !== null) {
          const document5Path = zibaisekiList[1].path
          setDocument5(document5Path)
        } else {
          setDocument5(null)
        }
      } else {
        setDocument3(null)
        setDocument5(null)
      }
      // 車両画像
      const syaryouiList = fileState.list.filter((x) => { return x.category === fileTypeList.Syaryou })
      const photoList = [setPhoto1, setPhoto2, setPhoto3, setPhoto4, setPhoto5]
      let count = 0
      if (syaryouiList.length >= 1) {
        for (let i = 0; i < syaryouiList.length; i++) {
          if (syaryouiList[i].path !== null) {
            const path = syaryouiList[i].path
            photoList[i](path)
            count++
          }
        }
        for (let i = count; i < photoList.length; i++) {
          photoList[i](null)
        }
      } else {
        setPhoto1(null)
        setPhoto2(null)
        setPhoto3(null)
        setPhoto4(null)
        setPhoto5(null)
      }
      // 任意保険
      setFileDocument(fileTypeList.NiniHoken, setDocument4)
      // 年次点検報告書
      setFileDocument(fileTypeList.NenziTenkin, setDocument6)
      // 購入時契約書
      setFileDocument(fileTypeList.KounyuziKeiyaku, setDocument7)
      // レンタル時契約書
      setFileDocument(fileTypeList.Rental, setDocument8)
    } else {
      setPhoto1(null)
      setPhoto2(null)
      setPhoto3(null)
      setPhoto4(null)
      setPhoto5(null)
      setDocument1(null)
      setDocument2(null)
      setDocument3(null)
      setDocument4(null)
      setDocument5(null)
      setDocument6(null)
      setDocument7(null)
      setDocument8(null)
    }
  }, [fileState.list])

  const setFileDocument = (category, setDocumet) => {
    const list = fileState.list.filter((x) => { return x.category === category })
    if (list.length === 1) {
      if (list[0].path !== null) {
        const path = list[0].path
        setDocumet(path)
      } else {
        setDocumet(null)
      }
    } else {
      setDocumet(null)
    }
  }

  const images = [
    {
      original: photo1,
      thumbnail: photo1
    },
    {
      original: photo2,
      thumbnail: photo2
    },
    {
      original: photo3,
      thumbnail: photo3
    },
    {
      original: photo4,
      thumbnail: photo4
    },
    {
      original: photo5,
      thumbnail: photo5
    }
  ]

  const imagePlaceHolder = {
    original: Forklift,
    thumbnail: Forklift
  }

  const handleMouseIn = (e) => {
    if (e.target.offsetWidth < e.target.scrollWidth) {
      // get the tooltip
      const tooltip = getTooltipElem(e.target.parentNode)
      // show tooltip
      if (tooltip) {
        tooltip.style.display = 'block'
      }
    }
  }

  const handleMouseOut = (e) => {
    // get the tooltip
    const tooltip = getTooltipElem(e.target.parentNode)
    // hide tooltip
    if (tooltip) {
      tooltip.style.display = 'none'
    }
  }

  const handleOnClick = (e) => {
    // select text
    const range = document.createRange()
    range.selectNode(e.target)
    window.getSelection().removeAllRanges()
    window.getSelection().addRange(range)
  }

  const handleTooltipLeave = (e) => {
    // hide tooltip
    e.target.parentNode.style.display = 'none'
  }

  const getTooltipElem = (parent) => {
    const tooltip = parent.querySelector('.tooltip-wrapper')
    return tooltip
  }

  const defaultImages = [imagePlaceHolder, imagePlaceHolder, imagePlaceHolder, imagePlaceHolder, imagePlaceHolder]
  const filterForImages = images.filter(i => i.original)
  return (
    <ForkliftDetailWrapper>
      <section className="header">
        <div className="header-title">{i18n.t('FORKLIFT_FORKLIFT-INFORMATION_PAGE-HEADER_LABEL')}</div>
        <div className="header-actions">
          {
            (userState.isEdit || (userState.isAdmin && userState.isEdit))
              ? <EditButton data-testid="edit-button" onClick={() => props.onEdit ? props.onEdit(props.id) : props.history.push(`/forklifteditinfo/${props.onBack ? props.id : props.match.params.id}/edit`)} />
              : ''
          }
          <BackButton data-testid="back-button" onClick={() => props.onBack ? props.onBack(false) : props.history.push('/forklift')} />
        </div>
      </section>
      <div className="scrolled-content">
        <section className="general">
          <div className="vehicle-images">
            <ImageGallery items={filterForImages.length > 0 ? filterForImages : defaultImages} infinite={false} showPlayButton={false} showFullscreenButton={false} />
          </div>
          <div>
            <ValueGroup
              title={i18n.t('VEHICLE_COMMON_GENERAL-INFO_LABEL')}
              valueLabels={generalInformationMap}
              values={forkliftValue}
            />
          </div>
        </section>
        <section className="registration">
          <ValueGroup
            title={i18n.t('VEHICLE_COMMON_REGISTRATION-INFO_LABEL')}
            valueLabels={registrationInformationMap}
            values={forkliftValue}
            column={2}
            row={9}
          />
          <FileGroupWrapper style={{ width: '59%' }}>
            <PdfThumbnail file={document6} title={documentNameMapping.doc6} />
            <PdfThumbnail file={document7} title={documentNameMapping.doc7} />
            <PdfThumbnail file={document8} title={documentNameMapping.doc8} />
          </FileGroupWrapper>
          <FileGroupWrapper>
            <PdfThumbnail file={document1} title={documentNameMapping.doc1} />
            <PdfThumbnail file={document2} title={documentNameMapping.doc2} />
            <PdfThumbnail file={document3} title={documentNameMapping.doc3} />
            <PdfThumbnail file={document5} title={documentNameMapping.doc5} />
            <PdfThumbnail file={document4} title={documentNameMapping.doc4} />
          </FileGroupWrapper>
        </section>
        <section className="weight-size">
          <ValueGroup
            title={i18n.t('VEHICLE_COMMON_WEIGHT-SIZE-INFO_LABEL')}
            valueLabels={weightSizeInformationMap}
            values={forkliftValue}
            column={2}
            row={2}
          />
        </section>
        <section className="detail-vehicle">
          <ValueGroup
            title={i18n.t('VEHICLE_COMMON_DETAILED-VEHICLE-INFO_LABEL')}
            valueLabels={detailedVehicleInformationMap}
            values={forkliftValue}
            column={2}
            row={4}
          />
          <div className="remarks-contain">
            <div className="remarks-label">{i18n.t('VEHICLE_COMMON_REMARKS_LABEL')}</div>
            <div className="value" onMouseOver={handleMouseIn} onMouseLeave={handleMouseOut}>
              <div onClick={handleOnClick}>{forkliftValue.importantNotes ? forkliftValue.importantNotes : '-'}</div>
              {forkliftValue.importantNotes
                ? (
                  <span className="tooltip-wrapper" onMouseOut={handleTooltipLeave}>
                    <span className="tooltiptext">{forkliftValue.importantNotes}</span>
                  </span>
                  )
                : ''}
            </div>
          </div>
        </section>
      </div>
    </ForkliftDetailWrapper>
  )
}

export default ForkliftDetail

ForkliftDetail.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  id: PropTypes.string,
  onBack: PropTypes.func,
  onEdit: PropTypes.func
}
