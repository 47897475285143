import { dropdownCategory } from '../../common/const'

export const fieldNames = {
  licenseAddress: { name: 'licensePlateAddress', type: 'string' },
  licenseClassNo: { name: 'licensePlateClass', type: 'string' },
  licenseNumber: { name: 'licensePlateNumber', type: 'string' }
}

export const advanceSearchOptions = [
  // { value: 'engineNumber', label: 'VEHICLE_COMMON_ENGINE-NUMBER_LABEL', type: 'text', maxLength: '255', selected: false },
  { value: 'body', label: 'VEHICLE_COMMON_BODY_LABEL', type: 'dropdown', maxLength: '', options: dropdownCategory.body, selected: false },
  { value: 'bodyManufacturer', label: 'VEHICLE_COMMON_BODY-MANUFACTURER_LABEL', type: 'dropdown', maxLength: '', options: dropdownCategory.bodyManufacturer, selected: false },
  { value: 'wing', label: 'VEHICLE_COMMON_WING_LABEL', type: 'dropdown', maxLength: '', options: dropdownCategory.wing, selected: false },
  { value: 'powerGate', label: 'VEHICLE_COMMON_POWER-GATE_LABEL', type: 'dropdown', maxLength: '', options: dropdownCategory.powerGate, selected: false },
  { value: 'powerManufacturer', label: 'VEHICLE_COMMON_POWER-MANUFACTURER_LABEL', type: 'dropdown', maxLength: '', options: dropdownCategory.powerManufacturer, selected: false },
  { value: 'powerGateLift', label: 'VEHICLE_COMMON_POWER-GATE-LIFT_LABEL', type: 'number - fromto', maxLength: '10', selected: false },
  { value: 'craneManufacturer', label: 'VEHICLE_COMMON_CRANE-MANUFACTURER_LABEL', type: 'dropdown', maxLength: '', options: dropdownCategory.craneManufacturer, selected: false },
  { value: 'craneLift', label: 'VEHICLE_COMMON_CRANE-LIFT_LABEL', type: 'number - fromto', maxLength: '10', selected: false },
  { value: 'withJorder', label: 'VEHICLE_COMMON_WITH-OR-WITHOUT-JORDER_LABEL', type: 'dropdown', maxLength: '', options: dropdownCategory.withJorder, selected: false },
  { value: 'airSuspension', label: 'VEHICLE_COMMON_AIR-SUSPENSION_LABEL', type: 'dropdown', maxLength: '', options: dropdownCategory.airSuspension, selected: false },
  { value: 'floorMaterial', label: 'VEHICLE_COMMON_FLOOR-MATERIAL_LABEL', type: 'text', maxLength: '255', selected: false },
  { value: 'floorWork', label: 'VEHICLE_COMMON_FLOOR-WORK_LABEL', type: 'text', maxLength: '255', selected: false },
  { value: 'fuelTank', label: 'VEHICLE_COMMON_FUEL-TANK_LABEL', type: 'number - fromto', maxLength: '10', selected: false },
  { value: 'refrigeratorManufacturer', label: 'VEHICLE_COMMON_REFRIGERATOR-MANUFACTURER_LABEL', type: 'dropdown', maxLength: '', options: dropdownCategory.refrigeratorManufacturer, selected: false },
  { value: 'refrigeratorModel', label: 'VEHICLE_COMMON_REFRIGERATOR-MODEL_LABEL', type: 'dropdown', maxLength: '', required: false, options: dropdownCategory.refrigeratorModel },
  { value: 'inVehicleFreezingEquipment', label: 'VEHICLE_COMMON_IN-VEHICLE-FREEZING-EQUIPMENT_LABEL', type: 'dropdown', maxLength: '', options: dropdownCategory.inVehicleFreezingEquipment, selected: false },
  { value: 'temperatureRecord', label: 'VEHICLE_COMMON_TEMPERATURE-RECORD_LABEL', type: 'dropdown', maxLength: '', options: dropdownCategory.temperatureRecord, selected: false },
  { value: 'standBy', label: 'VEHICLE_COMMON_STAND-BY_LABEL', type: 'dropdown', maxLength: '', options: dropdownCategory.standBy, selected: false },
  { value: 'etcTwo', label: 'VEHICLE_COMMON_ETC_LABEL', type: 'dropdown', maxLength: '', options: dropdownCategory.etcTwo, selected: false },
  { value: 'etcOnBoardUnitId', label: 'VEHICLE_COMMON_ON-BOARD-UNIT-ID_LABEL', type: 'text', maxLength: '255', selected: false },
  { value: 'etcCorporateCardNumber', label: 'VEHICLE_COMMON_ETC-CORPORATE-CARD-NUMBER_LABEL', type: 'text', maxLength: '255', selected: false },
  { value: 'fuelCardNo', label: 'VEHICLE_COMMON_FUEL-CARD-NO_LABEL', type: 'text', maxLength: '255', selected: false },
  { value: 'adBlue', label: 'VEHICLE_COMMON_ADBLUE_LABEL', type: 'dropdown', maxLength: '', options: dropdownCategory.adBlue, selected: false },
  { value: 'dashcamManufacturer', label: 'VEHICLE_COMMON_DASHCAM-MANUFACTURER_LABEL', type: 'dropdown', maxLength: '', options: dropdownCategory.dashcamManufacturer, selected: false },
  { value: 'backEyeCameraManufacturer', label: 'VEHICLE_COMMON_BACK-EYE-CAMERA-MANUFACTURER_LABEL', type: 'dropdown', maxLength: '', options: dropdownCategory.backEyeCameraManufacturer, selected: false },
  { value: 'cornerSensor', label: 'VEHICLE_COMMON_CORNER-SENSOR_LABEL', type: 'dropdown', maxLength: '', options: dropdownCategory.cornerSensor, selected: false },
  { value: 'digiTachoManufacture', label: 'VEHICLE_COMMON_DIGI-TACHO-MANUFACTURER_LABEL', type: 'dropdown', maxLength: '', options: dropdownCategory.digiTachoManufacture, selected: false },
  { value: 'digiTachoUniqueCode', label: 'VEHICLE_COMMON_DIGI-TACHO-UNIQUE-CODE_LABEL', type: 'text', maxLength: '255', selected: false },
  { value: 'remarks', label: 'VEHICLE_COMMON_REMARKS_LABEL', type: 'text', maxLength: '255', selected: false }
]

export const integerNumberOnly = ['seatingCapacity', 'licensePlateNumber']

export default advanceSearchOptions
