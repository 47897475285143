import React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import Modal from '../Modal'
import ModalError from './ModalError'
import CircularProgress from '@material-ui/core/CircularProgress'
import { ImportFinanceWrap } from './styles'
import { ImportFinanceButton, NoButton, OkButton, YesButton } from '../Button'
import i18n from 'i18next'
import { checkFinance, clearState, importForkliftFinance } from '../../features/forklift/importForkliftFinanceSlice'
function ImportFinance ({ children }) {
  const hiddenFileInputFinance = React.useRef(null)

  const handleClickFinance = event => {
    hiddenFileInputFinance.current.click()
  }

  const [selectedFileFinance, setFile] = React.useState('')
  const [randomStringFinance, setRandomString] = React.useState('0')
  const [showFinance, setShowFinance] = React.useState(false)
  const [showFinanceSuccess, setSuccessShowFinance] = React.useState(false)
  const dispatch = useDispatch()
  const financeState = useSelector(state => state.importForkliftFinance)

  const handleCloseFinance = () => {
    setShowFinance(false)
    dispatch(clearState())
  }

  const handleSuccessClose = () => {
    setSuccessShowFinance(false)
    dispatch(clearState())
  }

  React.useEffect(() => {
    dispatch(clearState())
  }, [])

  const submitImportFinance = e => {
    dispatch(importForkliftFinance({ file: selectedFileFinance }))
    setShowFinance(false)
  }

  const handleShowFinance = e => {
    if (e.target.files.length > 0) {
      setFile(e.target.files[0])
      dispatch(checkFinance({ file: e.target.files[0] }))
    }
    e.target.value = ''
  }
  React.useEffect(() => {
    const { check } = financeState
    if ('insert' in check.success && 'update' in check.success) {
      setShowFinance(true)
    }
    if ('insert' in financeState.import.data && 'update' in financeState.import.data) {
      setSuccessShowFinance(true)
    }
  }, [financeState])
  return (
    <ImportFinanceWrap>
      <ImportFinanceButton data-testid="import-button" onClick={handleClickFinance} />
      <input
        type="file"
        data-testid="file-input"
        key={randomStringFinance}
        ref={hiddenFileInputFinance}
        onChange={handleShowFinance}
        accept=".csv"
        style={{ display: 'none' }}
      />
      {(financeState.import.load || financeState.check.load) &&
        <Modal id="model-popup-dialog" show={true}>
          <CircularProgress />
        </Modal>
      }
      {showFinanceSuccess && <Modal id="model-popup-dialog" show={true}>
        <section className="confirm-modal">
          <span className="flex-1">{ /* i18n.t('REPORT_CO2-REPORT_POPUP-CONFIRM_LABEL') */}</span>
          <div className="flex-1">
            <span>- {financeState.import.data?.update} {i18n.t('REPORT_CO2-REPORT_POPUP-SUCCESS-MESSAGE-INSERT-2_TEXT')}</span>
          </div>
          <div className="bottom">
            <OkButton data-testid="success-button" onClick={handleSuccessClose} />
          </div>
        </section>
      </Modal>}
      {showFinance && <Modal id="model-popup-dialog" show={true}>
        <section className="confirm-modal">
          <span className="flex-1">{i18n.t('REPORT_CO2-REPORT_POPUP-CONFIRM_LABEL')}</span>
          <div className="flex-1">
            <span>- {financeState.check.success.update} {i18n.t('REPORT_CO2-REPORT_POPUP-RECORDS-MESSAGE-INSERT-2_TEXT')}</span>
          </div>
          <div className="bottom">
            <YesButton data-testid="yes-button" onClick={submitImportFinance} />
            <NoButton data-testid="no-button" onClick={handleCloseFinance} />
          </div>
        </section>
      </Modal>}
      {!!financeState.check.error.fieldName && <ModalError
        title={i18n.t('REPORT_CO2-REPORT_POPUP-WARNING1_LABEL')}
        show={true}
        testId='fieldname-modal'
        onOk={setRandomString}
      />}
      {!!financeState.check.error.unknown && <ModalError
        title={i18n.t('COMMON_UNKNOWN-ERROR_MESSAGE_LABEL')}
        value=""
        show={true}
        testId='unknown-modal'
        onOk={setRandomString}
      />}
      {!!financeState.check.error.csvError && <ModalError
        title={i18n.t('REPORT_CO2-REPORT_POPUP-WARNING7_LABEL')}
        show={true}
        testId='csverror-modal'
        onOk={setRandomString}
      />}
    </ImportFinanceWrap>
  )
}

export default ImportFinance
ImportFinance.propTypes = {
  children: PropTypes.node
}
