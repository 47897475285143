import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'
import { getForkliftContractApi, getForkliftContractDetailApi } from './forkliftContractAPI'
const initialState = {
  errors: [],
  data: [
    { period: '1', inspection: '', lease: '', mandatoryInsurance: '', annualInspection: '', voluntaryInsurance: '', rental: '' },
    { period: '2', inspection: '', lease: '', mandatoryInsurance: '', annualInspection: '', voluntaryInsurance: '', rental: '' },
    { period: '3', inspection: '', lease: '', mandatoryInsurance: '', annualInspection: '', voluntaryInsurance: '', rental: '' },
    { period: '4', inspection: '', lease: '', mandatoryInsurance: '', annualInspection: '', voluntaryInsurance: '', rental: '' },
    { period: '5', inspection: '', lease: '', mandatoryInsurance: '', annualInspection: '', voluntaryInsurance: '', rental: '' },
    { period: '6', inspection: '', lease: '', mandatoryInsurance: '', annualInspection: '', voluntaryInsurance: '', rental: '' },
    { period: '7', inspection: '', lease: '', mandatoryInsurance: '', annualInspection: '', voluntaryInsurance: '', rental: '' },
    { period: '8', inspection: '', lease: '', mandatoryInsurance: '', annualInspection: '', voluntaryInsurance: '', rental: '' },
    { period: '9', inspection: '', lease: '', mandatoryInsurance: '', annualInspection: '', voluntaryInsurance: '', rental: '' },
    { period: '10', inspection: '', lease: '', mandatoryInsurance: '', annualInspection: '', voluntaryInsurance: '', rental: '' },
    { period: '11', inspection: '', lease: '', mandatoryInsurance: '', annualInspection: '', voluntaryInsurance: '', rental: '' },
    { period: '12', inspection: '', lease: '', mandatoryInsurance: '', annualInspection: '', voluntaryInsurance: '', rental: '' }
  ],
  load: false,
  detail: {}
}

export const getForkliftContract = createAsyncThunk(
  'forkliftContract/get',
  async ({ body }, { rejectWithValue }) => {
    try {
      const response = await getForkliftContractApi(body)
      return response.data
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const getForkliftContractDetail = createAsyncThunk(
  'forkliftContract/detail',
  async ({ body }, { rejectWithValue }) => {
    try {
      const response = await getForkliftContractDetailApi(body)
      return response.data
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const forkliftContractSlice = createSlice({
  name: 'forkliftContract',
  initialState,
  reducers: {
    resetState: (state) => {
      state.data = [
        { period: '1', inspection: '', lease: '', mandatoryInsurance: '', annualInspection: '', voluntaryInsurance: '', rental: '' },
        { period: '2', inspection: '', lease: '', mandatoryInsurance: '', annualInspection: '', voluntaryInsurance: '', rental: '' },
        { period: '3', inspection: '', lease: '', mandatoryInsurance: '', annualInspection: '', voluntaryInsurance: '', rental: '' },
        { period: '4', inspection: '', lease: '', mandatoryInsurance: '', annualInspection: '', voluntaryInsurance: '', rental: '' },
        { period: '5', inspection: '', lease: '', mandatoryInsurance: '', annualInspection: '', voluntaryInsurance: '', rental: '' },
        { period: '6', inspection: '', lease: '', mandatoryInsurance: '', annualInspection: '', voluntaryInsurance: '', rental: '' },
        { period: '7', inspection: '', lease: '', mandatoryInsurance: '', annualInspection: '', voluntaryInsurance: '', rental: '' },
        { period: '8', inspection: '', lease: '', mandatoryInsurance: '', annualInspection: '', voluntaryInsurance: '', rental: '' },
        { period: '9', inspection: '', lease: '', mandatoryInsurance: '', annualInspection: '', voluntaryInsurance: '', rental: '' },
        { period: '10', inspection: '', lease: '', mandatoryInsurance: '', annualInspection: '', voluntaryInsurance: '', rental: '' },
        { period: '11', inspection: '', lease: '', mandatoryInsurance: '', annualInspection: '', voluntaryInsurance: '', rental: '' },
        { period: '12', inspection: '', lease: '', mandatoryInsurance: '', annualInspection: '', voluntaryInsurance: '', rental: '' }
      ]
      state.load = false
      state.detail = {}
      return state
    }
  },
  extraReducers: {
    [getForkliftContract.fulfilled]: (state, { payload }) => {
      state.load = false
      state.data = payload
      return state
    },
    [getForkliftContract.rejected]: (state, { payload }) => {
      state.load = false
      state.data = []
      state.errors = payload.errors
    },
    [getForkliftContract.pending]: (state) => {
      state.load = true
    },
    [getForkliftContractDetail.fulfilled]: (state, { payload }) => {
      state.detail = payload
      return state
    },
    [getForkliftContractDetail.rejected]: (state, { payload }) => {
      state.errors = payload.errors
    },
    [getForkliftContractDetail.pending]: (state) => {
      state.detail = {}
    }
  }
})

export const { resetState } = forkliftContractSlice.actions

export default forkliftContractSlice.reducer
