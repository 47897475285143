
export const advancedSearchOptions = [
  { value: 'organizationIdUpdatedBy', label: 'VEHICLE_COMMON_ORGANIZATION-ID-UPDATED-BY_DROPDOWN', type: 'text', maxLength: '255' },
  { value: 'registrationDateUpdatedBy', label: 'VEHICLE_COMMON_REGISTRATION-DATE-UPDATED-BY_DROPDOWN', type: 'text', maxLength: '255' },
  { value: 'statusUpdatedBy', label: 'VEHICLE_COMMON_STATUS-UPDATED-BY_DROPDOWN', type: 'text', maxLength: '255' },
  { value: 'userAddressUpdatedBy', label: 'VEHICLE_COMMON_USER-ADDRESS-UPDATED-BY_DROPDOWN', type: 'text', maxLength: '255' },
  { value: 'mileageUpdatedBy', label: 'VEHICLE_COMMON_MILEAGE-UPDATED-BY_DROPDOWN', type: 'text', maxLength: '255' },
  { value: 'document1UpdatedBy', label: 'VEHICLE_COMMON_DOCUMENT1-UPDATED-BY_DROPDOWN', type: 'text', maxLength: '255' },
  { value: 'document3UpdatedBy', label: 'VEHICLE_COMMON_DOCUMENT3-UPDATED-BY_DROPDOWN', type: 'text', maxLength: '255' },
  { value: 'batteryReplacementDateUpdatedBy', label: 'VEHICLE_COMMON_BATTERY-REPLACEMENT-DATE-UPDATED-BY_DROPDOWN', type: 'text', maxLength: '255' },
  { value: 'snowTireUpdatedBy', label: 'VEHICLE_COMMON_SNOW-TIRE-UPDATED-BY_DROPDOWN', type: 'text', maxLength: '255' },
  { value: 'tireChainUpdatedBy', label: 'VEHICLE_COMMON_TIRE-CHAIN-UPDATED-BY_DROPDOWN', type: 'text', maxLength: '255' }
]

export const forkliftAdvancedSearchOptions = [
  { value: 'organizationIdUpdatedBy', label: 'VEHICLE_COMMON_ORGANIZATION-ID-UPDATED-BY_DROPDOWN', type: 'text', maxLength: '255' },
  { value: 'statusUpdatedBy', label: 'VEHICLE_COMMON_STATUS-UPDATED-BY_DROPDOWN', type: 'text', maxLength: '255' },
  { value: 'userAddressUpdatedBy', label: 'VEHICLE_COMMON_USER-ADDRESS-UPDATED-BY_DROPDOWN', type: 'text', maxLength: '255' },
  { value: 'document1UpdatedBy', label: 'VEHICLE_COMMON_DOCUMENT1-UPDATED-BY_DROPDOWN', type: 'text', maxLength: '255' },
  { value: 'document3UpdatedBy', label: 'VEHICLE_COMMON_DOCUMENT3-UPDATED-BY_DROPDOWN', type: 'text', maxLength: '255' }
]
