import axios from 'axios'
import { API_ENDPOINT } from '../../tools/apiEndpoint'

export const importForkliftFinanceApi = async ({ exec, file }) => {
  const formData = new FormData()
  formData.append('file', file)
  const URL = `${API_ENDPOINT}/forkliftFinances/import`
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    params: { exec }
  }

  const response = await axios.post(
    URL,
    null || formData,
    config
  )

  return response
}
