import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'
import { VehicleWrapper } from './styles'
import Table from '../../components/Table'
import ImportConsumption from '../../components/ImportConsumption'
import { getReportList, clearState } from './../../features/reports/reportSlice'
import { getReportCSVApi } from '../../features/reports/reportAPI'
import { getOrganizationList } from '../../features/organizations/organizationSlice'
import { consumptionColumnMap } from '../../components/Table/columnNameMap'
import MultiSelect from '../../components/MultiSelect'

import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import { withStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import Modal from '../../components/Modal'
import { SearchButton, ExportButton } from '../../components/Button'
import GlobAttr from './../GlobalStyles'
import dayjs from 'dayjs'
import i18n from 'i18next'
import { userInfo } from './../../features/users/userInfoSlice'
import YearClick from '../../components/YearClick'
import { useMounted } from '../../tools'
import Pagination from '../../components/Pagination'

const PER_PAGE = 20
const stickyWidth = {
  licensePlateNumber: 129,
  'organization.company': 75,
  'organization.branch': 100,
  status: 70,
  code: 100,
  type: 100,
  model: 56,
  grossVehicleWeight: 158
}
function VehicleReport () {
  const dispatch = useDispatch()
  const isMounted = useMounted()

  // const [selectedTab, setTab] = React.useState(4)
  const [sortOrder, setSortOrder] = React.useState()
  const [page, setPage] = React.useState(1)
  const [company, setCompany] = React.useState('')
  const [branchs, setBranchs] = React.useState([])
  const [searchBody, setSearchBody] = React.useState({})
  const [resetSwitch, setResetSwitch] = React.useState(false)
  const [aprilSearch, setAprilSearch] = React.useState(false)
  const reportState = useSelector(state => state.reports)
  const organizationState = useSelector(state => state.organization)
  const [isLoading, setIsLoading] = React.useState(false)
  const [value, setValue] = React.useState('JAN')
  const userState = useSelector(state => state.userInfo)

  // eslint-disable-next-line
  const [year, setUpdateYear] = React.useState(dayjs().format('YYYY'))
  const PinkRadio = withStyles({
    root: {
      color: GlobAttr.primeColor,
      '&$checked': {
        color: GlobAttr.primeColor
      }
    },
    checked: {}
  })((props) => <Radio color="default" {...props} />)

  React.useEffect(() => {
    // Get master data
    dispatch(getOrganizationList())
    dispatch(userInfo())
    dispatch(clearState())
  }, [])

  React.useEffect(() => {
    if (isMounted) {
      if (sortOrder) {
        dispatch(getReportList({ page, perPage: PER_PAGE, sort: { column: sortOrder.column, by: sortOrder.by }, body: searchBody }))
      } else {
        dispatch(getReportList({ page, perPage: PER_PAGE, body: searchBody }))
      }
    }
  }, [page])

  const onSearchSubmit = (iYear) => {
    setPage(1)
    const body = buildSearchBody(iYear)
    setSearchBody(body)
    setAprilSearch(value === 'APR')
    setResetSwitch(!resetSwitch)
    dispatch(getReportList({ page: 1, perPage: PER_PAGE, body }))
  }

  const onExport = async () => {
    setIsLoading(true)
    const response = await getReportCSVApi({ body: searchBody })
    const bom = new Uint8Array([0xEF, 0xBB, 0xBF])
    const blob = new Blob([bom, response.data], { type: response.headers['content-type'] })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = i18n.t('REPORT_CO2-REPORT-EXPORT_DEFAULT-EXPORT-FILE-NAME_LABEL')
    link.click()
    link.remove()
    setIsLoading(false)
  }

  const getOrganizationId = () => {
    return branchs
  }

  const getPeriod = (gYear) => {
    const selectedYear = parseInt(gYear)
    switch (value) {
      case 'JAN':
        return [parseInt(`${selectedYear}01`), parseInt(`${selectedYear}12`)]
      case 'APR':
        return [parseInt(`${selectedYear}04`), parseInt(`${selectedYear + 1}03`)]
    }
  }

  const buildSearchBody = bYear => {
    const body = {}
    const ids = getOrganizationId()
    if (ids.length > 0) body.organizationId = { in: ids }
    body.period = { between: getPeriod(bYear) }
    return body
  }

  const sortHandle = (column, by) => {
    setSortOrder(by === 'def' ? null : { column, by })
    const searchCondition = { page, perPage: PER_PAGE, body: searchBody }
    if (by !== 'def') {
      searchCondition.sort = { column, by }
    }
    dispatch(getReportList(searchCondition))
  }

  const handlePageChange = (pagePag) => {
    setPage(pagePag)
  }

  const getCompanyOptions = () => {
    let companyOptions = [...new Set(organizationState.list.map(item => item.company))]
    companyOptions = companyOptions.map(item => { return { key: item, value: item } })
    return [{ key: '', value: i18n.t('COMMON_COMMON_DROPDOWN-OPTION-ALL_LABEL') }, ...companyOptions]
  }

  const handleCompanyChange = (e) => {
    if (e) { setCompany(e[0].key) }
  }

  const handleBranchChange = (e) => {
    setBranchs(e.map(item => item.id))
  }

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  const updateSelectionYear = year => {
    setUpdateYear(year)
    if (Object.keys(searchBody).length) {
      onSearchSubmit(year)
    }
  }
  const pageCount = Math.ceil(reportState.itemCount / PER_PAGE)
  const countToPage = page * PER_PAGE < reportState.itemCount ? page * PER_PAGE : reportState.itemCount
  const startItem = reportState.itemCount > 0 ? 1 + ((page - 1) * PER_PAGE) : 0
  return (
    <VehicleWrapper>
      <section className="header">
        <div className="header-title">{i18n.t('REPORT_CO2-REPORT_PAGE-HEADER_LABEL')} </div>
      </section>
      <section className="header">
        <div className="header-actions">
          <div className="action-options reduce-flex-size">
            <label>{i18n.t('COMMON_COMMON_COMPANY-DROPDOWN_LABEL')}</label>
            <MultiSelect
              testId="dropdown-company"
              placeholder={i18n.t('COMMON_COMMON_DROPDOWN-PLEASE-SELECT_LABEL')}
              options={getCompanyOptions()}
              valueField="key"
              labelField="value"
              onChange={handleCompanyChange}
              defaultValue=""
            />
          </div>
          <div className="action-options reduce-flex-size">
            <label>{i18n.t('COMMON_COMMON_BRANCH-DROPDOWN_LABEL')}</label>
            <MultiSelect
              testId="dropdown-branch"
              disabled={!company}
              placeholder={i18n.t('COMMON_COMMON_DROPDOWN-PLEASE-SELECT_LABEL')}
              multi={true}
              allItem={true}
              options={organizationState.list.filter(item => item.company === company)}
              valueField="id"
              labelField="branch"
              onChange={handleBranchChange}
            />
          </div>
          <div className="action-options">
            <FormControl component="fieldset" color="primary">
              <FormLabel component="legend">{i18n.t('REPORT_CO2-REPORT_START-MONTH_LABEL')}</FormLabel>
              <RadioGroup row aria-label="position" value={value} onChange={handleChange} name="position" defaultValue="top">
                <FormControlLabel value="JAN" control={<PinkRadio />} label={i18n.t('COMMON_COMMON_MONTH-JAN_LABEL')} />
                <FormControlLabel data-testid="radio-apr" value="APR" control={<PinkRadio />} label={i18n.t('COMMON_COMMON_MONTH-APR_LABEL')} />
              </RadioGroup>
            </FormControl>
          </div>
          {/* <div className="action-options"></div> */}
          <div className="action-options set-flex-width"></div>
          <div className="action-options" style={{ alignItems: 'flex-end', marginRight: '24px' }}>
            <div className="submit-container">
              <SearchButton data-testid="submit-button" tabIndex={0} type="submit" onClick={() => onSearchSubmit(year)} />
            </div>
          </div>
        </div>
      </section>

      <section className="list">
        <div className="list-action-wrapper">
          <div className="year-selection">
            <YearClick onChange={updateSelectionYear} year={year}></YearClick>
          </div>
          {
            (userState.isEdit || (userState.isAdmin && userState.isEdit))
              ? <ImportConsumption>
                <FontAwesomeIcon icon={faFileExcel} />{i18n.t('REPORT_CO2-REPORT_IMPORT-CSV-BUTTON_BUTTON')}
              </ImportConsumption>
              : ''
          }
          <ExportButton data-testid="export" onClick={onExport} {...reportState.itemCount <= 0 && { disabled: true }} />
          <span>{i18n.t('COMMON_COMMON_PAGINATION-DISPLAY_LABEL', { page: startItem, countToPage, total: reportState.itemCount })}</span>
          <Pagination page={page} count={pageCount} onChange={handlePageChange} />
        </div>
        <Table
          borderCollapse={'collapse'}
          data={reportState.list}
          stickyWidthPass={stickyWidth}
          groups={aprilSearch ? consumptionColumnMap.consumptionGroupApr : consumptionColumnMap.consumptionGroupJan}
          columnName={aprilSearch ? consumptionColumnMap.consumptionReportTabApr : consumptionColumnMap.consumptionReportTabJan}
          sortHandle={sortHandle}
          resetSwitch={resetSwitch}
        />
        <Modal show={isLoading || reportState.load}><CircularProgress></CircularProgress></Modal>
      </section>

    </VehicleWrapper>)
}

export default VehicleReport
