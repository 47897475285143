import React from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { FilterTabWrapper } from './styles'
import i18n from 'i18next'
import { SearchButton } from '../Button'
import { integerNumberOnly } from './advanceSearchOptions'
import { forkliftAdvancedSearchOptions } from './Defines/updateHistoryTabDefine'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'

const fieldNames = {
  code: {
    // 製造/車台番号
    name: 'code',
    type: 'text',
    label: 'FORKLIFT_COMMON_CODE_LABEL',
    operator: 'ilike',
    queryName: 'code',
    maxLength: 255,
    tabIndexes: [1]
  },
  'forklifthistory.organizationIdUpdatedAt': {
    label: 'VEHICLE_COMMON_ORGANIZATION-ID-UPDATED-AT_LABEL',
    name: 'forklifthistory.organizationIdUpdatedAt',
    type: 'date',
    tabIndexes: [2, 3]
  },
  'forklifthistory.statusUpdatedAt': {
    label: 'VEHICLE_COMMON_STATUS-UPDATED-AT_LABEL',
    name: 'forklifthistory.statusUpdatedAt',
    type: 'date',
    tabIndexes: [4, 5]
  },
  'forklifthistory.userAddressUpdatedAt': {
    label: 'VEHICLE_COMMON_USER-ADDRESS-UPDATED-AT_LABEL',
    name: 'forklifthistory.userAddressUpdatedAt',
    type: 'date',
    tabIndexes: [6, 7]
  },
  'forklifthistory.document1UpdatedAt': {
    label: 'VEHICLE_COMMON_DOCUMENT1-UPDATED-AT_LABEL',
    name: 'forklifthistory.document1UpdatedAt',
    type: 'date',
    tabIndexes: [8, 9]
  },
  'forklifthistory.document3UpdatedAt': {
    label: 'VEHICLE_COMMON_DOCUMENT3-UPDATED-AT_LABEL',
    name: 'forklifthistory.document3UpdatedAt',
    type: 'date',
    tabIndexes: [10, 11]
  }
}

function UpdateHistoryTab (props) {
  const { onSearchSubmit } = props
  const { register, handleSubmit, formState: { errors }, setError, getValues, setValue, clearErrors, watch, unregister } = useForm()
  const [errorFields, setErrorFields] = React.useState([])

  const watchAdvancedSelected = watch([
    'advanceSearchLabel_1',
    'advanceSearchLabel_2',
    'advanceSearchLabel_3'
  ])

  const prepareSearchBody = data => {
    const body = {}
    body.onSubmit = 'onSubmit'
    for (const key of Object.keys(fieldNames)) {
      const fieldName = fieldNames[key].name
      const type = fieldNames[key].type
      const column = fieldName.split('.')[1]
      switch (type) {
        case 'text':
          if (data[fieldName].length > 0) body[fieldName] = { ilike: `%${data[fieldName]}%` }
          break
        case 'date':
          if (data.forklifthistory[column] && data.forklifthistory[column].from && data.forklifthistory[column].to) {
            body[`${column}`] = { between: [data.forklifthistory[column].from, data.forklifthistory[column].to] }
          } else if (data.forklifthistory[column] && data.forklifthistory[column].from && !data.forklifthistory[column].to) {
            body[`${column}`] = { gte: data.forklifthistory[column].from }
          } else if (data.forklifthistory[column] && !data.forklifthistory[column].from && data.forklifthistory[column].to) {
            body[`${column}`] = { lte: data.forklifthistory[column].to }
          }
          break
      }
    }

    for (const key of Object.keys(data)) {
      const [label, searchNumber, optionName] = key.split('_')
      if (label !== 'advanceSearchInput') continue
      if (searchNumber && optionName && data[`advanceSearchLabel_${searchNumber}`] !== optionName) {
        unregister(key)
        continue
      }
      if (data[key].length > 0) body[`${optionName}`] = { ilike: `%${data[key]}%` }
    }

    return body
  }

  const onSubmit = data => {
    const body = prepareSearchBody(data)
    onSearchSubmit(body)
  }

  const onDateBlur = field => () => {
    const [table, column] = field.split('.')
    clearErrors([`${table}.${column}.from`, `${table}.${column}.to`])
    const index = errorFields.indexOf(`${table}.${column}.from`)
    if (index > -1) {
      errorFields.splice(index, 1)
    }
    const from = Date.parse(getValues(`${table}.${column}.from`))
    const to = Date.parse(getValues(`${table}.${column}.to`))
    if (from > to && !Number.isNaN(from) && !Number.isNaN(to)) {
      setError(`${table}.${column}.from`, {
        type: 'manual',
        message: i18n.t('VEHICLE_VEHICLE-LIST_POPUP-WARNING2_LABEL')
      })
      setErrorFields(prevState => [...prevState, `${table}.${column}.from`])
    }
  }

  const getSelectedStatus = (formKey, value) => {
    const curSelected = getValues(formKey)

    const selectedItems = [
      getValues('advanceSearchLabel_1'),
      getValues('advanceSearchLabel_2'),
      getValues('advanceSearchLabel_3')
    ]

    if (value !== curSelected && selectedItems.includes(value)) {
      return true
    }
    return false
  }
  const renderErrorText = field => {
    let errorMessage = ''
    if (errors[field]) {
      errorMessage = errors[field].message
    }
    const column = field.split('.')[1]
    if (errors.forklifthistory && errors.forklifthistory[column]) {
      errorMessage = errors.forklifthistory[column].from.message
    }
    return errorMessage ? <p data-testid={`${field}-error-text`} className="error-text">{`${errorMessage}`}</p> : ''
  }

  const renderInput = options => {
    let component = ''
    switch (options.type) {
      case 'text':
        component = (
          <input
            className="input-item"
            type={options.type}
            tabIndex={options.tabIndexes[0]}
            maxLength={options.maxLength}
            data-testid={options.name}
            {...register(options.name)} />
        )
        break
      case 'date':
        component = (
          <>
            <input
              {...!integerNumberOnly.includes(options.name) && { step: 'any' }}
              className="input-item"
              type={options.type}
              tabIndex={options.tabIndexes[0]}
              placeholder="From"
              {...(options.type === 'date' && { min: '1890-01-01' })}
              {...(options.type !== 'month' && { max: watch(`${options.name}.to`) || '9999-12-31' })}
              data-testid={`${options.name}.from`}
              {...register(`${options.name}.from`)} />
            <input
              {...!integerNumberOnly.includes(options.name) && { step: 'any' }}
              className="input-item"
              type={options.type}
              tabIndex={options.tabIndexes[1]}
              placeholder="To"
              min={getValues(`${options.name}.from`) || '1890-01-01'}
              {...(options.type === 'date' && { max: '9999-12-31' })}
              data-testid={`${options.name}.to`}
              {...register(`${options.name}.to`)} />
          </>
        )
        break
    }
    return component
  }

  const renderInputGroup = options => {
    let onBlur = null
    if (options.type === 'date') onBlur = onDateBlur(options.name)
    return (
      <>
        <div className="label-text"><label>{i18n.t(options.label)}</label></div>
        <div>
          <div data-testid={`${options.name}-input-group`} className="input-group" onBlur={onBlur}>{renderInput(options)}</div>
          {renderErrorText(options.name)}
        </div>
      </>
    )
  }

  const renderAdvancedSearchInput = (formKey, num) => {
    const advanceSearchValue = getValues(formKey)
    const index = forkliftAdvancedSearchOptions.map((e) => e.value).indexOf(advanceSearchValue)
    if (!advanceSearchValue || advanceSearchValue === '1') return
    const advanceSearchItem = forkliftAdvancedSearchOptions[index]
    const dataType = 'text'
    // Advanced search fields only have dropdown type
    return (
      <input tabIndex={23 + num + 1}
      placeholder=''
      type={dataType}
      maxLength={advanceSearchItem.maxLength}
      {...register(`advanceSearchInput_${num}_${advanceSearchValue}`)}
      data-testid={`advanceSearchInput_${num}_${advanceSearchValue}`}
      className="input-item"
      />)
  }

  return (
    <FilterTabWrapper>
      <form className="form-container" onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="filter-container">
          <div className="input-container">
            {renderInputGroup(fieldNames.code)}
            {renderInputGroup(fieldNames['forklifthistory.userAddressUpdatedAt'])}
          </div>
          <div className="input-container">
            {renderInputGroup(fieldNames['forklifthistory.organizationIdUpdatedAt'])}
            {renderInputGroup(fieldNames['forklifthistory.document1UpdatedAt'])}
          </div>
          <div className="input-container">
            {renderInputGroup(fieldNames['forklifthistory.statusUpdatedAt'])}
            {renderInputGroup(fieldNames['forklifthistory.document3UpdatedAt'])}
          </div>
          <div className="filter-container" style={{ marginBottom: '8px' }}>
            <div className="label-text">
              <label>{i18n.t('VEHICLE_VEHICLE-HISTORY-LIST_ADVANCE-SEARCH_LABEL')}</label>
            </div>
          </div>
        </div>
          <div className="filter-container">
            {[1, 2, 3].map((e, i) => {
              return (
                <div key={e} className="advanced-input-container">
                  <div className="input-group">
                    {!watch(`advanceSearch_${e}`) && e !== 1 &&
                      <AddCircleOutlineIcon
                        style={{ alignSelf: 'center' }}
                        fontSize="large"
                        className="btn-add"
                        data-testid={`plus-icon-${e}`}
                        {...register(`advanceSearch_${e}`)}
                        {...watch(`advanceSearch_${e}`, false)}
                        onClick={() => setValue(`advanceSearch_${e}`, true)}
                      />
                    }
                    {((watch(`advanceSearch_${e}`) && watchAdvancedSelected) || e === 1) &&
                      <select defaultValue="" data-testid={`advanceSearchLabel_${e}`} className={`input-item item_${getValues(`advanceSearchLabel_${e}`)}`} style={{ marginLeft: '0' }} {...register(`advanceSearchLabel_${e}`)}
                      tabIndex={23 + e}>
                        <option value="1">{i18n.t('COMMON_COMMON_DROPDOWN-PLEASE-SELECT_LABEL')}</option>
                        {forkliftAdvancedSearchOptions.map(({ value, label }, index) => {
                          return <option value={value} key={index} disabled={getSelectedStatus(`advanceSearchLabel_${e}`, value)}>{i18n.t(label)}</option>
                        })}
                      </select>
                    }
                  </div>
                  <div className="input-group">
                    {watch(`advanceSearchLabel_${e}`) && renderAdvancedSearchInput(`advanceSearchLabel_${e}`, e)}
                  </div>
                </div>
              )
            })}
          </div>
          <div className="submit-container">
            <SearchButton data-testid="search-button" tabIndex={31} type="submit" {...errorFields.length > 0 && { disabled: true }}/>
          </div>
      </form>
    </FilterTabWrapper>
  )
}

export default UpdateHistoryTab

UpdateHistoryTab.propTypes = {
  onSearchSubmit: PropTypes.func,
  defaultValue: PropTypes.object
}
