import axios from 'axios'
import { API_ENDPOINT } from '../../tools/apiEndpoint'

export const dropDownCategoryListApi = async () => {
  const URL = `${API_ENDPOINT}/dropdown/categorySearch`
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  }

  const response = await axios.get(
    URL,
    config
  )

  return response
}

export const dropDownContentListApi = async ({ categoryId }) => {
  const URL = `${API_ENDPOINT}/dropdown/contentSearch/${categoryId}`

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  }

  const response = await axios.get(
    URL,
    config
  )

  return response
}

export const editDropDownApi = async ({ body }) => {
  const URL = `${API_ENDPOINT}/dropdown/edit`
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  }

  const response = await axios.patch(
    URL,
    body,
    config
  )

  return response
}

export const getDropDownIdApi = async ({ categoryId, name }) => {
  const URL = `${API_ENDPOINT}/dropdown/idSearch`

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  }

  const bodyParameters = { categoryId, name }
  return (await axios.post(
    URL,
    null || bodyParameters,
    config
  ))
}

export const checkDropdownDeleteApi = async ({ categoryId, contentId }) => {
  const URL = `${API_ENDPOINT}/dropdown/checkDropdownDelete`

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  }

  const bodyParameters = { categoryId, contentId }
  return (await axios.post(
    URL,
    null || bodyParameters,
    config
  ))
}
