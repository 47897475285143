import { fileCategory } from '../../../common/const'

export const generalInformationMap = {
  company: 'VEHICLE_COMMON_COMPANY_LABEL',
  department: 'VEHICLE_COMMON_DEPARTMENT_LABEL',
  businessUnit: 'VEHICLE_COMMON_BUSINESS-UNIT_LABEL',
  branchOffice: 'VEHICLE_COMMON_BRANCH-OFFICE_LABEL',
  branch: 'VEHICLE_COMMON_BRANCH_LABEL',
  licensePlateNumber: 'FORKLIFT_COMMON_LICENSE-PLATE_LABEL',
  carNumber: 'FORKLIFT_COMMON_CARNUMBER_LABEL',
  code: 'FORKLIFT_COMMON_CODE_LABEL',
  manufacturer_name: 'FORKLIFT_COMMON_MANUFACTURER_LABEL',
  bodyShape_name: 'FORKLIFT_COMMON_BODY-SHAPE_LABEL',
  model: 'FORKLIFT_COMMON_MODEL_LABEL',
  power_name: 'FORKLIFT_COMMON_POWER_LABEL',
  batteryVoltage_name: 'FORKLIFT_COMMON_BATTERYVOLTAGE_LABEL',
  status_name: 'FORKLIFT_COMMON_STATUS_LABEL'
}

export const registrationInformationMap = {
  owner_name: 'FORKLIFT_COMMON_OWNER_LABEL',
  user_name: 'FORKLIFT_COMMON_USER_LABEL',
  userAddress: 'FORKLIFT_COMMON_USERADDRESS_LABEL',
  purchaseDate: 'FORKLIFT_COMMON_PURCHASEDATE_LABEL',
  expensesIncludingLease_name: 'FORKLIFT_COMMON_EXPENSESINCLUDINGLEASE_LABEL',
  leaseEndDate: 'FORKLIFT_COMMON_LEASEENDDATE_LABEL',
  leaseFee: 'FORKLIFT_COMMON_LEASEFEE_LABEL',
  automobileTax: 'FORKLIFT_COMMON_AUTOMOBILETAX_LABEL',
  weightTax: 'FORKLIFT_COMMON_WEIGHTTAX_LABEL',
  inspectionExpireDate: 'FORKLIFT_COMMON_INSPECTIONEXPIREDATE_LABEL',
  annualInspectionDate: 'FORKLIFT_COMMON_ANNUALINSPECTIONDATE_LABEL',
  holdingFormat_name: 'FORKLIFT_COMMON_HOLDINGFORMAT_LABEL',
  mandatoryInsuranceEndDate: 'FORKLIFT_COMMON_MANDATORYINSURANCEENDDATE_LABEL',
  voluntaryInsuranceType_name: 'FORKLIFT_COMMON_VOLUNTARYINSURANCETYPE_LABEL',
  voluntaryInsuranceEndDate: 'FORKLIFT_COMMON_VOLUNTARYINSURANCEENDDATE_LABEL',
  rentalEndDate: 'FORKLIFT_COMMON_RENTALENDDATE_LABEL',
  rentalFee: 'FORKLIFT_COMMON_RENTALFEE_LABEL'
}

export const weightSizeInformationMap = {
  vehicleWeight: 'FORKLIFT_COMMON_VEHICLEWEIGHT_LABEL',
  maximumLiftHeight: 'FORKLIFT_COMMON_MAXIMUMLIFTHEIGHT_LABEL',
  maximumLoad: 'FORKLIFT_COMMON_MAXIMUMLOAD_LABEL',
  clawLength: 'FORKLIFT_COMMON_CLAWLENGTH_LABEL'
}

export const detailedVehicleInformationMap = {
  dashcamManufacturer_name: 'FORKLIFT_COMMON_DASHCAMMANUFACTURER_LABEL',
  rearLightingManufacturer_name: 'FORKLIFT_COMMON_REARLIGHTINGMANUFACTURER_LABEL',
  horizontalLaserManufacturer_name: 'FORKLIFT_COMMON_HORIZONTALLASERMANUFACTURER_LABEL',
  sensorsManufacturer_name: 'FORKLIFT_COMMON_SENSORSMANUFACTURER_LABEL',
  attachment1_name: 'FORKLIFT_COMMON_ATTACHMENT1_LABEL',
  attachment2_name: 'FORKLIFT_COMMON_ATTACHMENT2_LABEL',
  attachment3_name: 'FORKLIFT_COMMON_ATTACHMENT3_LABEL',
  attachment4_name: 'FORKLIFT_COMMON_ATTACHMENT4_LABEL'
}

export const fileTypeList = {
  Syaken: fileCategory.Syaken,
  Zibaiseki: fileCategory.Zibaiseki,
  Syaryou: fileCategory.Syaryou,
  NenziTenkin: fileCategory.NenziTenkin,
  KounyuziKeiyaku: fileCategory.KounyuziKeiyaku,
  Rental: fileCategory.Rental,
  NiniHoken: fileCategory.NiniHoken
}
