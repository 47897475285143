import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'
import { getContractReportApi, getContractDetailApi } from './contractAPI'
const initialState = {
  errors: [],
  data: [
    { period: '1', inspection: '', lease: '', regularMaintenance: '', mandatoryInsurance: '', specialVehiclePass: '', voluntaryInsurance: '' },
    { period: '2', inspection: '', lease: '', regularMaintenance: '', mandatoryInsurance: '', specialVehiclePass: '', voluntaryInsurance: '' },
    { period: '3', inspection: '', lease: '', regularMaintenance: '', mandatoryInsurance: '', specialVehiclePass: '', voluntaryInsurance: '' },
    { period: '4', inspection: '', lease: '', regularMaintenance: '', mandatoryInsurance: '', specialVehiclePass: '', voluntaryInsurance: '' },
    { period: '5', inspection: '', lease: '', regularMaintenance: '', mandatoryInsurance: '', specialVehiclePass: '', voluntaryInsurance: '' },
    { period: '6', inspection: '', lease: '', regularMaintenance: '', mandatoryInsurance: '', specialVehiclePass: '', voluntaryInsurance: '' },
    { period: '7', inspection: '', lease: '', regularMaintenance: '', mandatoryInsurance: '', specialVehiclePass: '', voluntaryInsurance: '' },
    { period: '8', inspection: '', lease: '', regularMaintenance: '', mandatoryInsurance: '', specialVehiclePass: '', voluntaryInsurance: '' },
    { period: '9', inspection: '', lease: '', regularMaintenance: '', mandatoryInsurance: '', specialVehiclePass: '', voluntaryInsurance: '' },
    { period: '10', inspection: '', lease: '', regularMaintenance: '', mandatoryInsurance: '', specialVehiclePass: '', voluntaryInsurance: '' },
    { period: '11', inspection: '', lease: '', regularMaintenance: '', mandatoryInsurance: '', specialVehiclePass: '', voluntaryInsurance: '' },
    { period: '12', inspection: '', lease: '', regularMaintenance: '', mandatoryInsurance: '', specialVehiclePass: '', voluntaryInsurance: '' }
  ],
  load: false,
  detail: {}
}

export const getContract = createAsyncThunk(
  'contract/get',
  async ({ body }, { rejectWithValue }) => {
    try {
      const response = await getContractReportApi(body)
      return response.data
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const getContractDetail = createAsyncThunk(
  'contract/detail',
  async ({ body }, { rejectWithValue }) => {
    try {
      const response = await getContractDetailApi(body)
      return response.data
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const contractSlice = createSlice({
  name: 'contract',
  initialState,
  reducers: {
    resetState: (state) => {
      state.data = [
        { period: '1', inspection: '', lease: '', regularMaintenance: '', mandatoryInsurance: '', specialVehiclePass: '', voluntaryInsurance: '' },
        { period: '2', inspection: '', lease: '', regularMaintenance: '', mandatoryInsurance: '', specialVehiclePass: '', voluntaryInsurance: '' },
        { period: '3', inspection: '', lease: '', regularMaintenance: '', mandatoryInsurance: '', specialVehiclePass: '', voluntaryInsurance: '' },
        { period: '4', inspection: '', lease: '', regularMaintenance: '', mandatoryInsurance: '', specialVehiclePass: '', voluntaryInsurance: '' },
        { period: '5', inspection: '', lease: '', regularMaintenance: '', mandatoryInsurance: '', specialVehiclePass: '', voluntaryInsurance: '' },
        { period: '6', inspection: '', lease: '', regularMaintenance: '', mandatoryInsurance: '', specialVehiclePass: '', voluntaryInsurance: '' },
        { period: '7', inspection: '', lease: '', regularMaintenance: '', mandatoryInsurance: '', specialVehiclePass: '', voluntaryInsurance: '' },
        { period: '8', inspection: '', lease: '', regularMaintenance: '', mandatoryInsurance: '', specialVehiclePass: '', voluntaryInsurance: '' },
        { period: '9', inspection: '', lease: '', regularMaintenance: '', mandatoryInsurance: '', specialVehiclePass: '', voluntaryInsurance: '' },
        { period: '10', inspection: '', lease: '', regularMaintenance: '', mandatoryInsurance: '', specialVehiclePass: '', voluntaryInsurance: '' },
        { period: '11', inspection: '', lease: '', regularMaintenance: '', mandatoryInsurance: '', specialVehiclePass: '', voluntaryInsurance: '' },
        { period: '12', inspection: '', lease: '', regularMaintenance: '', mandatoryInsurance: '', specialVehiclePass: '', voluntaryInsurance: '' }
      ]
      state.load = false
      state.detail = {}
      return state
    }
  },
  extraReducers: {
    [getContract.fulfilled]: (state, { payload }) => {
      state.load = false
      state.data = payload
      return state
    },
    [getContract.rejected]: (state, { payload }) => {
      state.load = false
      state.data = []
      state.errors = payload.errors
    },
    [getContract.pending]: (state) => {
      state.load = true
      state.data = []
    },
    [getContractDetail.fulfilled]: (state, { payload }) => {
      state.detail = payload
      return state
    },
    [getContractDetail.rejected]: (state, { payload }) => {
      state.errors = payload.errors
    },
    [getContractDetail.pending]: (state) => {
      state.detail = {}
    }
  }
})

export const { resetState } = contractSlice.actions

export default contractSlice.reducer
