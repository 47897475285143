export const generalInformationMap = {
  company: 'VEHICLE_COMMON_COMPANY_LABEL',
  businessUnit: 'VEHICLE_COMMON_BUSINESS-UNIT_LABEL',
  branch: 'VEHICLE_COMMON_BRANCH_LABEL',
  licensePlateNumber: 'VEHICLE_COMMON_LICENSE-PLATE_LABEL',
  carNumber: 'VEHICLE_COMMON_CAR-NUMBER_LABEL',
  pastLicensePlate: 'VEHICLE_COMMON_PAST-LICENSE-PLATE_LABEL',
  model: 'VEHICLE_COMMON_MODEL_LABEL',
  code: 'VEHICLE_COMMON_VEHICLE-ID_LABEL',
  type_name: 'VEHICLE_COMMON_TYPE_LABEL',
  brand_name: 'VEHICLE_COMMON_BRAND_LABEL',
  class_name: 'VEHICLE_COMMON_CLASS_LABEL',
  bodyShape_name: 'VEHICLE_COMMON_BODY-SHAPE_LABEL',
  specialEquipment: 'VEHICLE_COMMON_SPECIAL-EQUIPMENT_LABEL',
  initialRegistrationDate: 'VEHICLE_COMMON_INITIAL-REGISTRATION-YEAR-MONTH_LABEL',
  registrationDate: 'VEHICLE_COMMON_REGISTRATION-DATE_LABEL',
  engineModel: 'VEHICLE_COMMON_ENGINE-MODEL_LABEL',
  totalDisplacement: 'VEHICLE_COMMON_TOTAL-DISPLACEMENT_LABEL',
  fuelType_name: 'VEHICLE_COMMON_FUEL-TYPE_LABEL',
  status_name: 'VEHICLE_COMMON_STATUS_LABEL'

}

export const registrationInformationMap = {
  owner: 'VEHICLE_COMMON_OWNER_LABEL',
  user_name: 'VEHICLE_COMMON_USER_LABEL',
  userAddress: 'VEHICLE_COMMON_USER-ADDRESS_LABEL',
  withLease_name: 'VEHICLE_COMMON_WITH-OR-WITHOUT-LEASE_LABEL',
  expensesIncludingLease_name: 'VEHICLE_COMMON_EXPENSES-INCLUDING-LEASE_LABEL',
  leaseEndDate: 'VEHICLE_COMMON_LEASE-END-DATE_LABEL',
  leaseFee: 'VEHICLE_COMMON_LEASE-FEE_LABEL',
  automobileTax: 'VEHICLE_COMMON_AUTOMOBILE-TAX_LABEL',
  weightTax: 'VEHICLE_COMMON_WEIGHT-TAX_LABEL',
  inspectionExpireDate: 'VEHICLE_COMMON_INSPECTION-EXPIRE-DATE_LABEL',
  mileage: 'VEHICLE_COMMON_MILEAGE_LABEL',
  lastRegularMaintenanceDate: 'VEHICLE_COMMON_LAST-REGULAR-MAINTENANCE-DATE_LABEL',
  regularMaintenanceCycle_name: 'VEHICLE_COMMON_REGULAR-MAINTENANCE-CYCLE_LABEL',
  nextRegularMaintenanceMonth: 'VEHICLE_COMMON_NEXT-REGULAR-MAINTENANCE-MONTH_LABEL',
  mandatoryInsuranceEndDate: 'VEHICLE_COMMON_MANDATORY-INSURANCE-END-DATE_LABEL',
  voluntaryInsuranceType_name: 'VEHICLE_COMMON_VOLUNTARY-INSURANCE-TYPE_LABEL',
  voluntaryInsuranceEndDate: 'VEHICLE_COMMON_VOLUNTARY-INSURANCE-END-DATE_LABEL',
  usage_name: 'VEHICLE_COMMON_USAGE_LABEL',
  privateBusinessUse_name: 'VEHICLE_COMMON_PRIVATE-USE-OR-BUSINESS-USE_LABEL',
  factoryInCharge: 'VEHICLE_COMMON_FACTORY-IN-CHARGE_LABEL',
  specialVehiclePassExpireDate: 'VEHICLE_COMMON_SPECIAL-VEHICLE-PASS-EXPIRE-DATE_LABEL'
}

export const weightSizeInformationMap = {
  seatingCapacity: 'VEHICLE_COMMON_SEATING-CAPACITY_LABEL',
  maxLoadCapacity: 'VEHICLE_COMMON_MAXIMUM-LOAD-CAPACITY_LABEL',
  vehicleWeight: 'VEHICLE_COMMON_VEHICLE-WEIGHT_LABEL',
  grossVehicleWeight: 'VEHICLE_COMMON_GROSS-VEHICLE-WEIGHT_LABEL',
  length: 'VEHICLE_COMMON_LENGTH_LABEL',
  width: 'VEHICLE_COMMON_WIDTH_LABEL',
  height: 'VEHICLE_COMMON_HEIGHT_LABEL',
  frontFrontAxleLoad: 'VEHICLE_COMMON_FF-AXLE-LOAD_LABEL',
  frontRearAxleLoad: 'VEHICLE_COMMON_FR-AXLE-LOAD_LABEL',
  rearFrontAxleLoad: 'VEHICLE_COMMON_RF-AXLE-LOAD_LABEL',
  rearRearAxleLoad: 'VEHICLE_COMMON_RR-AXLE-LOAD_LABEL',
  fifthWheelLoad: 'VEHICLE_COMMON_FIFTH-WHEEL-LOAD_LABEL',
  modelDesignationNumber: 'VEHICLE_COMMON_MODEL-DESIGNATION-NUMBER_LABEL',
  classificationCategoryNumber: 'VEHICLE_COMMON_CLASSIFICATION-CATEGORY-NUMBER_LABEL',
  batteryType: 'VEHICLE_COMMON_BATTERY-TYPE_LABEL',
  batteryReplacementDate: 'VEHICLE_COMMON_BATTERY-REPLACEMENT-DATE_LABEL',
  transmission_name: 'VEHICLE_COMMON_TRANSMISSION_LABEL',
  cabinColor_name: 'VEHICLE_COMMON_CABIN-COLOR_LABEL',
  bodyColor: 'VEHICLE_COMMON_BODY-COLOR_LABEL',
  sbsMarking_name: 'VEHICLE_COMMON_SBS-MARKING_LABEL',
  innerLength: 'VEHICLE_COMMON_INNER-LENGTH_LABEL',
  innerWidth: 'VEHICLE_COMMON_INNER-WIDTH_LABEL',
  loadingPlatformIndoorHeight: 'VEHICLE_COMMON_LOADING-PLATFORM-INDOOR-HEIGHT_LABEL',
  aoriHeight: 'VEHICLE_COMMON_AORI-HEIGHT_LABEL',
  floorClearance: 'VEHICLE_COMMON_FLOOR-CLEARANCE_LABEL',
  lashingRailPosition: 'VEHICLE_COMMON_LASHING-RAIL-POSITION_LABEL',
  tireManufacturer_name: 'VEHICLE_COMMON_TIRE-MANUFACTURER_LABEL',
  snowTire_name: 'VEHICLE_COMMON_SNOW-TIRE_LABEL',
  tireChain_name: 'VEHICLE_COMMON_TIRE-CHAIN_LABEL',
  spareTire_name: 'VEHICLE_COMMON_SPARE-TIRE_LABEL',
  tireSizeFront: 'VEHICLE_COMMON_TIRE-SIZE-FRONT_LABEL',
  tireSizeRear: 'VEHICLE_COMMON_TIRE-SIZE-REAR_LABEL'
}

export const detailedVehicleInformationMap = {
  // c1
  // engineNumber: 'VEHICLE_COMMON_ENGINE-NUMBER_LABEL',
  body_name: 'VEHICLE_COMMON_BODY_LABEL',
  bodyManufacturer_name: 'VEHICLE_COMMON_BODY-MANUFACTURER_LABEL',
  wing_name: 'VEHICLE_COMMON_WING_LABEL', // ウィング VEHICLE_COMMON_WING_LABEL
  powerGate_name: 'VEHICLE_COMMON_POWER-GATE_LABEL',
  powerManufacturer_name: 'VEHICLE_COMMON_POWER-MANUFACTURER_LABEL',
  powerGateLift: 'VEHICLE_COMMON_POWER-GATE-LIFT_LABEL',
  craneManufacturer_name: 'VEHICLE_COMMON_CRANE-MANUFACTURER_LABEL',
  craneLift: 'VEHICLE_COMMON_CRANE-LIFT_LABEL',
  withJorder_name: 'VEHICLE_COMMON_WITH-OR-WITHOUT-JORDER_LABEL',
  airSuspension_name: 'VEHICLE_COMMON_AIR-SUSPENSION_LABEL',
  blank: '',
  // c2
  floorMaterial: 'VEHICLE_COMMON_FLOOR-MATERIAL_LABEL',
  floorWork: 'VEHICLE_COMMON_FLOOR-WORK_LABEL',
  fuelTank: 'VEHICLE_COMMON_FUEL-TANK_LABEL',
  refrigeratorManufacturer_name: 'VEHICLE_COMMON_REFRIGERATOR-MANUFACTURER_LABEL',
  refrigeratorModel_name: 'VEHICLE_COMMON_REFRIGERATOR-MODEL_LABEL',
  inVehicleFreezingEquipment_name: 'VEHICLE_COMMON_IN-VEHICLE-FREEZING-EQUIPMENT_LABEL',
  temperatureRecord_name: 'VEHICLE_COMMON_TEMPERATURE-RECORD_LABEL',
  standBy_name: 'VEHICLE_COMMON_STAND-BY_LABEL',
  etcTwo_name: 'VEHICLE_COMMON_ETC_LABEL',
  etcOnBoardUnitId: 'VEHICLE_COMMON_ON-BOARD-UNIT-ID_LABEL',
  etcCorporateCardNumber: 'VEHICLE_COMMON_ETC-CORPORATE-CARD-NUMBER_LABEL',

  // c3
  fuelCardNo: 'VEHICLE_COMMON_FUEL-CARD-NO_LABEL',
  adBlue_name: 'VEHICLE_COMMON_ADBLUE_LABEL',
  dashcamManufacturer_name: 'VEHICLE_COMMON_DASHCAM-MANUFACTURER_LABEL',
  backEyeCameraManufacturer_name: 'VEHICLE_COMMON_BACK-EYE-CAMERA-MANUFACTURER_LABEL',
  cornerSensor_name: 'VEHICLE_COMMON_CORNER-SENSOR_LABEL', // コーナーセンサー
  digiTachoManufacture_name: 'VEHICLE_COMMON_DIGI-TACHO-MANUFACTURER_LABEL',
  digiTachoUniqueCode: 'VEHICLE_COMMON_DIGI-TACHO-UNIQUE-CODE_LABEL',
  remarks: 'VEHICLE_COMMON_REMARKS_LABEL'

}

export const fileCategory = {
  // 車検証
  VIC: '1',
  // 自賠責証書
  CALI: '2'
}
