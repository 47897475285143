import { dropdownCategory } from '../../../common/const'

const generalTab = [
  { value: 'company', label: 'VEHICLE_COMMON_COMPANY_LABEL', type: 'select', maxLength: '', required: true, options: [] }, // organization.company
  { value: 'department', label: 'VEHICLE_COMMON_DEPARTMENT_LABEL', type: 'select', maxLength: '', required: true, options: [] }, // organization.department
  { value: 'businessUnit', label: 'VEHICLE_COMMON_BUSINESS-UNIT_LABEL', type: 'select', maxLength: '', required: true, options: [] }, // organization.businessUnit
  { value: 'branchOffice', label: 'VEHICLE_COMMON_BRANCH-OFFICE_LABEL', type: 'select', maxLength: '', required: true, options: [] }, // organization.branchOffice
  { value: 'branch', label: 'VEHICLE_COMMON_BRANCH_LABEL', type: 'select', maxLength: '', required: true, options: [] }, // organization.branch
  { value: 'licensePlateNumber', label: 'VEHICLE_COMMON_LICENSE-PLATE_LABEL', type: 'text', maxLength: '', required: true, subItems: [{ value: 'licensePlateAddress', label: 'VEHICLE_COMMON_LICENSE-PLATE-ADDRESS_LABEL', type: 'text', maxLength: '255' }, { value: 'licensePlateClass', label: 'VEHICLE_COMMON_LICENSE-PLATE-CLASS-NO_LABEL', type: 'text', maxLength: '255' }, { value: 'licensePlateHiragana', label: 'VEHICLE_COMMON_LICENSE-PLATE-HIRAGANA_LABEL', type: 'text', maxLength: '255' }, { value: 'licensePlateNumber', label: 'VEHICLE_COMMON_LICENSE-PLATE-NUMBER_LABEL', type: 'text', maxLength: '4' }] },
  { value: 'carNumber', label: 'VEHICLE_COMMON_CAR-NUMBER_LABEL', type: 'text', maxLength: '255', required: false },
  { value: 'pastLicensePlate', label: 'VEHICLE_COMMON_PAST-LICENSE-PLATE_LABEL', type: 'text', maxLength: '255', required: false },
  { value: 'model', label: 'VEHICLE_COMMON_MODEL_LABEL', type: 'text', maxLength: '255', required: true },
  { value: 'code', label: 'VEHICLE_COMMON_VEHICLE-ID_LABEL', type: 'text', maxLength: '255', required: true },
  { value: 'type', label: 'VEHICLE_COMMON_TYPE_LABEL', type: 'select', maxLength: '', required: true, options: dropdownCategory.type },
  { value: 'brand', label: 'VEHICLE_COMMON_BRAND_LABEL', type: 'select', maxLength: '', required: true, options: dropdownCategory.brand },
  { value: 'class', label: 'VEHICLE_COMMON_CLASS_LABEL', type: 'select', maxLength: '', required: true, options: dropdownCategory.class }, // new 3-13 class
  { value: 'bodyShape', label: 'VEHICLE_COMMON_BODY-SHAPE_LABEL', type: 'select', maxLength: '', required: true, options: dropdownCategory.bodyShape },
  { value: 'specialEquipment', label: 'VEHICLE_COMMON_SPECIAL-EQUIPMENT_LABEL', type: 'text', maxLength: '255', required: true }, // new 3-15 specialEquipment
  { value: 'initialRegistrationDate', label: 'VEHICLE_COMMON_INITIAL-REGISTRATION-YEAR-MONTH_LABEL', type: 'date', maxLength: '2100-12-31', required: true, addMaxDate: 0 },
  { value: 'registrationDate', label: 'VEHICLE_COMMON_REGISTRATION-DATE_LABEL', type: 'date', maxLength: '2100-12-31', required: true, addMaxDate: 0 },
  { value: 'engineModel', label: 'VEHICLE_COMMON_ENGINE-MODEL_LABEL', type: 'text', maxLength: '255', required: true },
  { value: 'totalDisplacement', label: 'VEHICLE_COMMON_TOTAL-DISPLACEMENT_LABEL', type: 'number', maxLength: '15', required: true },
  { value: 'fuelType', label: 'VEHICLE_COMMON_FUEL-TYPE_LABEL', type: 'select', maxLength: '', required: true, options: dropdownCategory.fuelType },
  { value: 'status', label: 'VEHICLE_COMMON_STATUS_LABEL', type: 'select', maxLength: '', required: true, options: dropdownCategory.status }
]

const registrationTab = [
  { value: 'owner', label: 'VEHICLE_COMMON_OWNER_LABEL', type: 'text', maxLength: '255', required: true },
  { value: 'user', label: 'VEHICLE_COMMON_USER_LABEL', type: 'select', maxLength: '', required: true, options: dropdownCategory.user_vehicle },
  { value: 'userAddress', label: 'VEHICLE_COMMON_USER-ADDRESS_LABEL', type: 'text', maxLength: '255', required: true },
  { value: 'withLease', label: 'VEHICLE_COMMON_WITH-OR-WITHOUT-LEASE_LABEL', type: 'select', maxLength: '', required: true, options: dropdownCategory.withLease },
  { value: 'expensesIncludingLease', label: 'VEHICLE_COMMON_EXPENSES-INCLUDING-LEASE_LABEL', type: 'select', maxLength: '', required: false, options: dropdownCategory.expensesIncludingLease }, // new 6-5
  { value: 'leaseEndDate', label: 'VEHICLE_COMMON_LEASE-END-DATE_LABEL', type: 'date', maxLength: '', required: false, addMaxDate: 3650 },
  { value: 'leaseFee', label: 'VEHICLE_COMMON_LEASE-FEE_LABEL', type: 'number', maxLength: '9', required: false },
  { value: 'automobileTax', label: 'VEHICLE_COMMON_AUTOMOBILE-TAX_LABEL', type: 'number', maxLength: '9', required: true },
  { value: 'weightTax', label: 'VEHICLE_COMMON_WEIGHT-TAX_LABEL', type: 'number', maxLength: '9', required: true },
  { value: 'inspectionExpireDate', label: 'VEHICLE_COMMON_INSPECTION-EXPIRE-DATE_LABEL', type: 'date', maxLength: '', required: true, addMaxDate: 1096 },
  { value: 'mileage', label: 'VEHICLE_COMMON_MILEAGE_LABEL', type: 'number', maxLength: '15', required: true },
  { value: 'lastRegularMaintenanceDate', label: 'VEHICLE_COMMON_LAST-REGULAR-MAINTENANCE-DATE_LABEL', type: 'date', maxLength: '', required: true, addMaxDate: 30 },
  { value: 'regularMaintenanceCycle', label: 'VEHICLE_COMMON_REGULAR-MAINTENANCE-CYCLE_LABEL', type: 'select', maxLength: '', required: true, options: dropdownCategory.regularMaintenanceCycle }, // new 6-13
  { value: 'nextRegularMaintenanceMonth', label: 'VEHICLE_COMMON_NEXT-REGULAR-MAINTENANCE-MONTH_LABEL', type: 'auto-calculated', maxLength: '', required: false },
  { value: 'mandatoryInsuranceEndDate', label: 'VEHICLE_COMMON_MANDATORY-INSURANCE-END-DATE_LABEL', type: 'date', maxLength: '', required: true, addMaxDate: 1096 },
  { value: 'voluntaryInsuranceType', label: 'VEHICLE_COMMON_VOLUNTARY-INSURANCE-TYPE_LABEL', type: 'select', maxLength: '', required: true, options: dropdownCategory.voluntaryInsuranceType },
  { value: 'voluntaryInsuranceEndDate', label: 'VEHICLE_COMMON_VOLUNTARY-INSURANCE-END-DATE_LABEL', type: 'date', maxLength: '', required: true, addMaxDate: 1096 },
  { value: 'usage', label: 'VEHICLE_COMMON_USAGE_LABEL', type: 'select', maxLength: '', required: true, options: dropdownCategory.usage },
  { value: 'privateBusinessUse', label: 'VEHICLE_COMMON_PRIVATE-USE-OR-BUSINESS-USE_LABEL', type: 'select', maxLength: '', required: true, options: dropdownCategory.privateBusinessUse },
  { value: 'factoryInCharge', label: 'VEHICLE_COMMON_FACTORY-IN-CHARGE_LABEL', type: 'text', maxLength: '255', required: false }, // new 6-20
  { value: 'specialVehiclePassExpireDate', label: 'VEHICLE_COMMON_SPECIAL-VEHICLE-PASS-EXPIRE-DATE_LABEL', type: 'date', maxLength: '', required: false, addMaxDate: 1461 }
]

const weightSizeInfoTab = [
  { value: 'seatingCapacity', label: 'VEHICLE_COMMON_SEATING-CAPACITY_LABEL', type: 'number', maxLength: '9', required: true },
  { value: 'maxLoadCapacity', label: 'VEHICLE_COMMON_MAXIMUM-LOAD-CAPACITY_LABEL', type: 'number', maxLength: '15', required: true },
  { value: 'vehicleWeight', label: 'VEHICLE_COMMON_VEHICLE-WEIGHT_LABEL', type: 'number', maxLength: '15', required: true },
  { value: 'grossVehicleWeight', label: 'VEHICLE_COMMON_GROSS-VEHICLE-WEIGHT_LABEL', type: 'number', maxLength: '15', required: true },
  { value: 'length', label: 'VEHICLE_COMMON_LENGTH_LABEL', type: 'number', maxLength: '15', required: true },
  { value: 'width', label: 'VEHICLE_COMMON_WIDTH_LABEL', type: 'number', maxLength: '15', required: true },
  { value: 'height', label: 'VEHICLE_COMMON_HEIGHT_LABEL', type: 'number', maxLength: '15', required: true },
  { value: 'frontFrontAxleLoad', label: 'VEHICLE_COMMON_FF-AXLE-LOAD_LABEL', type: 'number', maxLength: '15', required: false },
  { value: 'frontRearAxleLoad', label: 'VEHICLE_COMMON_FR-AXLE-LOAD_LABEL', type: 'number', maxLength: '15', required: false },
  { value: 'rearFrontAxleLoad', label: 'VEHICLE_COMMON_RF-AXLE-LOAD_LABEL', type: 'number', maxLength: '15', required: false },
  { value: 'rearRearAxleLoad', label: 'VEHICLE_COMMON_RR-AXLE-LOAD_LABEL', type: 'number', maxLength: '15', required: false },
  { value: 'fifthWheelLoad', label: 'VEHICLE_COMMON_FIFTH-WHEEL-LOAD_LABEL', type: 'number', maxLength: '15', required: false }, // change text to number
  { value: 'modelDesignationNumber', label: 'VEHICLE_COMMON_MODEL-DESIGNATION-NUMBER_LABEL', type: 'text', maxLength: '255', required: false },
  { value: 'classificationCategoryNumber', label: 'VEHICLE_COMMON_CLASSIFICATION-CATEGORY-NUMBER_LABEL', type: 'text', maxLength: '255', required: false },
  { value: 'batteryType', label: 'VEHICLE_COMMON_BATTERY-TYPE_LABEL', type: 'text', maxLength: '255', required: false },
  { value: 'batteryReplacementDate', label: 'VEHICLE_COMMON_BATTERY-REPLACEMENT-DATE_LABEL', type: 'date', maxLength: '', required: false, addMaxDate: 1096 },
  { value: 'transmission', label: 'VEHICLE_COMMON_TRANSMISSION_LABEL', type: 'select', maxLength: '', required: false, options: dropdownCategory.transmission },
  { value: 'cabinColor', label: 'VEHICLE_COMMON_CABIN-COLOR_LABEL', type: 'select', maxLength: '', required: false, options: dropdownCategory.cabinColor },
  { value: 'bodyColor', label: 'VEHICLE_COMMON_BODY-COLOR_LABEL', type: 'text', maxLength: '255', required: false }, // new 9-19
  { value: 'sbsMarking', label: 'VEHICLE_COMMON_SBS-MARKING_LABEL', type: 'select', maxLength: '', required: true, options: dropdownCategory.sbsMarking }, // new 9-20
  { value: 'innerLength', label: 'VEHICLE_COMMON_INNER-LENGTH_LABEL', type: 'number', maxLength: '15', required: false },
  { value: 'innerWidth', label: 'VEHICLE_COMMON_INNER-WIDTH_LABEL', type: 'number', maxLength: '15', required: false },
  { value: 'loadingPlatformIndoorHeight', label: 'VEHICLE_COMMON_LOADING-PLATFORM-INDOOR-HEIGHT_LABEL', type: 'number', maxLength: '15', required: false },
  { value: 'aoriHeight', label: 'VEHICLE_COMMON_AORI-HEIGHT_LABEL', type: 'number', maxLength: '15', required: false },
  { value: 'floorClearance', label: 'VEHICLE_COMMON_FLOOR-CLEARANCE_LABEL', type: 'number', maxLength: '15', required: false },
  { value: 'lashingRailPosition', label: 'VEHICLE_COMMON_LASHING-RAIL-POSITION_LABEL', type: 'text', maxLength: '255', required: false },
  { value: 'tireManufacturer', label: 'VEHICLE_COMMON_TIRE-MANUFACTURER_LABEL', type: 'select', maxLength: '', required: true, options: dropdownCategory.tireManufacturer },
  { value: 'snowTire', label: 'VEHICLE_COMMON_SNOW-TIRE_LABEL', type: 'select', maxLength: '', required: true, options: dropdownCategory.snowTire },
  { value: 'tireChain', label: 'VEHICLE_COMMON_TIRE-CHAIN_LABEL', type: 'select', maxLength: '', required: false, options: dropdownCategory.tireChain }, // new 9-2
  { value: 'spareTire', label: 'VEHICLE_COMMON_SPARE-TIRE_LABEL', type: 'select', maxLength: '', required: false, options: dropdownCategory.spareTire },
  { value: 'tireSizeFront', label: 'VEHICLE_COMMON_TIRE-SIZE-FRONT_LABEL', type: 'text', maxLength: '255', required: false },
  { value: 'tireSizeRear', label: 'VEHICLE_COMMON_TIRE-SIZE-REAR_LABEL', type: 'text', maxLength: '255', required: false }

]

const detailedVehicleInfoTab = [
  { value: 'body', label: 'VEHICLE_COMMON_BODY_LABEL', type: 'select', maxLength: '', required: false, options: dropdownCategory.body }, // change text to select
  { value: 'bodyManufacturer', label: 'VEHICLE_COMMON_BODY-MANUFACTURER_LABEL', type: 'select', maxLength: '', required: false, options: dropdownCategory.bodyManufacturer },
  { value: 'wing', label: 'VEHICLE_COMMON_WING_LABEL', type: 'select', maxLength: '', required: false, options: dropdownCategory.wing }, // new 12-3 ウィング
  { value: 'powerGate', label: 'VEHICLE_COMMON_POWER-GATE_LABEL', type: 'select', maxLength: '', required: false, options: dropdownCategory.powerGate },
  { value: 'powerManufacturer', label: 'VEHICLE_COMMON_POWER-MANUFACTURER_LABEL', type: 'select', maxLength: '', required: false, options: dropdownCategory.powerManufacturer }, // new 12-5 パワーゲートメーカー
  { value: 'powerGateLift', label: 'VEHICLE_COMMON_POWER-GATE-LIFT_LABEL', type: 'number', maxLength: '15', required: false }, // new 12-6 パワーゲート揚力（トン）
  { value: 'craneManufacturer', label: 'VEHICLE_COMMON_CRANE-MANUFACTURER_LABEL', type: 'select', maxLength: '', required: false, options: dropdownCategory.craneManufacturer }, // new 12-8 クレーンメーカー
  { value: 'craneLift', label: 'VEHICLE_COMMON_CRANE-LIFT_LABEL', type: 'number', maxLength: '15', required: false }, // new 12-9 クレーン揚力（トン）
  { value: 'withJorder', label: 'VEHICLE_COMMON_WITH-OR-WITHOUT-JORDER_LABEL', type: 'select', maxLength: '', required: false, options: dropdownCategory.withJorder },
  { value: 'airSuspension', label: 'VEHICLE_COMMON_AIR-SUSPENSION_LABEL', type: 'select', maxLength: '', required: false, options: dropdownCategory.airSuspension },
  { value: 'floorMaterial', label: 'VEHICLE_COMMON_FLOOR-MATERIAL_LABEL', type: 'text', maxLength: '255', required: false },
  { value: 'floorWork', label: 'VEHICLE_COMMON_FLOOR-WORK_LABEL', type: 'text', maxLength: '255', required: false },
  { value: 'fuelTank', label: 'VEHICLE_COMMON_FUEL-TANK_LABEL', type: 'number', maxLength: '15', required: false },
  { value: 'refrigeratorManufacturer', label: 'VEHICLE_COMMON_REFRIGERATOR-MANUFACTURER_LABEL', type: 'select', maxLength: '', required: false, options: dropdownCategory.refrigeratorManufacturer },
  { value: 'refrigeratorModel', label: 'VEHICLE_COMMON_REFRIGERATOR-MODEL_LABEL', type: 'select', maxLength: '', required: false, options: dropdownCategory.refrigeratorModel }, // change text to select
  { value: 'inVehicleFreezingEquipment', label: 'VEHICLE_COMMON_IN-VEHICLE-FREEZING-EQUIPMENT_LABEL', type: 'select', maxLength: '', required: false, options: dropdownCategory.inVehicleFreezingEquipment },
  { value: 'temperatureRecord', label: 'VEHICLE_COMMON_TEMPERATURE-RECORD_LABEL', type: 'select', maxLength: '', required: false, options: dropdownCategory.temperatureRecord },
  { value: 'standBy', label: 'VEHICLE_COMMON_STAND-BY_LABEL', type: 'select', maxLength: '', required: false, options: dropdownCategory.standBy },
  { value: 'etcTwo', label: 'VEHICLE_COMMON_ETC_LABEL', type: 'select', maxLength: '', required: false, options: dropdownCategory.etcTwo },
  { value: 'etcOnBoardUnitId', label: 'VEHICLE_COMMON_ON-BOARD-UNIT-ID_LABEL', type: 'text', maxLength: '255', required: false },
  { value: 'etcCorporateCardNumber', label: 'VEHICLE_COMMON_ETC-CORPORATE-CARD-NUMBER_LABEL', type: 'text', maxLength: '255', required: false },
  { value: 'fuelCardNo', label: 'VEHICLE_COMMON_FUEL-CARD-NO_LABEL', type: 'text', maxLength: '255', required: false },
  { value: 'adBlue', label: 'VEHICLE_COMMON_ADBLUE_LABEL', type: 'select', maxLength: '', required: false, options: dropdownCategory.adBlue },
  { value: 'dashcamManufacturer', label: 'VEHICLE_COMMON_DASHCAM-MANUFACTURER_LABEL', type: 'select', maxLength: '', required: true, options: dropdownCategory.dashcamManufacturer },
  { value: 'backEyeCameraManufacturer', label: 'VEHICLE_COMMON_BACK-EYE-CAMERA-MANUFACTURER_LABEL', type: 'select', maxLength: '', required: true, options: dropdownCategory.backEyeCameraManufacturer },
  { value: 'cornerSensor', label: 'VEHICLE_COMMON_CORNER-SENSOR_LABEL', type: 'select', maxLength: '', required: false, options: dropdownCategory.cornerSensor }, // new 12-31 コーナーセンサーメーカー
  { value: 'digiTachoManufacture', label: 'VEHICLE_COMMON_DIGI-TACHO-MANUFACTURER_LABEL', type: 'select', maxLength: '', required: false, options: dropdownCategory.digiTachoManufacture },
  { value: 'digiTachoUniqueCode', label: 'VEHICLE_COMMON_DIGI-TACHO-UNIQUE-CODE_LABEL', type: 'text', maxLength: '255', required: false },
  { value: 'remarks', label: 'VEHICLE_COMMON_REMARKS_LABEL', type: 'textarea', maxLength: '255', required: false }

  // according design file
  // { value: 'engineNumber', label: 'VEHICLE_COMMON_ENGINE-NUMBER_LABEL', type: 'text', maxLength: '255', required: false },
  // { value: 'snowTire', label: 'VEHICLE_COMMON_SNOW-TIRE_LABEL', type: 'select', maxLength: '', required: false, options: ['あり', 'なし'] },
  // { value: 'rLiftType', label: 'VEHICLE_COMMON_R-LIFT-TYPE_LABEL', type: 'number', maxLength: '15', required: false },
  // { value: 'rLiftManufacturer', label: 'VEHICLE_COMMON_R-LIFT-MANUFACTURER_LABEL', type: 'text', maxLength: '255', required: false },
  // { value: 'factoryInCharge', label: 'VEHICLE_COMMON_FACTORY-IN-CHARGE_LABEL', type: 'text', maxLength: '255', required: false },
  // { value: 'color', label: 'VEHICLE_COMMON_COLOR_LABEL', type: 'text', maxLength: '255', required: false },
  // { value: 'paintColor', label: 'VEHICLE_COMMON_PAINT-COLOR_LABEL', type: 'text', maxLength: '255', required: false },
  // { value: 'accessories', label: 'VEHICLE_COMMON_ACCESSORIES_LABEL', type: 'text', maxLength: '255', required: false },
  // { value: 'rigType', label: 'VEHICLE_COMMON_RIG-TYPE_LABEL', type: 'text', maxLength: '255', required: false },
  // { value: 'containerManufacturer', label: 'VEHICLE_COMMON_CONTAINER-MANUFACTURER_LABEL', type: 'text', maxLength: '255', required: false },
  // { value: 'directConnectionType', label: 'VEHICLE_COMMON_DIRECT-CONNECTION-TYPE_LABEL', type: 'select', maxLength: '', required: false, options: ['あり', 'なし'] },
]

export const tabConstants = {
  generalTab: { title: 'VEHICLE_COMMON_GENERAL-INFO_LABEL', limit: 5, maxItemEachCol: 11, fileSize: 5 },
  registrationTab: { title: 'VEHICLE_COMMON_REGISTRATION-INFO_LABEL', limit: 1, maxItemEachCol: 11, fileSize: 2 },
  weightSizeInfoTab: { title: 'VEHICLE_COMMON_WEIGHT-SIZE-INFO_LABEL', limit: 3, maxItemEachCol: 16, fileSize: 1 },
  detailedVehicleInfo: { title: 'VEHICLE_COMMON_DETAILED-VEHICLE-INFO_LABEL', maxItemEachCol: 10 }
}

export const labelName = {
  generalTab,
  registrationTab,
  weightSizeInfoTab,
  detailedVehicleInfoTab
}

export const dialogMessageList = [
  { type: 'info', code: 'save', message: 'VEHICLE_VEHICLE-CREATE-EDIT_POPUP-SAVE_LABEL' },
  { type: 'info', code: 'remove_file', message: 'VEHICLE_VEHICLE-CREATE-EDIT_POPUP-REMOVE-FILE_LABEL' },
  { type: 'info', code: 'delete_vehicle', message: 'VEHICLE_VEHICLE-EDIT_POPUP-DELETE-VEHICLE_LABEL' },
  { type: 'info', code: 'cancel_create_vehicle', message: 'VEHICLE_VEHICLE-CREATE_POPUP-CANCEL-CREATE_LABEL' },
  { type: 'info', code: 'cancel_edit_vehicle', message: 'VEHICLE_VEHICLE-EDIT_POPUP-CANCEL-EDIT_LABEL' },
  { type: 'error', code: 'id_exist', message: 'VEHICLE_VEHICLE-CREATE-EDIT_POPUP-DUPLICATE_LABEL' },
  { type: 'error', code: 'has_co2_report', message: 'VEHICLE_VEHICLE-EDIT_POPUP-CANNOT-DELETE_LABEL' },
  { type: 'error', code: 'wrong_file_type', message: 'VEHICLE_VEHICLE-CREATE-EDIT_POPUP-WRONG-FILE-TYPE_LABEL' },
  { type: 'error', code: 'max_file_num', message: 'VEHICLE_VEHICLE-CREATE-EDIT_POPUP-OVER-MAX-FILE-NO_LABEL' },
  { type: 'error', code: 'max_file_size', message: 'VEHICLE_VEHICLE-CREATE-EDIT_POPUP-OVER-MAX-FILE-SIZE_LABEL' },
  { type: 'error', code: 'dropdown_not_exist', message: 'COMMON_DROPDOWN-NOT-EXIST_MESSAGE_LABEL' },
  { type: 'error', code: 'unknown', message: 'COMMON_UNKNOWN-ERROR_MESSAGE_LABEL' }
]

export const defaultDropdownValues = {
  // adBlue: '',
  // airSuspension: '',
  // cabinColor: '',
  // directConnectionType: '',
  // etcTwo: '',
  // inVehicleFreezingEquipment: '',
  // powerGate: '',
  // privateBusinessUse: '',
  // refrigerator: '',
  // snowTire: '',
  // spareTire: '',
  // standBy: '',
  // status: '',
  // temperatureRecord: '',
  // transmission: '',
  // type: '' ,
  // usage: '',
  // voluntaryInsuranceType: '',
  // withBackEyeCamera: '',
  // withJorder: '',
  // withLease: '',
  // sbsMarking: '',
  // tireChain: '',
  // wing: ''
  // crane: '',
  // dashcam: '',
  // cornerSensor: '',
  // digiTacho: ''
}
export const dropdownKey = {
  type: dropdownCategory.type,
  status: dropdownCategory.status,
  user: dropdownCategory.user_vehicle,
  withLease: dropdownCategory.withLease,
  expensesIncludingLease: dropdownCategory.expensesIncludingLease,
  regularMaintenanceCycle: dropdownCategory.regularMaintenanceCycle,
  voluntaryInsuranceType: dropdownCategory.voluntaryInsuranceType,
  usage: dropdownCategory.usage,
  privateBusinessUse: dropdownCategory.privateBusinessUse,
  transmission: dropdownCategory.transmission,
  cabinColor: dropdownCategory.cabinColor,
  sbsMarking: dropdownCategory.sbsMarking,
  snowTire: dropdownCategory.snowTire,
  tireChain: dropdownCategory.tireChain,
  spareTire: dropdownCategory.spareTire,
  body: dropdownCategory.body,
  wing: dropdownCategory.wing,
  powerGate: dropdownCategory.powerGate,
  withJorder: dropdownCategory.withJorder,
  airSuspension: dropdownCategory.airSuspension,
  refrigeratorModel: dropdownCategory.refrigeratorModel,
  inVehicleFreezingEquipment: dropdownCategory.inVehicleFreezingEquipment,
  temperatureRecord: dropdownCategory.temperatureRecord,
  standBy: dropdownCategory.standBy,
  etcTwo: dropdownCategory.etcTwo,
  adBlue: dropdownCategory.adBlue,
  brand: dropdownCategory.brand,
  class: dropdownCategory.class,
  fuelType: dropdownCategory.fuelType,
  tireManufacturer: dropdownCategory.tireManufacturer,
  bodyManufacturer: dropdownCategory.bodyManufacturer,
  powerManufacturer: dropdownCategory.powerManufacturer,
  craneManufacturer: dropdownCategory.craneManufacturer,
  refrigeratorManufacturer: dropdownCategory.refrigeratorManufacturer,
  dashcamManufacturer: dropdownCategory.dashcamManufacturer,
  backEyeCameraManufacturer: dropdownCategory.backEyeCameraManufacturer,
  cornerSensor: dropdownCategory.cornerSensor,
  digiTachoManufacture: dropdownCategory.digiTachoManufacture,
  bodyShape: dropdownCategory.bodyShape,
  bodyColor: dropdownCategory.bodyColor
}
export const requiredItems = [
  'code',
  'specialEquipment',
  'type',
  'brand',
  'licensePlateNumber',
  'bodyShape',
  'registrationDate',
  'initialRegistrationDate',
  'company',
  'branch',
  'model',
  'engineModel',
  'regularMaintenanceCycle',
  'totalDisplacement',
  'fuelType',
  'status',
  'owner',
  'seatingCapacity',
  'user',
  'userAddress',
  'withLease',
  'mileage',
  'inspectionExpireDate',
  'lastRegularMaintenanceDate',
  'mandatoryInsuranceEndDate',
  'voluntaryInsuranceType',
  'voluntaryInsuranceEndDate',
  'automobileTax',
  'weightTax',
  'usage',
  'privateBusinessUse',
  'maxLoadCapacity',
  'vehicleWeight',
  'grossVehicleWeight',
  'length',
  'width',
  'height',
  'class',
  'tireManufacturer',
  'snowTire',
  'body',
  'bodyManufacturer',
  'craneManufacturer',
  'dashcamManufacturer',
  'backEyeCameraManufacturer',
  'digiTachoManufacture',
  'sbsMarking'
]
export const businessUseItems = [
  'transmission',
  'body',
  'bodyManufacturer',
  'wing',
  'powerGate',
  'craneManufacturer',
  'withJorder',
  'airSuspension',
  'digiTachoManufacture'
]
