import axios from 'axios'
import { API_ENDPOINT } from '../../tools/apiEndpoint'

export const getFileApi = async ({ id, category, vehicleKind }) => {
  const URL = `${API_ENDPOINT}/file/${id}`
  const params = {
    category,
    vehicleKind
  }
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    params
  }

  const response = await axios.get(
    URL,
    config
  )

  return response
}

export const editFileApi = async ({ exec, id, vehicleKind, body }) => {
  const URL = `${API_ENDPOINT}/file/edit?exec=${exec}`
  const params = {
    id,
    vehicleKind
  }
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    params
  }
  const { fileList } = body
  const response = await axios.patch(
    URL,
    fileList,
    config
  )

  return response
}
