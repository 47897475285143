import React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { isEqual } from 'lodash'
import { CircularProgress } from '@material-ui/core'
import { VehicleWrapper } from './styles'
import Tab from '../../components/Tab'
import Table from '../../components/Table'
import EditVehicle from './EditVehicle'
import MultiSelect from '../../components/MultiSelect'
import { getVehicleList, clearState } from '../../features/vehicles/vehicleSlice'
import { clearFileState } from '../../features/file/fileSlice'
import { getOrganizationList } from '../../features/organizations/organizationSlice'
import { columnMap, genetalTabOnSearch } from '../../components/Table/columnNameMap'
import Pagination from '../../components/Pagination'
import GeneralTab from '../../components/FilterGroup/generalTab'
import RegistrationTab from '../../components/FilterGroup/registrationTab'
import UpdateHistoryTab from '../../components/FilterGroup/updateHistoryTab'
import WeightSizeTab from '../../components/FilterGroup/weightSizeTab'
import DetailVehicleTab from '../../components/FilterGroup/detailVehicleTab'
import { BackToContractButton, CreateButton, HiddenButton, ExportExcelButton, DisplayButton } from '../../components/Button'
import VehicleDetail from './VehicleDetail'
import i18n from 'i18next'

import { getVehicleCSVApi } from '../../features/vehicles/vehicleAPI'
import { getDropDownIdApi } from '../../features/dropdown/dropdownAPI'
import Modal from '../../components/Modal/index'
import { userInfo } from './../../features/users/userInfoSlice'
import { useMounted } from '../../tools'
import ImportVehicle from '../../components/ImportVehicle'
import ImportFinance from '../../components/ImportDataFinance'
import { perPageOptions } from '../../common/const'

const fieldMapping = {
  inspection: 'inspectionExpireDate',
  lease: 'leaseEndDate',
  regularMaintenance: 'nextRegularMaintenanceMonth',
  mandatoryInsurance: 'mandatoryInsuranceEndDate',
  specialVehiclePass: 'specialVehiclePassExpireDate',
  voluntaryInsurance: 'voluntaryInsuranceEndDate'
}

function VehicleManagement (props) {
  const isMounted = useMounted()
  const dispatch = useDispatch()
  const location = useLocation()
  const [selectedTab, setTab] = React.useState(location.state ? 1 : 0)
  const [advancedOrganization, setAdvancedOrganization] = React.useState({
    department: '',
    businessUnit: '',
    branchOffice: ''
  })
  const [sortOrder, setSortOrder] = React.useState()
  const [onDetail, setShowDetail] = React.useState(false)
  const [crtOrEdt, setCrtOrEdt] = React.useState('')
  const [onCreate, setShowCreate] = React.useState(false)
  const [selectedVehicle, setSelectedVehicle] = React.useState('')
  const [resetSwitch, setResetSwitch] = React.useState(false)
  const [Hidden, setHidden] = React.useState(false)
  const [page, setPage] = React.useState(1)
  const [perPage, setPerPage] = React.useState(1000)
  const [company, setCompany] = React.useState(location.state?.companyName ? location.state.companyName : '')
  const [branch, setBranch] = React.useState(location.state?.organizationId ? location.state.organizationId : [])
  const [branchAll, setBranchAll] = React.useState(false)
  const [searchBody, setSearchBody] = React.useState({})
  const [registrationTabDefault, setRegistrationTabDefault] = React.useState({})
  const [updateHistoryTabDefault, setUpdateHistoryTabDefault] = React.useState({})
  const [isLoading, setIsLoading] = React.useState(false)
  const [isSearchClicked, setIsSearchClicked] = React.useState(false)
  const vehicleState = useSelector(state => state.vehicle)
  const organizationState = useSelector(state => state.organization)
  const userState = useSelector(state => state.userInfo)
  React.useEffect(() => {
    // Get master data
    dispatch(clearState())
    dispatch(clearFileState())
    dispatch(getOrganizationList())
    dispatch(userInfo())
  }, [])

  React.useEffect(() => {
    const body = searchBody
    delete body.onSubmit
    setSearchBody(body)
    // To prevent render call api at first time page
    if (isMounted) {
      if (sortOrder) {
        dispatch(getVehicleList({ page: page, perPage: perPage, sort: { column: sortOrder.column, by: sortOrder.by }, body: body }))
      } else {
        dispatch(getVehicleList({ page: page, perPage: perPage, body: body }))
      }
    }
  }, [page])

  React.useEffect(() => {
    const body = searchBody
    delete body.onSubmit
    setSearchBody(body)
    if (isMounted) {
      if (vehicleState.list) {
        setPage(1)
        if (sortOrder) {
          dispatch(getVehicleList({ page: 1, perPage: perPage, sort: { column: sortOrder.column, by: sortOrder.by }, body: body }))
        } else {
          dispatch(getVehicleList({ page: 1, perPage: perPage, body: body }))
        }
      }
    }
  }, [perPage])

  React.useEffect(() => {
    // reset advanced organization
    setAdvancedOrganization({
      department: '',
      businessUnit: '',
      branchOffice: ''
    })
    if (selectedTab !== 1) {
      location.state = undefined
    }
    // reset default selected body
    setRegistrationTabDefault({})
    setUpdateHistoryTabDefault({})
  }, [selectedTab])

  React.useEffect(() => {
    // Re-search branch
    const body = searchBody
    body.onSubmit = 'onSubmit'
    if (branch.length > 0) {
      body.organizationId = { in: branch }
    } else {
      delete body.organizationId
    }
    setSearchBody(body)
    if (isMounted) {
      setPage(1) // fix sbs-350: reset page when company/branch changes
      dispatch(getVehicleList({ page: 1, perPage: perPage, body }))
    }
  }, [branch])
  React.useEffect(async () => {
    if (location.state) {
      const { organizationId, type, period } = location.state
      const status = await getDropDownIdApi({ categoryId: '6', name: '稼働' })
      const body = {
        status: status.data.id,
        [fieldMapping[type]]: { between: [period.from, period.to] }
      }
      if (organizationId.length > 0) {
        body.organizationId = { in: organizationId }
      }
      setRegistrationTabDefault(body)
      setUpdateHistoryTabDefault(body)
      setSearchBody(body)
      // clear history state
      window.history.replaceState(null, '')
      dispatch(getVehicleList({ page: 1, perPage: perPage, body }))
    }
  }, [])

  const onSearchSubmit = body => {
    setPage(1)
    body = updateOrganizationBody(body)
    setSearchBody(body)
    setResetSwitch(!resetSwitch)
    dispatch(getVehicleList({ page: 1, perPage: perPage, body }))
    setIsSearchClicked(true)
  }

  const updateOrganizationBody = body => {
    if (!('organizationId' in body) && branch.length > 0) {
      body.organizationId = { in: branch }
    }
    return body
  }

  const onExport = async () => {
    setIsLoading(true)
    const response = await getVehicleCSVApi({ body: searchBody })
    const bytes = Buffer.from(response.data.base64Data, 'base64')
    const blob = new Blob([bytes])
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = i18n.t('VEHICLE_VEHICLE-LIST-EXPORT_DEFAULT-EXPORT-FILE-NAME_LABEL')
    link.click()
    link.remove()
    setIsLoading(false)
  }

  const tablist = [
    <Tab.TabItem key={1} name={i18n.t('VEHICLE_VEHICLE-LIST_GENERAL-TAB_LABEL')}><GeneralTab onSearchSubmit={onSearchSubmit} company={company} branchAll={branchAll} setAdvancedOrganization={setAdvancedOrganization} /></Tab.TabItem>,
    <Tab.TabItem key={2} name={i18n.t('VEHICLE_VEHICLE-LIST_REGISTRATINO-INFO-TAB_LABEL')}>{<RegistrationTab onSearchSubmit={onSearchSubmit} defaultValue={registrationTabDefault} />}</Tab.TabItem>,
    <Tab.TabItem key={3} name={i18n.t('VEHICLE_VEHICLE-LIST_WEIGHT-SIZE-INFO-TAB_LABEL')}>{<WeightSizeTab onSearchSubmit={onSearchSubmit} />}</Tab.TabItem>,
    <Tab.TabItem key={4} name={i18n.t('VEHICLE_VEHICLE-LIST_DETAILED-VEHICLE-INFO-TAB_LABEL')}>{<DetailVehicleTab onSearchSubmit={onSearchSubmit} />}</Tab.TabItem>,
    <Tab.TabItem key={5} name={i18n.t('VEHICLE_VEHICLE-LIST_UPDATE-HISTORY-INFO-TAB_LABEL')}>{<UpdateHistoryTab onSearchSubmit={onSearchSubmit} defaultValue={updateHistoryTabDefault} />}</Tab.TabItem>
  ]

  if (userState.email !== '' && !userState.isEdit) {
    const index = tablist.findIndex((i) => i.key === 5)
    tablist.splice(index, 1)
  }

  const sortHandle = (column, by) => {
    setSortOrder(by === 'def' ? null : { column, by })
    const body = searchBody
    delete body.onSubmit
    setSearchBody(body)
    const searchCondition = { page, perPage: perPage, body: body }
    if (by !== 'def') {
      searchCondition.sort = { column, by }
    }
    dispatch(getVehicleList(searchCondition))
  }

  const handlePageChange = pagePag => {
    setPage(pagePag)
  }

  const handleCompanyChange = (e) => {
    setCompany(e[0].key)
    if (!e[0].key && isMounted) {
      setBranch([])
      setBranchAll(true)
    }
  }

  const handleBranchChange = (e, allSelected) => {
    if (e.length > 0) {
      const newBranchs = e.map(item => item.id)
      // Doesn't change state if newBranchs same as previous.
      if (!isEqual(newBranchs, branch)) {
        setBranch(newBranchs)
      }
    }
    setBranchAll(allSelected)
  }
  // const handleAllSelectChange = (allSelected) => {
  //   setBranchAll(allSelected)
  // }

  const getCompanyOptions = () => {
    let companyOptions = [...new Set(organizationState.list.map(item => item.company))]
    companyOptions = companyOptions.map(item => { return { key: item, value: item } })
    return [{ key: '', value: i18n.t('COMMON_COMMON_DROPDOWN-OPTION-ALL_LABEL') }, ...companyOptions]
  }
  // UNIT test
  const onListClick = id => {
    setShowDetail(true)
    window.history.pushState({ urlPath: '/vehicle' }, '', `/viewinfo/${id}`)
    setSelectedVehicle(id)
  }
  // UNIT test
  const setMiddleDetailBack = bool => {
    window.history.pushState({ urlPath: '/vehicle' }, '', '/vehicle')
    setShowDetail(bool)
    setShowCreate(false)
    setCrtOrEdt('')
    dispatch(clearFileState())
  }
  // UNIT test
  const setMiddleCreateBack = state => {
    const body = searchBody
    delete body.onSubmit
    setSearchBody(body)
    /* istanbul ignore else */
    if (state === 'create') {
      window.history.pushState({ urlPath: '/vehicle' }, '', '/vehicle')
      setShowCreate(false)
    } else if (state === 'edit') {
      window.history.pushState({ urlPath: '/vehicle' }, '', `/viewinfo/${selectedVehicle}`)
      setShowDetail(true)
      setShowCreate(false)
    }
    dispatch(getVehicleList({ page: page, perPage: perPage, body: body }))
    setCrtOrEdt('')
  }

  const setMiddleCreate = bool => {
    window.history.pushState({ urlPath: '/vehicle' }, '', '/vehicle/create')
    setCrtOrEdt('create')
    setShowCreate(bool)
  }
  // UNIT test
  const setMiddleEdit = id => {
    window.history.pushState({ urlPath: '/vehicle' }, '', `/editinfo/${id}/edit`)
    setCrtOrEdt('edit')
    setShowDetail(false)
    setShowCreate(true)
  }

  const getBranchOptions = () => {
    const { department, businessUnit, branchOffice } = advancedOrganization

    const selectedOrganizations = organizationState.list.filter(item => item.company === company &&
      (!department || item.department === department) &&
      (!businessUnit || item.businessUnit === businessUnit) &&
      (!branchOffice || item.branchOffice === branchOffice)
    )
    return selectedOrganizations
  }

  const handlePerPageChange = (e, allSelected) => {
    if (e.length > 0) {
      const newPerPage = perPageOptions.find(item => item.value === e)
      // Doesn't change state if newBranchs same as previous.
      if (!isEqual(newPerPage.key, perPage)) {
        setPerPage(newPerPage.key)
      }
    }
    // setBranchAll(allSelected)
  }

  const countToPage = page * perPage < vehicleState.itemCount ? page * perPage : vehicleState.itemCount
  const startItem = vehicleState.itemCount > 0 ? 1 + ((page - 1) * perPage) : 0

  const tableColumn = (isSearchClicked && selectedTab === 0) ? genetalTabOnSearch : columnMap[selectedTab]
  return (
    <>
      {onDetail && <VehicleDetail history={props.history} onEdit={setMiddleEdit} onBack={setMiddleDetailBack} id={selectedVehicle} />}
      {onCreate && <EditVehicle state={crtOrEdt} history={props.history} onBack={setMiddleCreateBack} id={selectedVehicle} />}
      <VehicleWrapper show={onDetail || onCreate}>
        <Modal show={isLoading || vehicleState.load}><CircularProgress></CircularProgress></Modal>
        <section className="header">
          <div className="header-title">{i18n.t('VEHICLE_VEHICLE-LIST_PAGE-HEADER_LABEL')}</div>
          { location.state && <BackToContractButton state={ location.state } />}

          <div className="header-actions">
            {Hidden && <DisplayButton onClick={() => setHidden(!Hidden)} />}
            {!Hidden && <HiddenButton onClick={() => setHidden(!Hidden)} />}
            { userState.isAdmin && <ImportFinance /> }
            { userState.isEdit && <ImportVehicle />}
            {
              (userState.isEdit || (userState.isAdmin && userState.isEdit))
                ? <CreateButton data-testid="create-vehicle-button" onClick={() => setMiddleCreate(true)} />
                : ''
            }
            <div className="action-options">
              <label>{i18n.t('COMMON_COMMON_COMPANY-DROPDOWN_LABEL')}</label>
              <div>
                <MultiSelect
                  width="200px"
                  placeholder={i18n.t('COMMON_COMMON_DROPDOWN-PLEASE-SELECT_LABEL')}
                  options={getCompanyOptions()}
                  valueField="key"
                  labelField="value"
                  onChange={handleCompanyChange}
                  defaultValue={company}
                />
              </div>
            </div>
            <div className="action-options">
              <label>{i18n.t('COMMON_COMMON_BRANCH-DROPDOWN_LABEL')}</label>
              <div>
                <MultiSelect
                  disabled={!company}
                  width="200px"
                  placeholder={i18n.t('COMMON_COMMON_DROPDOWN-PLEASE-SELECT_LABEL')}
                  multi={true}
                  allItem={true}
                  options={getBranchOptions()}
                  valueField="id"
                  labelField="branch"
                  onChange={handleBranchChange}
                  // onAllSelectChange={handleAllSelectChange}
                  defaultValue={branch}
                />
              </div>
            </div>
          </div>
        </section>

        <section className="tab" style={{ marginTop: '3px' }}>
          <Tab tabList={tablist} onTabChange={setTab} tab={selectedTab} hidden={Hidden} />
        </section>
        <section className="list">
          <div className="list-action-wrapper">
            <ExportExcelButton data-testid="export-button" onClick={onExport} {...vehicleState.itemCount <= 0 && { disabled: true }} />
            <select onChange={(e) => { handlePerPageChange(e.target.value) }} defaultValue='1000件' className="perpage-item">
                {
                  perPageOptions.map((item) => (
                      <option value={item.value} key={item.key}>{item.value}</option>
                  ))
                }
              </select>
            <span>{i18n.t('COMMON_COMMON_PAGINATION-DISPLAY_LABEL', { page: startItem, countToPage, total: vehicleState.itemCount })}</span>
            {/* <span>Display {page} to {countToPage} out of {vehicleState.itemCount}</span> */}
            <Pagination page={page} count={Math.ceil(vehicleState.itemCount / perPage)} onChange={handlePageChange} />
          </div>
          {(!onDetail && !onCreate) && <Table borderCollapse={'separate'} onItemClick={onListClick} history={props.history} data={vehicleState.list} columnName={tableColumn} sortHandle={sortHandle} resetSwitch={resetSwitch} />}
        </section>

      </VehicleWrapper>
    </>)
}

export default VehicleManagement

VehicleManagement.propTypes = {
  history: PropTypes.object
}
