import React, { useState, useContext } from 'react'
import { API_ENDPOINT } from '../../tools/apiEndpoint'
import { userInfo } from './../../features/users/userInfoSlice'
import { useSelector, useDispatch } from 'react-redux'
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent
} from 'react-pro-sidebar'
import { Link, useLocation, useHistory } from 'react-router-dom'
import Modal from '../../components/Modal'
import { LogoutWrap, ActionConfirmWrap } from '../../components/Modal/styles'
import Logo from '../../assets/logo.jpg'
import { YesButton, NoButton } from '../../components/Button'

import { MaterialCommunityIcons, Foundation, AntDesign, MaterialIcons } from 'react-web-vector-icons'
import i18n from 'i18next'
import 'react-pro-sidebar/dist/css/styles.css'
import './style.css'
import { ThemeDetect } from './../DetectThemeCollaspe'

const SideMenu = () => {
  const dispatch = useDispatch()
  const [show, setPopup] = useState(false)
  const [actionConfirmPopup, setActionConfirmPopup] = useState(false)
  const [actionConfirmPopupMessage, setActionConfirmPopupMessage] = useState('')
  const [actionConfirmYes, setActionConfirmYes] = useState(() => {})
  const [actionConfirmNo, setActionConfirmNo] = useState(() => {})
  const [menuCollapse, setMenuCollapse] = useState(false)
  const expandCollapse = useContext(ThemeDetect)
  const location = useLocation()
  const history = useHistory()
  const { pathname } = location
  const splitLocation = pathname.split('/')
  const userState = useSelector(state => state.userInfo)
  const vehicleState = useSelector(state => state.vehicle)
  const dropDownState = useSelector(state => state.dropDown)
  const forkliftState = useSelector(state => state.forklift)
  const menuIconClick = () => {
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true)
    expandCollapse.setCollapse(menuCollapse)
  }
  const [action, setAction] = React.useState()
  React.useEffect(() => {
    // TODO unit test
    dispatch(userInfo())
    if (localStorage.getItem('idp') === 'HenngeOne') {
      setAction(`${API_ENDPOINT}/auth/henngeOneLogout`)
    } else {
      setAction(`${API_ENDPOINT}/auth/logout`)
    }
  }, [])

  React.useEffect(() => {
    if (!actionConfirmPopup) {
      setActionConfirmYes(() => {})
      setActionConfirmNo(() => {})
    }
  }, [actionConfirmPopup])

  const logoutConfirm = () => {
    if (!vehicleState.isEditing) {
      setPopup(true)
      return
    }
    // Create new action
    const actionOnYes = () => {
      setActionConfirmPopup(false)
      setPopup(true)
    }
    const actionOnNo = () => {
      setActionConfirmPopup(false)
    }
    // show the popup
    setActionConfirmPopup(true)
    setActionConfirmYes(() => actionOnYes)
    setActionConfirmNo(() => actionOnNo)
  }

  const vehicleEditingConfirm = (e) => {
    if (!vehicleState.isEditing && !dropDownState.isEditing && !forkliftState.isEditing) {
      return
    }
    // Prevent the default action
    e.preventDefault()
    // 画面に表示する文言を設定
    if (vehicleState.isEditing === true) {
      window.location.pathname === '/vehicle/create'
        ? setActionConfirmPopupMessage(i18n.t('VEHICLE_VEHICLE-CREATE_POPUP-CANCEL-CREATE_LABEL'))
        : setActionConfirmPopupMessage(i18n.t('VEHICLE_VEHICLE-EDIT_POPUP-CANCEL-EDIT_LABEL'))
    }
    if (dropDownState.isEditing === true) {
      setActionConfirmPopupMessage(i18n.t('DROP-DOWN-MANAGEMENT_EDIT-DROP-DOWN_POPUP-CANCEL_LABEL'))
    }
    if (forkliftState.isEditing === true) {
      window.location.pathname === '/forklift/create'
        ? setActionConfirmPopupMessage(i18n.t('FORKLIFT_FORKLIFT-CREATE_POPUP-CANCEL-CREATE_LABEL'))
        : setActionConfirmPopupMessage(i18n.t('FORKLIFT_FORKLIFT-EDIT_POPUP-CANCEL-EDIT_LABEL'))
    }
    // Create new action
    const actionOnYes = () => {
      setActionConfirmPopup(false)
      window.location.assign(e.target.pathname)
      history.push(e.target.pathname)
    }
    const actionOnNo = () => {
      setActionConfirmPopup(false)
    }
    // show the popup
    setActionConfirmPopup(true)
    setActionConfirmYes(() => actionOnYes)
    setActionConfirmNo(() => actionOnNo)
  }

  const getTooltipElem = (parent) => {
    const tooltip = parent.querySelector('.tooltip-menu-wrapper')
    return tooltip
  }

  const handleMouseIn = (e) => {
    /* istanbul ignore else */
    if (userState.email.length > 42) {
      const tooltip = getTooltipElem(e.target.parentNode)
      /* istanbul ignore else */
      if (tooltip) {
        tooltip.style.display = 'block'
      }
    }
  }

  const handleMouseOut = (e) => {
    const tooltip = getTooltipElem(e.target.parentNode)
    /* istanbul ignore else */
    if (userState.email.length > 42) {
      /* istanbul ignore else */
      if (tooltip) {
        tooltip.style.display = 'none'
      }
    }
  }

  const handleTooltipLeave = (e) => {
    e.target.parentNode.style.display = 'none'
  }

  return (
      <div id='header' className={menuCollapse ? 'header-collapse' : ''}>
        <ProSidebar collapsed={menuCollapse}>
          <SidebarHeader>
            <img src={Logo} className="App-logo" alt="logo" />
            <div className='logotext'>
              <p className="value" data-testid="user-email" onMouseOver={handleMouseIn} onMouseLeave={handleMouseOut}>{!menuCollapse ? userState.email : ''}</p>
              <span data-testid="tooltip-menu" className="tooltip-menu-wrapper" onMouseOut={handleTooltipLeave}>
                <span className="tooltiptext">{userState.email}</span>
              </span>
            </div>
            <div className='App-user'>
              <MaterialIcons name='person' color='#B9103D' size={menuCollapse ? 40 : 70} />
            </div>
          </SidebarHeader>
          <SidebarContent>
              <Menu iconShape='square'>
                <MenuItem className={(splitLocation[1] === 'vehicle' || splitLocation[1] === '') ? 'active' : ''} icon={<MaterialCommunityIcons name='truck' size={30} />}>
                  <Link testid="link-vehicle" to='/' onClick={vehicleEditingConfirm}>{i18n.t('MENU_MENU_VEHICLE-MANAGEMENT-MENU_CLICKABLE-LABEL')}</Link>
                </MenuItem>
                <MenuItem className={splitLocation[1] === 'contract' ? 'active' : ''} icon={<MaterialCommunityIcons name='calendar-range' size={30} />}>
                  <Link testid="link-contract" to='/contract' onClick={vehicleEditingConfirm}>{i18n.t('MENU_MENU_CONTRACT-MANAGEMENT-MENU_CLICKABLE-LABEL')}</Link>
                </MenuItem>
                <MenuItem className={splitLocation[1] === 'forklift' ? 'active' : ''} icon={<MaterialCommunityIcons name='forklift' size={30} />} style={{ borderTop: '2px solid #FFFFFF' } }>
                  <Link testid="link-vehicle" to='/forklift' onClick={vehicleEditingConfirm}>{i18n.t('MENU_MENU_FORKLIFT-MANAGEMENT-MENU_CLICKABLE-LABEL')}</Link>
                </MenuItem>
                <MenuItem className={splitLocation[1] === 'forkliftContract' ? 'active' : ''} icon={<MaterialCommunityIcons name='calendar-range' size={30} />} style={{ borderBottom: '2px solid #FFFFFF' } }>
                  <Link testid="link-contract" to='/forkliftContract' onClick={vehicleEditingConfirm}>{i18n.t('MENU_MENU_FORKLIFT-CONTRACT-MANAGEMENT-MENU_CLICKABLE-LABEL')}</Link>
                </MenuItem>
                <MenuItem className={splitLocation[1] === 'report' ? 'active' : ''} icon={<Foundation name='clipboard-notes' size={30} />}>
                  <Link testid="link-report" to='/report' onClick={vehicleEditingConfirm}>{i18n.t('MENU_MENU_REPORT-MENU_CLICKABLE-LABEL')}</Link>
                </MenuItem>
                {
                (userState.isAdmin || (userState.isEdit && userState.isAdmin))
                  ? <MenuItem className={splitLocation[1] === 'usermanagement' ? 'active' : ''} icon={<MaterialCommunityIcons name='clipboard-account' size={30} />}><Link testid="link-user" to='/usermanagement' onClick={vehicleEditingConfirm}>{i18n.t('MENU_MENU_USER-MANAGEMENT-MENU_CLICKABLE-LABEL')}</Link></MenuItem>
                  : ''
                }
                {
                (userState.isAdmin)
                  ? <MenuItem className={splitLocation[1] === 'dropdownmanagement' ? 'active' : ''} icon={<MaterialCommunityIcons name='pencil' size={30} />}><Link testid="link-user" to='/dropdownmanagement' onClick={vehicleEditingConfirm}>{i18n.t('MENU_MENU_DROP-DOWN-MANAGEMENT-MENU_CLICKABLE-LABEL')}</Link></MenuItem>
                  : ''
                }
              </Menu>
          </SidebarContent>
          <SidebarFooter>
            <Menu iconShape='square'>
              <MenuItem data-testid="menu-logout" onClick={logoutConfirm} icon={<MaterialCommunityIcons name='logout-variant' color='white' size={30} />}>{i18n.t('MENU_MENU_LOGOUT-MENU_CLICKABLE-LABEL')}</MenuItem>
              <MenuItem data-testid="menu-clicked" className={[menuCollapse ? 'expandedArrow' : 'collapsedArrow', !menuCollapse && i18n.language === 'ja' ? 'set-icon-position' : '']} onClick={menuIconClick} icon={menuCollapse ? (<AntDesign name='rightcircle' color='#B9103D' size={25} />) : (<AntDesign name='leftcircle' color='#B9103D' size={25} />)}></MenuItem>
            </Menu>
          </SidebarFooter>
        </ProSidebar>
        <Modal id="model-popup-dialog" testid="modal-logout" show={show} onClose={() => setPopup(false)}>
          <LogoutWrap action={action} method="POST">
            <span>{i18n.t('LOGOUT_COMMON_POPUP-LOGOUT-CONFIRM_LABEL')}</span>
            <input type="hidden" name="token" value={localStorage.getItem('token') || ''} />
            <section>
              <YesButton type="submit" />
              <NoButton data-testid="menu-popup-btn" onClick={(e) => { e.preventDefault(); setPopup(false) }} />
            </section>
          </LogoutWrap>
        </Modal>
        <Modal testid="modal-vehicle-edit" show={actionConfirmPopup} onClose={() => setActionConfirmPopup(false)}>
          <ActionConfirmWrap>
            <div className="modal-message-content">{actionConfirmPopupMessage}</div>
            <div className="modal-message-button">
              <YesButton data-testid="message-yes" onClick={() => actionConfirmYes()} />
              <NoButton data-testid="message-no" onClick={() => actionConfirmNo()}/>
            </div>
          </ActionConfirmWrap>
        </Modal>
      </div>
  )
}
export default SideMenu
